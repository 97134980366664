import { call, all, put, fork, takeLatest, delay } from "redux-saga/effects";
import { createBrowserHistory } from "history";

import {
  removePendingAthleteSuccess,
  removePendingAthlete,
  removePendingAthleteError,
  deleteAthleteRequest,
  deleteAthleteSuccess,
  deleteAthleteError,
} from "../actions/athlete-card";
import { apiCaller } from "../../utils/apicaller";
import { AthleteCardTypes } from "../types/athlete-card";
import { AxiosResponse } from "axios";

export const history = createBrowserHistory();

const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;

function* removePendingAthleteAsync(
  action: ReturnType<typeof removePendingAthlete>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const response: AxiosResponse = yield call(
      apiCaller,
      "del",
      API_ENDPOINT,
      `Coach/${action.payload.coachId}/removePendingAthlete?athleteId=${action.payload.athleteId}&clubSportTeamId=${action.payload.clubSportTeamId}&athleteEmail=${action.payload.athleteEmail}`
    );
    if (response?.status === 200) {
      yield put(removePendingAthleteSuccess(response.status));
      yield delay(4000);
      yield put(removePendingAthleteSuccess(0));
    } else {
      yield put(removePendingAthleteError(response?.status ?? 400));
    }
  } catch (err) {
    yield put(removePendingAthleteError(-1)); // connection error
    yield delay(4000);
    yield put(removePendingAthleteError(0));
  }
}

// Athlete hard delete
function* hardRemoveAthleteRequest(
  action: ReturnType<typeof deleteAthleteRequest>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const response: AxiosResponse = yield call(
      apiCaller,
      "del",
      API_ENDPOINT,
      `Coach/${action.payload.athleteEmail}RemoveAthlete`
    );
    if (response.status === 200) {
      yield put(deleteAthleteSuccess(response.status));
      yield delay(4000);
      yield put(deleteAthleteSuccess(0));
    } else {
      yield put(deleteAthleteError(response.status));
    }
  } catch (err) {
    yield put(removePendingAthleteError(400));
    yield delay(4000);
    yield put(removePendingAthleteError(0));
  }
}

//this is watcherfunction we use takeLatest to watch redux actions which should go through saga

function* watchRemovePendingAthlete() {
  yield takeLatest(
    AthleteCardTypes.ATHLETE_CARD_REQUEST,
    removePendingAthleteAsync
  );
}

function* watchHardRemoveAthlete() {
  yield takeLatest(
    AthleteCardTypes.ATHLETE_REMOVE_REQUEST,
    hardRemoveAthleteRequest
  );
}
// We can also use `fork()` here to split our saga into multiple watchers.

function* athleteCardSaga() {
  yield all([fork(watchRemovePendingAthlete)]);
  yield all([fork(watchHardRemoveAthlete)]);
}

export default athleteCardSaga;
