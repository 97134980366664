import React, { Component } from "react";
import { Layout, Row, Col, Card } from "antd";
import "../../styles/dashboard/dashboard.less";
import TableSorter from "../../components/dashboard-table";
import Widget from "../../components/dashboard-widget";
import { requestTableData } from "../../store/actions/concussion-table";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { ConcussionTable } from "../../store/types/concussion-table";
import { requestWidgetData } from "../../store/actions/dashboard-widgets";
import { WidgetsData } from "../../store/types/dashboard-widgets";
import { CoachDetails } from "../../store/types/coach-profile";
import { requestCoachPersonalData } from "../../store/actions/coach-profile";
import { withTranslation } from "react-i18next";
const { Content } = Layout;

interface PropsFromDispatch {
  getConcussionData: typeof requestTableData;
  getWidgetData: typeof requestWidgetData;
  requestCoachPersonalData: typeof requestCoachPersonalData;
}

interface PropsFromState {
  concussionTableData: Array<ConcussionTable>;
  dashboardWidgetsResult: WidgetsData;
  coachData: CoachDetails;
}

type AllProps = PropsFromState & PropsFromDispatch;
class Dashboard extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      username: "'",
      isViewClicked: false,
    };
  }

  componentDidMount() {
    const {
      requestCoachPersonalData,
      coachData: CoachData,
      getWidgetData,
    } = this.props;
    if (CoachData.id === "") {
      requestCoachPersonalData();
    } else {
      getWidgetData(CoachData.teamSelection);
    }
  }

  handleClickTrue(event: any) {
    this.setState({ isViewClicked: true });
  }
  handleClickFalse(event: any) {
    this.setState({ isViewClicked: false });
  }

  render() {
    const {
      concussionTableData: ConcussionTableData,
      dashboardWidgetsResult: DashboardWidgetsResult,
      t,
    } = this.props;
    const { isViewClicked } = this.state;

    return (
      <Content className="main-content dash-board">
        {/*<Title>{t("Overview")}</Title>*/}
        <Row type="flex" gutter={[32, 48]} className="athlete-rsk-row">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={8}
            className="athlete-rsk-col-1"
          >
            <Card title="" bordered={false} className="widget-card">
              <div className="widget-title">{t("Current concussions")}</div>
              {/*<div className="num-cns-img">*/}
              {/*  <img src={require("../../assets/images/graph.svg")} />*/}
              {/*</div>*/}

              <Widget key={1} widgetData={DashboardWidgetsResult} type={"1"} />
            </Card>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={8}
            className="athlete-rsk-col-2"
          >
            <Card title="" bordered={false} className="widget-card">
              {DashboardWidgetsResult.athletesInRisk.length !== 0
                ? [
                    isViewClicked === true ? (
                      <div key={1} className="back-link">
                        <button onClick={this.handleClickFalse.bind(this)}>
                          {t("Back")}
                        </button>
                      </div>
                    ) : (
                      <div key={2} className="back-link">
                        <button onClick={this.handleClickTrue.bind(this)}>
                          {t("view")}
                        </button>
                      </div>
                    ),
                  ]
                : null}
              <div className="widget-title no-padding">
                {t("Athletes at risk")}
              </div>
              <div className="widget-sub-title">
                ({t("Concussion in last 365 days")})
              </div>
              <Widget
                key={2}
                widgetData={DashboardWidgetsResult}
                type={"2"}
                isViewClicked={isViewClicked}
              />
            </Card>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={8}
            className="athlete-rsk-col-3"
          >
            <Card title="" bordered={false} className="widget-card">
              <div className="widget-title">
                {t("Baseline Tests Completed")}
              </div>
              {/*               <div className="bs-test-img">
                <img src={require("../../assets/images/baseline-icon.svg")} />
              </div> */}
              <Widget key={3} widgetData={DashboardWidgetsResult} type={"3"} />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <br /> <br />{" "}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card title="" bordered={false} className="widget-card">
              <div className="widget-table-title">
                {t("Ongoing concussions")}
              </div>
              <TableSorter concussionDataProp={ConcussionTableData} />
            </Card>
          </Col>
        </Row>
      </Content>
    );
  }
}
const mapDispatchToProps = {
  getConcussionData: requestTableData,
  getWidgetData: requestWidgetData,
  requestCoachPersonalData: requestCoachPersonalData,
};
const mapStateToProps = ({
  concussionTableData: ConcussionTableData,
  dashboardWidgetsResult: DashboardWidgetsResult,
  coachPersonalData,
}: ApplicationState) => ({
  concussionTableData: ConcussionTableData.concussionTableResultData,
  dashboardWidgetsResult: DashboardWidgetsResult.dashboardWidgetsResult,
  coachData: coachPersonalData.coachData,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("dashboard")(Dashboard));
