import {
  requestInviteAthlete,
  requestInviteAthleteSuccess,
  requestInviteAthleteError,
  requestCheckEmailValidate,
  requestCheckEmailValidateSuccess,
  requestBulkCheckEmailValiditySuccess,
  requestBulkCheckEmailValidityError,
  getAthleteByEmail,
  getAthleteByEmailSuccess,
  getAthleteByEmailError
} from "../actions/invite-athlete";
import { call, all, fork, takeLatest, put, delay } from "redux-saga/effects";
import { apiCaller } from "../../utils/apicaller";
import { InviteAthleteTypes } from "../types/invite-athlete";
import { AxiosResponse } from "axios";

const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;
function* requestInviteAthleteSaga(
  action: ReturnType<typeof requestInviteAthlete>
) {
  try {
    const coachID = sessionStorage.getItem("coachID")

    const response: AxiosResponse = yield call(
      apiCaller,
      "post",
      API_ENDPOINT,
      `Coach/${coachID}/${localStorage.getItem("teamSelection")}/addAthletes`,
      action.payload
    );

    if (response.status === 200) {
      yield put(requestInviteAthleteSuccess(response.status));
      yield delay(4000);
      yield put(requestInviteAthleteSuccess(0));
    } else {
      yield put(requestInviteAthleteError(response.status));
    }
  } catch (error) {
    console.log(error)
    yield put(requestInviteAthleteError(400));
    yield delay(4000);
    yield put(requestInviteAthleteError(0));
  }
}
function* requestInviteAthleteEmailCheckSaga(
  action: ReturnType<typeof requestCheckEmailValidate>
) {
  try {
    const response: AxiosResponse = yield call(
      apiCaller,
      "get",
      API_ENDPOINT,
      `Coach/${action.payload}`
    );

    if (response.status === 200) {
      yield put(requestCheckEmailValidateSuccess(response.data));
      yield delay(5000);
      yield put(requestCheckEmailValidateSuccess(""));
    } else {
      yield put(requestCheckEmailValidateSuccess(response.data));
    }
  } catch (error) {
    yield put(requestCheckEmailValidateSuccess(""));
    yield delay(5000);
    yield put(requestCheckEmailValidateSuccess(""));
  }
}

function* requestBulkEmailCheckSaga(
  action: ReturnType<typeof requestCheckEmailValidate>
) {
  try {
    const response: AxiosResponse = yield call(
      apiCaller,
      "post",
      API_ENDPOINT,
      "Coach/emailsValidation",
      action.payload
    );

    if (response.status === 200) {
      yield put(requestBulkCheckEmailValiditySuccess(response.data));
    } else {
      yield put(requestBulkCheckEmailValidityError(response.data));
    }
  } catch (error) {
    yield put(requestBulkCheckEmailValidityError(error as String));
  }
}

function* getAthleteByEmailSaga(
  action: ReturnType<typeof getAthleteByEmail>
) {
  try {
    const teamSelection: string | null = localStorage.getItem("teamSelection");
    const response: AxiosResponse = yield call(
      apiCaller,
      "get",
      API_ENDPOINT,
      `Coach/GetAthleteByEmail?athleteEmail=${action.payload}&clubSportTeamId=${teamSelection}`
    );
    if (response.status === 200) {
      if (response.data.isExistingAthlete) {
        // athlete exists
        yield put(getAthleteByEmailSuccess({
          ...response.data,
          email: action.payload,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          isExisting: true
        }));
      } else {
        // new athlete
        yield put(getAthleteByEmailSuccess({
          ...response.data,
          email: action.payload,
          isExisting: false
        }));
      }
      // yield put(getAthleteByEmailSuccess(response.data));
    } else {
      yield put(getAthleteByEmailError(response.data));
    }
  } catch (error) {
    yield put(getAthleteByEmailError(" "));
    // yield delay(5000);
    // yield put(requestCheckEmailValidateSuccess(""));
  }
}

function* watchRequestInviteAthlete() {
  yield takeLatest(
    InviteAthleteTypes.INVITEATHLETE_REQUEST,
    requestInviteAthleteSaga
  );
}
function* watchRequestInviteAthleteEmailCheck() {
  yield takeLatest(
    InviteAthleteTypes.CHECKEMAIL_REQUEST,
    requestInviteAthleteEmailCheckSaga
  );
}

function* watchRequestInviteAthleteBulkEmailCheck() {
  yield takeLatest(
    InviteAthleteTypes.CHECKEMAIL_BULKREQUEST,
    requestBulkEmailCheckSaga
  );
}

function* watchGetAthleteByEmail() {
  yield takeLatest(
    InviteAthleteTypes.GET_ATHLETE_BY_EMAIL,
    getAthleteByEmailSaga
  );
}

function* inviteAthleteSaga() {
  yield all([
    fork(watchRequestInviteAthlete),
    fork(watchRequestInviteAthleteEmailCheck),
    fork(watchRequestInviteAthleteBulkEmailCheck),
    fork(watchGetAthleteByEmail)
  ]);
}

export default inviteAthleteSaga;
