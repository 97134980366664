import { call, all, put, fork, takeLatest } from "redux-saga/effects";
import { createBrowserHistory } from "history";

import { requestTableData } from "../actions/concussion-table";
import { apiCaller } from "../../utils/apicaller";
import { DashboardWidgetTypes } from "../types/dashboard-widgets";
import {
  requestWidgetDataError,
  requestWidgetDataSuccess
} from "../actions/dashboard-widgets";
import { AxiosResponse } from "axios";

export const history = createBrowserHistory();

const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;

function* fetchWidgetDataAsync(action: ReturnType<typeof requestTableData>) {
  try {
    // To call async functions, use redux-saga's `call()`.

    const response: AxiosResponse = yield call(
      apiCaller,
      "get",
      API_ENDPOINT,
     `coach/${action.payload}/concussionStatistics`
    );

    if (response.data.error) {
      yield put(requestWidgetDataError());
    } else {
      yield put(requestWidgetDataSuccess(response.data));
    }
  } catch (err) {
    yield put(requestWidgetDataError());
  }
}

//this is watcherfunction we use takeLatest to watch redux actions which should go through saga

function* watchFetchTableData() {
  yield takeLatest(
    DashboardWidgetTypes.DASHBOARDWIDGETS_REQUEST,
    fetchWidgetDataAsync
  );
}
// We can also use `fork()` here to split our saga into multiple watchers.

function* widgetSaga() {
  yield all([fork(watchFetchTableData)]);
}

export default widgetSaga;
