//package imports
import React, { Component, Fragment } from "react";
import { message } from "antd";

//css
import { CoachDetails } from "../../store/types/coach-profile";
import { RequestAthleteConcussionTestPermission } from "../../store/types/notification";
import {
  requestOldNotification,
  responsePermission,
  appendOldNotifications,
  insertNotification,
  dismissNotification,
} from "../../store/actions/notification";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import firebase from "firebase";
import { requestCoachPersonalData } from "../../store/actions/coach-profile";
import { withTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/sv";
import "moment/locale/en-gb";
import InformationNotificationComponent from "../information-notification";
import ConfirmationNotificationComponent from "../confirmation-notification";

interface PropsFromState {
  CoachData: CoachDetails;
  athleteListConcussionTestRequestPermission: Array<RequestAthleteConcussionTestPermission>;
  grantPermission: string;
  responseNotificationMessage: string;
  forceRequestMessage: string;
}
interface PropsFromDispatch {
  requestOldNotification: typeof requestOldNotification;
  responsePermission: typeof responsePermission;
  appendOldNotifications: typeof appendOldNotifications;
  insertNotification: typeof insertNotification;
  requestCoachPersonalData: typeof requestCoachPersonalData;
  dismissNotification: typeof dismissNotification;
}
type AllProps = PropsFromState & PropsFromDispatch;

class NotificationComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isRequestApproved: false,
      isDismissNotification: false,
    };
  }

  componentWillReceiveProps(anyProps: any) {
    const { t } = anyProps;
    if (anyProps.responseNotificationMessage === 200) {
      if (this.state.isRequestApproved) {
        message.success(t("Request approved"));
      }
      if (
        this.state.isRequestApproved === false &&
        this.state.isDismissNotification === false
      ) {
        message.success(t("Request denied"));
      }
      this.setState({
        isRequestApproved: false,
      });
    }
  }

  sendPermission = (
    permission: boolean,
    athleteId: string,
    type: string,
    notificationId: number
  ) => {
    this.setState({
      isRequestApproved: permission,
    });

    const { responsePermission, CoachData } = this.props;
    let responseObject = {
      athleteId,
      permission,
      teamSelection: CoachData.teamSelection,
      type,
    };

    responsePermission(responseObject);
    // Dismiss the notification after giving the permission ( approve or deny )
    this.dismissNotification(athleteId, notificationId);
  };

  dismissNotification = (athleteID: string, notificationID: number) => {
    this.setState({
      isDismissNotification: true,
    });
    const { dismissNotification } = this.props;
    dismissNotification(athleteID, notificationID);
  };

  componentDidMount = () => {
    const {
      requestOldNotification,
      CoachData,
      requestCoachPersonalData,
      i18n,
    } = this.props;

    moment.locale(i18n.language);

    if (CoachData.id === "") {
      requestCoachPersonalData();
    }

    if (CoachData.id === "" || CoachData.id === undefined) {
    } else {
      requestOldNotification({
        coachId: CoachData.id,
        teamSelection: CoachData.teamSelection,
      });
    }

    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage((payload) => {
        requestOldNotification(
          {
            coachId: sessionStorage.getItem("coachID"),
            teamSelection: CoachData.teamSelection,
          } || ""
        );
      });
    }
  };

  renderNotification = (athlete: any) => {
    switch (athlete.data.alertType) {
      case "INFO":
        return (
          <InformationNotificationComponent
            athlete={athlete}
            dismissNotification={(athleteID, notificationID) =>
              this.dismissNotification(athleteID, notificationID)
            }
          />
        );
        break;
      case "CONFIRM":
        return (
          <ConfirmationNotificationComponent
            athlete={athlete}
            sendPermission={(permission, athleteId, type, notificationId) =>
              this.sendPermission(permission, athleteId, type, notificationId)
            }
          />
        );
        break;

      default:
        return "";
        break;
    }
  };

  render() {
    const { athleteListConcussionTestRequestPermission, i18n } = this.props;

    i18n.on("languageChanged", (lng: any) => {
      moment.locale(lng);
    });

    return (
      <Fragment>
        {athleteListConcussionTestRequestPermission
          .reverse()
          .map((athlete: any) => this.renderNotification(athlete))}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  coachPersonalData,
  athleteRequestConcussionTestListDetails:
    AthleteRequestConcussionTestListDetails,
  athleteResponseConcussionTestListDetails:
    AthleteResponseConcussionTestListDetails,
}: ApplicationState) => ({
  CoachData: coachPersonalData.coachData,
  athleteListConcussionTestRequestPermission:
    AthleteRequestConcussionTestListDetails.athleteListConcussionTestRequestPermission,
  grantPermission:
    AthleteResponseConcussionTestListDetails.permissionReplyMessage,
  responseNotificationMessage:
    AthleteResponseConcussionTestListDetails.permissionReplyMessage,
  forceRequestMessage:
    AthleteRequestConcussionTestListDetails.forceRequestMessage,
});
const mapDispatchToProps = {
  requestOldNotification: requestOldNotification,
  responsePermission: responsePermission,
  appendOldNotifications: appendOldNotifications,
  insertNotification: insertNotification,
  requestCoachPersonalData: requestCoachPersonalData,
  dismissNotification: dismissNotification,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NotificationComponent));
