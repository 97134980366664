import post, { del, put, get }   from "./http-requests";

export async function apiCaller(method:string,url:string,path:string,data?:any){
    switch (method){
        case 'post':{
           return post(`${url}${path}`,data)
        }
        case 'del':{
            return del (`${url}${path}`,data)
        }
        case 'put':{
            return put (`${url}${path}`,data)
        }
        default :{ return get (`${url}${path}`) }
    }
  
}