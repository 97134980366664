import { Reducer } from 'redux'
import { DashboardWidgetState, DashboardWidgetTypes } from '../types/dashboard-widgets';

//typesafe initialstate
export const initialState: DashboardWidgetState = {
    dashboardWidgetsResult: {
        athletesInRisk: [],
        numberOfConcussions: [{

            year: "",
            value: ""
        }, {

            year: "",
            value: ""
        }
        ],
        baselineTest: {

            noOfAthletesDoneBaseline: "",
            noOfAthletesUnderCoach: ""
        }
    }
}

const reducer: Reducer<DashboardWidgetState> = (state = initialState, action) => {

    switch (action.type) {
        case (DashboardWidgetTypes.DASHBOARDWIDGETS_REQUEST):
            return {
                ...state
            };
        case (DashboardWidgetTypes.DASHBOARDWIDGETS_SUCCESS):
            return {
                ...state,
                dashboardWidgetsResult: action.payload
            };
        case (DashboardWidgetTypes.DASHBOARDWIDGETS_ERROR):
            return {
                ...state,
                dashboardWidgetsResult: []
            };
        default:
            return state;
    }
}

export { reducer as widgetReducer }

