

import React from 'react';
import { Modal, Button } from 'antd';

export const IdleTimeOutModal = ({ showModal, handleClose, handleLogout }) => {
 
    return (
        <Modal
            title='Du har varit inaktiv!'
            visible={showModal}
            onCancel={handleClose}
            footer={[

                <Button
                    className="ant-btn-logout"
                    key="submit"
                    type="primary"
                    onClick={handleLogout}
                >
                    Logga ut
                 </Button>,
                <Button className="ant-btn-idle" key="back" onClick={handleClose}>
                    Stanna
                 </Button>,
            ]}
        >
            <p>Du kommer att bli utlogga. Vill du stanna?</p>
        </Modal>
    );
}