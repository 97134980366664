import { action } from "typesafe-actions";
import { AthleteDetails, AthleteListTypes } from "../types/athlete-list";

export const requestAthleteData = (teamSelection: number) =>
  action(AthleteListTypes.ATHLETELIST_REQUEST, teamSelection);

export const requestAthleteDataSuccess = (athleteData: AthleteDetails[]) =>
  action(AthleteListTypes.ATHLETELIST_SUCCESS, athleteData);

export const requestAthleteDataError = () =>
  action(AthleteListTypes.ATHLETELIST_ERROR);
