// export interface TeamDetails {
//   id: number;
//   teamName: string;
//   createdOn: Date;
//   isDeleted: boolean;
// }
export interface TeamDetails {
  clubSportId: number;
  clubSport: {
    clubId: number;
    club: {
      clubName: string;
      id: number;
    };
    sportId: number;
    sport: {
      name: string;
      id: number;
    };
    id: number;
  };
  teamId: number;
  team: {
    teamName: string;
    id: number;
  };
  id: number;
}

export interface TeamListState {
  teamListData: Array<TeamDetails>;
}

export enum TeamListTypes {
  TEAMLIST_REQUEST = "@@teamList/TEAM_LIST_REQUEST",
  TEAMLIST_SUCCESS = "@@teamList/TEAM_LIST_SUCCESS",
  TEAMLIST_ERROR = "@@teamList/TEAM_LIST_ERROR",
}
