import React, { FC, useState, useEffect } from "react";
import {
  Upload,
  Button,
  Modal,
  Table,
  Typography,
  Row,
  Col,
  Icon,
  message,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

import csvData from "./csv-data";
import { requestBulkCheckEmailValidity } from "../../store/actions/invite-athlete";
import {
  EmailListValidityResponse,
  EmailList,
} from "../../store/types/invite-athlete";
import { ApplicationState } from "../../store";

type DataSource = { firstName: string; lastName: string; email: string };
type CSVUploaderProps = {
  addAthleteFromCSV: (data: DataSource[]) => void;
};
type ColumnType = { title: string; dataIndex: string; key: string };
type InvalidRecType = {
  firstName: string;
  lastName: string;
  email: string;
  reason: string;
};

const CSVUploader: FC<CSVUploaderProps> = ({ addAthleteFromCSV }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]); // To Do: type change
  const [parsedCSV, setParsedCSV] = useState<string[][]>([]);
  const [validatedCSV, setValidatedCSV] = useState<string[][]>([]);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<DataSource[]>([]);
  const [invalidRecords, setInvalidRecords] = useState<InvalidRecType[]>([]);
  const [columns, setColumns] = useState<ColumnType[]>([]);
  const [invalidColumns, setInvalidColumns] = useState<ColumnType[]>([]);
  const [multipleAthleteEmailList, setMultipleAthleteEmailList] = useState<
    string[]
  >([]);
  const [playVideo, setPlayVideo] = useState<undefined | string>(undefined);

  const { t } = useTranslation("athletes");

  const Dragger = Upload.Dragger;
  const Text = Typography.Text;

  const draggerProps = {
    accept: ".csv",
    onRemove: (file: any) => {
      // To Do: type change
      setFileList((prvList: any) => {
        const index = prvList.indexOf(file);
        const newFileList = prvList.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file: Blob) => {
      if (
        file.type === "text/csv" ||
        file.type === "application/vnd.ms-excel"
      ) {
        let tempFileList = [...fileList, file];
        tempFileList = tempFileList.slice(-1);
        setFileList(tempFileList);
      } else {
        message.warning(t("Please upload a CSV file"));
      }
      return false;
    },
    fileList,
  };

  const dispatch = useDispatch();
  const { emailListValidityResponse: emailListValidity } = useSelector(
    (state: ApplicationState) => state.bulkEmailCheckData
  );
  const uploadDragIcon = {
    zoom: "1.3",
  };

  useEffect(() => {
    if (validatedCSV.length > 0) {
      uploadCSV();
    }
  }, [validatedCSV]);

  useEffect(() => {
    if (parsedCSV.length > 0) {
      validateData();
    }
  }, [parsedCSV]);

  useEffect(() => {
    if (emailListValidity.length > 0 && emailListValidity[0].email) {
      const invalidEmails = emailListValidity.filter(
        (athlete: EmailListValidityResponse) => {
          return athlete.validity === false;
        }
      );

      var invalRecords: InvalidRecType[] = [];

      invalidEmails.forEach((InvalidEmail: EmailListValidityResponse) => {
        const rejectedRecord = dataSource.find((InvalidRecords) => {
          return InvalidRecords.email === InvalidEmail.email;
        });
        if (rejectedRecord) {
          var data = {
            firstName: rejectedRecord.firstName,
            lastName: rejectedRecord.lastName,
            email: rejectedRecord.email,
            reason: t("Duplicate Email"),
          };
          invalRecords.push(data);
        }
      });

      if (invalidEmails.length > 0) {
        var validRecords: any[] = [];

        emailListValidity.forEach((athlete: EmailListValidityResponse) => {
          if (athlete.validity) {
            var rec = dataSource.find((record) => {
              return record.email === athlete.email;
            });
            validRecords.push(rec);
          }
        });
        setDataSource(validRecords);
        setInvalidRecords(invalRecords);
      }

      setShowTable(true);
    }
  }, [emailListValidity]);

  const uploadCSV = () => {
    const dataList = [];
    const columns = [
      {
        title: t("First Name"),
        dataIndex: "firstName",
        key: "firstName",
      },
      {
        title: t("Last Name"),
        dataIndex: "lastName",
        key: "lastName",
      },
      {
        title: t("Email"),
        dataIndex: "email",
        key: "email",
      },
    ];
    for (let array in validatedCSV) {
      const data = {
        firstName: validatedCSV[array][0],
        lastName: validatedCSV[array][1],
        email: validatedCSV[array][2],
      };
      dataList.push(data);
    }
    const invalidColumns = [
      ...columns,
      {
        title: t("Reason"),
        dataIndex: "reason",
        key: "reason",
      },
    ];
    setColumns(columns);
    setDataSource(dataList);
    setInvalidColumns(invalidColumns);
    compareDuplicatedEmail();
  };

  const compareDuplicatedEmail = () => {
    let emailArrayList: EmailList[] = [];
    for (let array in multipleAthleteEmailList) {
      const data = {
        email: multipleAthleteEmailList[array],
      };
      emailArrayList.push(data);
    }
    dispatch(requestBulkCheckEmailValidity(emailArrayList));
  };

  const showModal = () => {
    setModalOpen(true);
    setFileList([]);
    setShowTable(false);
    setParsedCSV([]);
    setValidatedCSV([]);
    setDataSource([]);
    setColumns([]);
    setInvalidColumns([]);
    setMultipleAthleteEmailList([]);
    setInvalidRecords([]);
  };
  const ContainsEmptyString = (my_arr: any[]) => {
    for (var i = 0; i < my_arr.length; i++) {
      if (my_arr[i] === "") return true;
    }
    return false;
  };
  const validateEmail = (email: string) => {
    var re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateData = () => {
    var tempParsedCSV: any = [...parsedCSV];
    var tempInvalidRecords: any = [...invalidRecords];
    var tempMultipleAthleteEmailList: any = [...multipleAthleteEmailList];
    var tempValidatedCSV: any = [];
    for (var i = 0; i < tempParsedCSV.length; i++) {
      const tempParsedLOne = tempParsedCSV[i];
      if (!ContainsEmptyString(tempParsedLOne)) {
        if (tempParsedLOne.length === 3) {
          if (validateEmail(tempParsedLOne[2])) {
            tempValidatedCSV.push(tempParsedLOne);
            tempMultipleAthleteEmailList.push(tempParsedLOne[2]);
          } else {
            tempInvalidRecords.push({
              firstName: tempParsedLOne[0],
              lastName: tempParsedLOne[1],
              email: tempParsedLOne[2],
              reason: t("Invalid Email"),
            });
          }
        }
      } else {
        tempInvalidRecords.push({
          firstName: tempParsedLOne[0],
          lastName: tempParsedLOne[1],
          email: tempParsedLOne[2],
          reason: t("Empty Field"),
        });
      }
    }
    if (
      tempMultipleAthleteEmailList.length !== multipleAthleteEmailList.length
    ) {
      setMultipleAthleteEmailList(tempMultipleAthleteEmailList);
    }
    setInvalidRecords(tempInvalidRecords);
    setValidatedCSV(tempValidatedCSV);
  };

  const processData = (csv: any) => {
    var withoutQuotes = csv.replace(/['"]+/g, "");
    var splitIntoRows = withoutQuotes.split(/\r\n|\n/); //splits file into rows
    var parsedcsv: any = [];
    var limit = splitIntoRows.length;
    if (splitIntoRows[splitIntoRows.length - 1] === "") {
      limit = splitIntoRows.length - 1;
    }
    for (var i = 1; i < limit; i++) {
      var data = splitIntoRows[i].split(","); //splits into columns
      var tarr = [];
      for (var j = 0; j < data.length; j++) {
        tarr.push(data[j].trim());
      }
      parsedcsv.push(tarr);
    }
    setParsedCSV(parsedcsv);
  };

  const errorHandler = (evt: any) => {
    if (evt.target.error.name === "NotReadableError") {
      alert("Cannot read file !");
    }
  };

  const loadHandler = (event: any) => {
    const csv = event.target.result;
    processData(csv);
  };

  const getAsText = (fileToRead: Blob) => {
    var reader = new FileReader();
    // Read file into memory as UTF-8
    reader.readAsText(fileToRead, "ISO-8859-1");
    // Handle errors load
    reader.onload = loadHandler;
    reader.onerror = errorHandler;
  };
  const handleShowVideo = () => {
    const videoLink = process.env.REACT_APP_CSV_EXPLAINER_VIDEO;
    setPlayVideo(videoLink);
  };

  const handleFiles = (file: Blob) => {
    // Check for the various File API support.
    if (window.FileReader) {
      // FileReader are supported.
      getAsText(file);
    } else {
      alert("FileReader are not supported in this browser.");
    }
  };

  return (
    <span>
      <Row type="flex" justify="space-between" style={{ marginBottom: 10 }}>
        <Col className="vid-desc-container">
          <Text>{t("csv-uploader.instructional_video")}</Text>
        </Col>
        <Col>
          <Button
            type="primary"
            shape="circle"
            icon="caret-right"
            className="colored-button"
            onClick={handleShowVideo}
          />
        </Col>
      </Row>
      <div className="upload-csv-wrap" onClick={showModal}>
        <div style={uploadDragIcon}>
          <Row>
            <Icon type="cloud-upload" onClick={showModal} className="mr-10" />
          </Row>
          <Row>
            <Text className="upload-csv-txt">{t("Upload")}</Text>
          </Row>
        </div>
      </div>
      <Modal
        title={t("Upload CSV")}
        visible={modalOpen}
        footer={null}
        centered
        closable
        onCancel={() => setModalOpen(false)}
        width={750}
      >
        {showTable ? (
          <div>
            <Text strong={true}>{t("Preview")}</Text>
            <Table
              dataSource={dataSource.slice(0, 100)}
              columns={columns}
              pagination={false}
            />
            <br />

            <h4 style={invalidRecords.length > 0 ? { color: "red" } : {}}>
              {t("Invalid/Duplicate Emails")}: {invalidRecords.length}{" "}
            </h4>

            {invalidRecords.length > 0 ? (
              <Table
                dataSource={invalidRecords.slice(0, 100)}
                columns={invalidColumns}
                pagination={false}
              />
            ) : null}
            <br />
            <Row>
              <Col lg={12} sm={12} className="col-text-left">
                <Text>
                  {t("Quantity")}: {parsedCSV.length}
                </Text>
                <br />
                <Text>
                  {t("Successful uploads")}: {dataSource.length}
                </Text>
                <br />
                <Text>
                  {t("Failed uploads")}: {invalidRecords.length}
                </Text>
                <br />
              </Col>
              <Col sm={6} className="col-text-right">
                <Button
                  type="default"
                  id="csv-records-cancel"
                  onClick={() => setModalOpen(false)}
                >
                  {t("Cancel")}
                </Button>
              </Col>
              <Col sm={6} className="col-text-right">
                <Button
                  type="primary"
                  id="csv-records-upload"
                  onClick={() => {
                    addAthleteFromCSV(dataSource);
                    setModalOpen(false);
                  }}
                  disabled={validatedCSV.length - invalidRecords.length === 0}
                >
                  {t("Upload")}
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row>
              <Col>
                <Dragger {...draggerProps}>
                  <Button type="primary" id="csv-uploader-upload">
                    {t("Upload a CSV file")}
                  </Button>
                  <br />
                  <Text>{t("or drag and drop")}</Text>
                </Dragger>
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={18} xs={14}>
                <Text>
                  {t("Make sure to upload with the correct file format!")}{" "}
                  <CSVLink filename={"sample.csv"} data={csvData}>
                    {t("Download sample file")}
                  </CSVLink>
                </Text>
              </Col>
              <Col lg={6} xs={10} className="col-text-right">
                <Button
                  id="csv-uploader-next"
                  disabled={fileList.length === 0}
                  onClick={() => {
                    handleFiles(fileList[0]);
                    setFileList([]);
                  }}
                  type="primary"
                >
                  {t("Next")}
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
      <Modal
        title={null}
        visible={typeof playVideo !== "undefined"}
        footer={null}
        centered
        closable
        onCancel={() => setPlayVideo(undefined)}
        width={750}
        wrapClassName="video-modal"
      >
        {playVideo ? (
          <video className="video-iframe" controls>
            <source src={playVideo} type="video/mp4"></source>
            <a href={playVideo}>Download video</a>
          </video>
        ) : null}
      </Modal>
    </span>
  );
};

export default CSVUploader;
