import { action } from "typesafe-actions";
import { SidelineTest, SidelineTestInformation, SidelineTestTypes } from "../types/sideline-test";

export const requestSidelineTestInformation = (teamSelection: number) =>
    action(SidelineTestTypes.SIDELINETESTINFORMATION_REQUEST, teamSelection);

export const requestSidelineTestInformationSuccess = (sidelineTestInformation: SidelineTestInformation) =>
    action(SidelineTestTypes.SIDELINETESTINFORMATION_SUCCESS, sidelineTestInformation);

export const requestSidelineTestInformationError = (errorMessage: number) =>
    action(SidelineTestTypes.SIDELINETESTINFORMATION_ERROR, errorMessage);

export const requestSubmitSidelineTest = (athleteId:number, sidelineTest:SidelineTest) =>
    action(SidelineTestTypes.SUBMITSIDELINETEST_REQUEST, {athleteId, sidelineTest});

export const requestSubmitSidelineTestSuccess = (result: number) =>
    action(SidelineTestTypes.SUBMITSIDELINETEST_SUCCESS, result);

export const requestSubmitSidelineTestError = (errorMessage: number) =>
    action(SidelineTestTypes.SUBMITSIDELINETEST_ERROR, errorMessage);