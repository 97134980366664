import { action } from "typesafe-actions";
import {
  SportPositionsTypes,
  SportPositionsState
} from "../types/sport-positions";

export const requestSportPositionsData = (teamSelection: number) =>
  action(SportPositionsTypes.SPORT_POSITIONS_REQUEST, teamSelection);

export const requestSportPositionsSuccess = (
  sportPositionsData: SportPositionsState
) =>
  action(
    SportPositionsTypes.SPORT_POSITIONS_SUCCESS,
    sportPositionsData
  );

export const requestSportPositionsError = () =>
  action(SportPositionsTypes.SPORT_POSITIONS_ERROR);
