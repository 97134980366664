import { Reducer } from "redux";
import { TeamListState, TeamListTypes } from "../types/team-selection";

export const initialState: TeamListState = {
  teamListData: [
    {
      clubSportId: 0,
      clubSport: {
        clubId: 0,
        club: {
          clubName: "",
          id: 0,
        },
        sportId: 0,
        sport: {
          name: "",
          id: 0,
        },
        id: 0,
      },
      teamId: 0,
      team: {
        teamName: "",
        id: 0,
      },
      id: 0,
    },
  ],
};

const reducer: Reducer<TeamListState> = (state = initialState, action) => {
  switch (action.type) {
    case TeamListTypes.TEAMLIST_REQUEST:
      return { ...state };
    case TeamListTypes.TEAMLIST_SUCCESS:
      return { ...state, teamListData: action.payload };
    case TeamListTypes.TEAMLIST_ERROR:
      return { ...state, teamListData: [] };
    default:
      return state;
  }
};

export { reducer as teamListReducer };
