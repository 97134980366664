import { Reducer } from "redux";
import {
  SportPositionsTypes,
  SportPositionsState
} from "../types/sport-positions";

export const initialState: SportPositionsState = {
  sportPositionsData: {}
};

const reducer: Reducer<SportPositionsState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SportPositionsTypes.SPORT_POSITIONS_REQUEST:
      return { ...state };
    case SportPositionsTypes.SPORT_POSITIONS_SUCCESS:
      return { ...state, sportPositionsData: action.payload };
    case SportPositionsTypes.SPORT_POSITIONS_ERROR:
      return { ...state, sportPositionsData: [] };
    default:
      return state;
  }
};

export { reducer as sportPositionsReducer };
