
export interface WidgetContentNumberOfConcussions {
  year: string
  value: string
}
export interface WidgetContent {
  athleteName: string
  frequency: string
}
export interface Baseline {
  noOfAthletesDoneBaseline: string
  noOfAthletesUnderCoach: string
}

export interface WidgetsData {
  numberOfConcussions: Array<WidgetContentNumberOfConcussions>
  athletesInRisk: Array<WidgetContent>
  baselineTest: Baseline
}

export interface DashboardWidgetState {
  dashboardWidgetsResult: WidgetsData
}

export enum DashboardWidgetTypes {
  DASHBOARDWIDGETS_REQUEST = '@@dashboardWidgets/DASHBOARDWIDGETS_REQUEST',
  DASHBOARDWIDGETS_SUCCESS = '@@dashboardWidgets/DASHBOARDWIDGETS_SUCCESS',
  DASHBOARDWIDGETS_ERROR = '@@dashboardWidgets/DASHBOARDWIDGETS_ERROR',
} 