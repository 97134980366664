import React, { Component } from "react";
import { Layout, Row, Icon, Button } from "antd";
import { Link } from "react-router-dom";
import _ from "lodash";

//css
import "../../styles/athlete-profile/athlete-profile.less";
import AthleteProfileCard from "../../components/athlete-profile-card/athlete-profile-card";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { requestAthleteProfileData } from "../../store/actions/athlete-profile";
import { requestConcussionTestData } from "../../store/actions/concussion-test-details";
import { requestCoachPersonalData } from "../../store/actions/coach-profile";
import { AthleteDetails } from "../../store/types/athlete-list";
import { withTranslation } from "react-i18next";

const { Content } = Layout;

interface PropsFromDispatch {
  requestAthleteProfileData: typeof requestAthleteProfileData;
  requestConcussionTestData: typeof requestConcussionTestData;
  requestCoachPersonalData: typeof requestCoachPersonalData;
}

interface PropsFromState {
  athleteList: Array<AthleteDetails>;
  athleteProfile: AthleteDetails;
}

interface ProfileState {
  currentId: number;
  athleteIdArray: Array<number>;
  forwardHidden: boolean;
  backwardHidden: boolean;
}
type Props = PropsFromDispatch & (PropsFromState & any);

class AthleteProfile extends Component<Props, ProfileState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentId: 0,
      athleteIdArray: [],
      forwardHidden: false,
      backwardHidden: false,
    };
  }

  componentDidMount() {
    const { coachData, requestCoachPersonalData } = this.props
    if (coachData.id === "") {
      requestCoachPersonalData();
    } else {
      let id = parseInt(this.props.match.params.id);
      this.getAthleteProfile(id);
      this.props.requestConcussionTestData(coachData.teamSelection);
      const { AthleteList } = this.props;
      var tempIdArray = _.map(AthleteList, "athleteId");
      this.setState({
        athleteIdArray: tempIdArray,
      });
      this.setState({
        forwardHidden: this.IsTheLastIndex(this.state.currentId),
        backwardHidden: this.IsTheFirstIndex(this.state.currentId)
      })
    }
  }

  componentDidUpdate(prevProps: any) {
    const { coachData, AthleteProfile } = this.props;
    let id = parseInt(this.props.match.params.id);
    if (coachData !== prevProps.coachData) {
      if (coachData) {
        this.getAthleteProfile(id);
        this.props.requestConcussionTestData(coachData.teamSelection);
        const { AthleteList } = this.props;
        var tempIdArray = _.map(AthleteList, "athleteId");
        this.setState({
          athleteIdArray: tempIdArray,
        });
      }
    }
    if (AthleteProfile !== prevProps.AthleteProfile) {
      this.setState({
        forwardHidden: this.IsTheLastIndex(this.state.currentId),
        backwardHidden: this.IsTheFirstIndex(this.state.currentId)
      })
    }
  }

  getAthleteProfile = (id: number) => {
    const { requestAthleteProfileData } = this.props;
    this.setState({
      currentId: id,
    });
    requestAthleteProfileData(id);
  };

  onClickForward = () => {
    var id = this.state.currentId
    var currentIndex = this.state.athleteIdArray.indexOf(id);
    var newId;
    if (!this.IsTheLastIndex(id)) {
      newId = this.state.athleteIdArray[currentIndex + 1];
      this.getAthleteProfile(newId);
    }
    this.setState({
      forwardHidden: this.IsTheLastIndex(this.state.currentId),
      backwardHidden: this.IsTheFirstIndex(this.state.currentId),
    })
  };

  onClickBackward = () => {
    var id = this.state.currentId
    var currentIndex = this.state.athleteIdArray.indexOf(id);
    if (!this.IsTheFirstIndex(id)) {
      this.getAthleteProfile(this.state.athleteIdArray[currentIndex - 1]);
    }
    this.setState({
      forwardHidden: this.IsTheLastIndex(this.state.currentId),
      backwardHidden: this.IsTheFirstIndex(this.state.currentId),
    })
  };

  IsTheLastIndex = (currentValue: number): boolean => {
    var last = _.last(this.state.athleteIdArray);
    if (currentValue === last) {
      return true;
    } else {
      return false;
    }
  };

  IsTheFirstIndex = (currentValue: number): boolean => {
    var first = _.first(this.state.athleteIdArray);
    if (first === currentValue) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { t } = this.props;
    const { AthleteProfile, i18n } = this.props;
    i18n.on('languageChanged', (lng: any) => {
      let id = parseInt(this.props.match.params.id);
      this.getAthleteProfile(id);
    })
    return (
      <Layout>
        <Content className="main-content athlete-profile">
          <div className="row">
            <div className="athlete-profile-nav-bar">
              <div className="col-text-left">
                <Link to="/athletes" className="ant-btn-link">
                  <Icon type="arrow-left" className='circle-icon'></Icon>
                  <span>{t("Back to Athlete List")}</span>
                </Link>
              </div>
              <div className="col-text-right">

                <Button
                  onClick={() => this.onClickBackward()}
                  icon="arrow-left"
                  className="ant-btn-circle"
                  disabled={this.state.backwardHidden}
                />
                <Button
                  onClick={() => this.onClickForward()}
                  icon="arrow-right"
                  className="ant-btn-circle"
                  disabled={this.state.forwardHidden}
                />

              </div>
            </div>
            <Row>
              <AthleteProfileCard {...AthleteProfile} />
            </Row>
          </div>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = ({
  athleteList,
  athleteProfileData,
  coachPersonalData,
}: ApplicationState) => ({
  AthleteList: athleteList.athleteListData,
  AthleteProfile: athleteProfileData.athleteProfileData,
  coachData: coachPersonalData.coachData,
});

const mapDispatchToProps = {
  requestAthleteProfileData: requestAthleteProfileData,
  requestConcussionTestData: requestConcussionTestData,
  requestCoachPersonalData: requestCoachPersonalData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('athlete-profile')(AthleteProfile));