export interface RegisterCoachIDObject {
  Id: string;
  PushToken: string;
}
export interface RequestAthleteConcussionTestPermission {
  id: number,
  data: {
    athleteId: string;
    athleteFirstName: string;
    athleteLastName: string;
    position: string
    requestedTime: string
  }
}
export interface ResponseAthleteConcussionTestPermission {
  athleteId: string;
  permission: boolean;
  type?: string;
  teamSelection: number;
}
export interface AthleteRequestConcussionTestListState {
  athleteListConcussionTestRequestPermission: Array<RequestAthleteConcussionTestPermission>;
  forceRequestMessage: string
}
export interface AthleteResponseConcussionTestListState {
  athleteConcussionTestResponsePermission: ResponseAthleteConcussionTestPermission;
  permissionReplyMessage: string
}
export interface RegisterCoachIDState {
  registerCoachID: RegisterCoachIDObject;
  registerCoachResponseMessage: string
}

export enum NotificationTypes {
  REGISTERCOACHNOTIFICATION_REQUEST = "@@notification/REGISTERCOACHNOTIFICATION_REQUEST",
  REGISTERCOACHNOTIFICATION_RESPONSEMESSSAGE = "@@notification/REGISTERCOACHNOTIFICATION_RESPONSEMESSSAGE",


  REQUESTCONCUSSIONTESTPERMISSIONLIST_REQUEST = "@@notification/REQUESTCONCUSSIONTESTPERMISSIONLIST_REQUEST",
  REQUESTCONCUSSIONTESTPERMISSIONLIST_RESPONSE = "@@notification/REQUESTCONCUSSIONTESTPERMISSIONLIST_RESPONSE",
  REQUESTCONCUSSIONTESTPERMISSIONLIST_REPLYMESSAGE = "@@notification/REQUESTCONCUSSIONTESTPERMISSIONLIST_REPLYMESSAGE",


  RESPONSECONCUSSIONTESTPERMISSIONLIST_SUBMIT = "@@notification/RESPONSECONCUSSIONTESTPERMISSIONLIST_SUBMIT",
  RESPONSECONCUSSIONTESTPERMISSIONLIST_REPLYMESSAGE = "@@notification/RESPONSECONCUSSIONTESTPERMISSIONLIST_REPLYMESSAGE",
  RESPONSECONCUSSIONTESTPERMISSIONLIST_DISMISS = "@@notification/RESPONSECONCUSSIONTESTPERMISSIONLIST_DISMISS",

  REQUESTCONCUSSIONTESTPERMISSIONLIST_INSERT = "@@notification/REQUESTCONCUSSIONTESTPERMISSIONLIST_INSERT",
  RESPONSECONCUSSIONTESTPERMISSIONLIST_APPEND = "@@notification/RESPONSECONCUSSIONTESTPERMISSIONLIST_APPEND",
}

export enum NotificationScenarios {
  TRANSFERCLUB = "TRANSFERCLUB",
  TRANSFERCLUBREQUEST = "TRANSFERCLUBREQUEST",
}
