//package imports
import { combineReducers } from "redux";
import { fork, all } from "redux-saga/effects";
import { History } from "history";
import { connectRouter, RouterState } from "connected-react-router";

//types
import { ConcussionTableState } from "./types/concussion-table";
import { DashboardWidgetState } from "./types/dashboard-widgets";
import { CoachState, CoachUpdateState } from "./types/coach-profile";
import { AthleteListState } from "./types/athlete-list";
import { TeamListState } from "./types/team-selection";
import { AthleteProfileState } from "./types/athlete_profile";
import { ConcussionTestDetailsState } from "./types/concussion-test-details";
import { SportPositionsState } from "./types/sport-positions";
import { AthleteCardState } from "./types/athlete-card";
import {
  InviteAthleteState,
  BulkEmailCheckAthleteState,
} from "./types/invite-athlete";
import {
  RegisterCoachIDState,
  AthleteResponseConcussionTestListState,
  AthleteRequestConcussionTestListState,
} from "./types/notification";
import { SidelineTestState } from "./types/sideline-test";

//saga
import tableSaga from "./sagas/concussion-table";
import widgetSaga from "./sagas/dashboard-widgets";
import notificationRequestResponseSaga from "./sagas/notification";
import teamListSaga from "./sagas/team-selection";
import CoachPersonalSaga from "./sagas/coach-profile";
import athleteListSaga from "./sagas/athlete-list";
import athleteCardSaga from "./sagas/athlete-card";
import inviteAthleteSaga from "./sagas/invite-athlete";
import athleteProfileSaga from "./sagas/athlete-profile";
import concussionTestDetailsSaga from "./sagas/concussion-test-details";
import sportPositionsSaga from "./sagas/sport-positions";
import sidelineTestSaga from "./sagas/sideline-test"

//reducers
import { tableReducer } from "./reducers/concussion-table";
import { teamListReducer } from "./reducers/team-selection";
import { widgetReducer } from "./reducers/dashboard-widgets";
import { coachReducer, coachUpdateReducer } from "./reducers/coach-profile";
import { athleteListReducer } from "./reducers/athlete-list";
import { athleteCardReducer } from "./reducers/athlete-card";
import { athleteProfileReducer } from "./reducers/athlete-profile";
import { concussionTestDetailsReducer } from "./reducers/concussion-test-details";
import { sportPositionsReducer } from "./reducers/sport-positions";
import {
  inviteAthleteReducer,
  bulkEmailCheckReducer,
} from "./reducers/invite-athlete";
import {
  registerCoachReducer,
  requestPendingPermissionReducer,
  responseCoachPermissionReducer,
} from "./reducers/notification";
import { sidelineTestReducer } from "./reducers/sideline-test"

export interface ApplicationState {
  concussionTableData: ConcussionTableState;
  dashboardWidgetsResult: DashboardWidgetState;
  router: RouterState;
  coachPersonalData: CoachState;
  coachUpdateMessageData: CoachUpdateState;
  athleteList: AthleteListState;
  athleteRemovalStatus: AthleteCardState;
  inviteAthleteData: InviteAthleteState;
  athleteProfileData: AthleteProfileState;
  bulkEmailCheckData: BulkEmailCheckAthleteState;
  concussionTestDetails: ConcussionTestDetailsState;
  sportPositions: SportPositionsState;
  registerCoachIDDetails: RegisterCoachIDState;
  athleteResponseConcussionTestListDetails: AthleteResponseConcussionTestListState;
  athleteRequestConcussionTestListDetails: AthleteRequestConcussionTestListState;
  teamListData: TeamListState;
  sidelineTestData: SidelineTestState;
}
export const createRootReducer = (history: History) =>
  combineReducers({
    concussionTableData: tableReducer,
    dashboardWidgetsResult: widgetReducer,
    coachPersonalData: coachReducer,
    coachUpdateMessageData: coachUpdateReducer,
    athleteList: athleteListReducer,
    athleteRemovalStatus: athleteCardReducer,
    inviteAthleteData: inviteAthleteReducer,
    athleteProfileData: athleteProfileReducer,
    bulkEmailCheckData: bulkEmailCheckReducer,
    concussionTestDetails: concussionTestDetailsReducer,
    sportPositions: sportPositionsReducer,
    registerCoachIDDetails: registerCoachReducer,
    athleteResponseConcussionTestListDetails: responseCoachPermissionReducer,
    athleteRequestConcussionTestListDetails: requestPendingPermissionReducer,
    teamListData: teamListReducer,
    sidelineTestData: sidelineTestReducer,
    router: connectRouter(history),
  });

//we use redux saga to trigger actions asynchronously
export function* rootSaga() {
  yield all([
    fork(tableSaga),
    fork(athleteListSaga),
    fork(athleteCardSaga),
    fork(widgetSaga),
    fork(CoachPersonalSaga),
    fork(inviteAthleteSaga),
    fork(athleteProfileSaga),
    fork(concussionTestDetailsSaga),
    fork(sportPositionsSaga),
    fork(notificationRequestResponseSaga),
    fork(teamListSaga),
    fork(sidelineTestSaga)
  ]);
}
