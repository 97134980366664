import React, { Component } from "react";
import "./sideline-form.less";
import { Button, Typography, Row, Col } from "antd";
const { Text, Title } = Typography;

class SidelineForm extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      questionList: []
    };
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.questionList !== prevProps.questionList) {
      this.setState({
        questionList: this.props.questionList
      })
    }
  }

  componentDidMount(){
    this.setState({
        questionList: this.props.questionList
      })
  }

  changeItemValue(index: any, value: number) {
    const questionList = this.state.questionList
    this.props.isSymptoms ? questionList[index].SidelineSymptomId = value :  questionList[index].SidelineQuestionId = value;
    this.setState({
      questionList
    })
    this.props.disabledChecker(this.state.questionList)
  }

  buttonStatus = (question:{SidelineQuestionId:number,SidelineSymptomId:number}) : number => this.props.isSymptoms ? question.SidelineSymptomId :  question.SidelineQuestionId;

  render() {
    const { questionList } = this.state;
    const { title, description, buttonOne, buttonTwo } = this.props;
    return (
      <div>
        <Row>
          <Title level={4} className="sideline-sub-heading">{title}</Title>
          <Text>{description}</Text>
        </Row>
        <Row className="sideline-question-row-wrap">
          {questionList.map((item: any, index: any) =>         
            <Row key={item.id} className="sideline-question-row">
            <Col lg={16} md={10} xs={8}>
              <Text>{item.question}</Text>
            </Col>
            <Col lg={4} md={7} xs={8} >
              <Button type={this.buttonStatus(questionList[index]) === 1 ? "primary" : "default"} onClick={() => this.changeItemValue(index, 1)} disabled={this.props.currentAthlete === undefined}>{buttonOne}</Button>
            </Col>
            <Col lg={4} md={7} xs={8}>
              <Button type={this.buttonStatus(questionList[index]) === 2 ? "primary" : "default"} onClick={() => this.changeItemValue(index, 2)} disabled={this.props.currentAthlete === undefined}>{buttonTwo}</Button>
            </Col>
          </Row>
          )}
        </Row>
      </div>
    );
  }
}
export default (SidelineForm);
