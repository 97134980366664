import React, { FC, MouseEventHandler, useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import "../../styles/layout/common-styles.less";

interface ConfirmationModalProps {
  type: string;
  visibleConfirmModal: boolean;
  handleConfirmModalCancel: () => void;
  removeAthletes: () => void;
  deleteAthlete: () => void;
  removePendingAthlete: () => void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  type,
  visibleConfirmModal,
  handleConfirmModalCancel,
  removeAthletes,
  deleteAthlete,
  removePendingAthlete,
}) => {
  interface Content {
    title: string;
    bodyText: string;
    btnEvent: () => void;
  }
  const [bodyContent, setBodyContent] = useState<Content>({
    title: "",
    bodyText: "",
    btnEvent: () => {},
  });
  const { t } = useTranslation("athletes");

  const getContent = (type: string): Content => {
    switch (type) {
      case "cancel":
        return {
          title: t("Confirm Cancel"),
          bodyText: t("Do you want to delete the person?"),
          btnEvent: removePendingAthlete,
        };
      case "remove":
        return {
          title: t("Confirm Remove"),
          bodyText: t("Remove Athlete confirm"),
          btnEvent: removeAthletes,
        };
      case "delete":
        return {
          title: t("Confirm Remove"),
          bodyText: t("Delete_Athlete_confirm"),
          btnEvent: deleteAthlete,
        };
      default:
        return { title: "", bodyText: "", btnEvent: () => {} };
    }
  };

  useEffect(() => {
    const text = getContent(type);
    setBodyContent(text);
  }, [type]);

  return (
    <>
      <Modal
        title={bodyContent.title}
        visible={visibleConfirmModal}
        onCancel={handleConfirmModalCancel}
        footer={[
          <Button
            key="cancel"
            className="cancel-button"
            onClick={handleConfirmModalCancel}
          >
            {t("No")}
          </Button>,
          <Button
            key="update"
            type="primary"
            className="confirm-action-button"
            onClick={bodyContent.btnEvent}
          >
            {t("Yes")}
          </Button>,
        ]}
      >
        <p>{bodyContent.bodyText}</p>
      </Modal>
    </>
  );
};
export default ConfirmationModal;
