import { action } from "typesafe-actions";
import {
  ConcussionTestDetailsState,
  ConcussionTestDetailsTypes
} from "../types/concussion-test-details";

export const requestConcussionTestData = (teamSelection: number) =>
  action(ConcussionTestDetailsTypes.CONCUSSION_TEST_DETAILS_REQUEST, teamSelection);

export const requestConcussionTestSuccess = (
  concussionTestData: ConcussionTestDetailsState
) =>
  action(
    ConcussionTestDetailsTypes.CONCUSSION_TEST_DETAILS_SUCCESS,
    concussionTestData
  );

export const requestConcussionTestError = () =>
  action(ConcussionTestDetailsTypes.CONCUSSION_TEST_DETAILS_ERROR);