import { call, all, put, fork, takeLatest } from "redux-saga/effects";
import { createBrowserHistory } from "history";

import {
  requestConcussionTestSuccess,
  requestConcussionTestData,
  requestConcussionTestError
} from "../actions/concussion-test-details";
import { apiCaller } from "../../utils/apicaller";
import { ConcussionTestDetailsTypes } from "../types/concussion-test-details";
import { AxiosResponse } from "axios";

export const history = createBrowserHistory();

const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;

function* fetchConcussionTestDetailsAsync(
  action: ReturnType<typeof requestConcussionTestData>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const response: AxiosResponse = yield call(
      apiCaller,
      "get",
      API_ENDPOINT,
      `Coach/${action.payload}/StepProgressDetails`
    );

    if (response.data.error || response.status === 500) {
      yield put(requestConcussionTestError());
    } else {
      yield put(requestConcussionTestSuccess(response.data));
    }
  } catch (err) {
    yield put(requestConcussionTestError());
  }
}

//this is watcherfunction we use takeLatest to watch redux actions which should go through saga

function* watchFetchConcussionTestDetails() {
  yield takeLatest(
    ConcussionTestDetailsTypes.CONCUSSION_TEST_DETAILS_REQUEST,
    fetchConcussionTestDetailsAsync
  );
}
// We can also use `fork()` here to split our saga into multiple watchers.

function* concussionTestDetailsSaga() {
  yield all([fork(watchFetchConcussionTestDetails)]);
}

export default concussionTestDetailsSaga;