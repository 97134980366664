import React from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import App from "./App";
import PasswordReset from "./components/password-reset/password-reset";
import TeamSelection from "./pages/team-selection/team-selection";
import jwt from "jwt-decode";
import { ConfigProvider } from "antd";
import { withTranslation } from "react-i18next";
import svSE from 'antd/lib/locale/sv_SE';
import enUS from 'antd/lib/locale/en_US';

const history = createBrowserHistory({ basename: "" });
var reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

class Routes extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      role: "",
      currentLocale: {}
    };
  }

  componentWillMount() {
    const token = sessionStorage.getItem("msal.idtoken");
    if (token) {
      const user: any = jwt(token);
    }
  }

  changeConfigLocale(language: any) {
    switch (language) {
      case 'sv-SE':
        this.setState({
          currentLocale: svSE
        })
        break;
      case 'en-US':
        this.setState({
          currentLocale: enUS
        })
        break;
    }
  }

  render() {
    this.props.i18n.on('languageChanged', (lng: any) => {
      this.changeConfigLocale(lng)
    })
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/password-reset" component={PasswordReset} />
          <Route exact path="/team-selection" component={TeamSelection} />
          <ConfigProvider locale={this.state.currentLocale}>
            <Route path="/" component={App} />
          </ConfigProvider>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default withTranslation()(withRouter(withAITracking(reactPlugin, Routes)));
