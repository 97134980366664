import React, { FC } from "react";
import { Row, Col, Button } from "antd";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import "./informationNotification.less";

interface Athlete {
  id: number;
  data: {
    athleteId: string;
    athleteFirstName: string;
    athleteLastName: string;
    position: string;
    scenario: string;
    requestedTime: Date;
  };
}

interface InformationNotificationProps {
  athlete: Athlete;
  dismissNotification: (athleteId: string, id: number) => void;
}

const InformationNotificationComponent: FC<InformationNotificationProps> = ({
  athlete,
  dismissNotification,
}) => {
  const { t } = useTranslation();

  const {
    athleteId,
    athleteFirstName,
    athleteLastName,
    position,
    scenario,
    requestedTime,
  } = athlete.data;

  return (
    <div className="notifications green">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <span className="content-left">
            <span>
              {athleteFirstName} {athleteLastName} -{position}
              {scenario === "CONSULT"
                ? " behöver träffa en specialist för konsultation av sin hjärnskakning."
                : scenario === "RECOVERED"
                ? " är nu 100% återställd!"
                : scenario === "NOTIFIEDCLUBTRANSFER"
                ? " har gjort en övergång till ett annat lag"
                : ""}
              -
              {
                <Moment fromNow utc>
                  {requestedTime}
                </Moment>
              }
            </span>
          </span>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="content-right">
          <Button
            className="btn"
            type="primary"
            onClick={() => {
              dismissNotification(athleteId, athlete.id);
            }}
          >
            {t("Dismiss")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default InformationNotificationComponent;
