import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';

var language = localStorage.getItem('language');

if (language === undefined || language === null) {
  language = 'sv-SE';
  localStorage.setItem('language', language);
}

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en-US',
    lng: language,
    // debug only when not in production
    debug: process.env.NODE_ENV !== 'production',
    ns: [
      'common',
      'coach-profile',
      'dashboard',
      'layout',
      'team-selection',
      'athletes',
      'athlete-profile',
      'sideline-test',
    ],
    defaultNS: 'common',
    fallbackNS: 'common',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
    backend: {
      projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
      apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
      referenceLng: 'en-US',
      version: process.env.REACT_APP_LOCIZE_VERSION,
    },
    saveMissing: process.env.NODE_ENV !== 'production', // add missing keys to locize backend automatically only in production
  });

export default i18n;
