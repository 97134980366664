import { action } from "typesafe-actions";
import {
  AthleteProfileState,
  AthleteProfileTypes
} from "../types/athlete_profile";

export const requestAthleteProfileData = (athleteId: number) =>
  action(AthleteProfileTypes.ATHLETE_PROFILE_REQUEST, athleteId);

export const requestAthleteProfileDataSuccess = (
  athleteProfileData: AthleteProfileState
) => action(AthleteProfileTypes.ATHLETE_PROFILE_SUCCESS, athleteProfileData);

export const requestAthleteProfileDataError = () =>
  action(AthleteProfileTypes.ATHLETE_PROFILE_ERROR);