import React, { Component } from "react";
import {
  Layout,
  Typography,
  Row,
  Col,
  Input,
  Card
} from "antd";
import "../../styles/coach-profile/coach-profile.less";
import Form, { FormComponentProps } from "antd/lib/form";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { Icon } from "antd";
import {
  requestCoachPersonalData,
  updateCoachPersonalData
} from "../../store/actions/coach-profile";
import {
  CoachDetails,
  CoachUpdateDetails
} from "../../store/types/coach-profile";
import i18next from 'i18next';
import { withTranslation, I18nextProviderProps } from "react-i18next";

const { Content } = Layout;
const { Title } = Typography;

interface PropsFromState {
  coachData: CoachDetails;
  coachUpdateDetails: CoachUpdateDetails;
  updateMessage: string;
  t: any
}
interface PropsFromDispatch {
  requestCoachPersonalData: typeof requestCoachPersonalData;
  updateCoachPersonalData: typeof updateCoachPersonalData;
}
type AllProps = FormComponentProps & PropsFromState & PropsFromDispatch & I18nextProviderProps;
class CoachProfile extends Component<AllProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      username: "'",
      previewVisible: false,
      imagePreviewUrl: "",
      fileList: [
        {
          uid: "-2",
          name: "image.png",
          status: "done",
          url:
            "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        }
      ],

      firstName: "",
      lastName: "",
      email: "",
      team: "",
      club: "",
      sport: "",
      editCoachInfo: true
    };
  }
  componentDidMount = () => {
    const { requestCoachPersonalData } = this.props;
    requestCoachPersonalData();
  };
  getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  handleChange = (fileList: []) => this.setState({ fileList });
  uploadButton = () => (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  fileChangedHandler = (event: any) => {
    this.setState({
      selectedFile: event.target.files
    });

    let reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result
      });
    };

    reader.readAsDataURL(event.target.files);
  };
  changeCoachDetails = (e: any) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });
  };
  changeCoachDetailsOfSelect = (e: any) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    });
  };
  confirmChangeCoachDetails = () => {
    var coachObj: CoachUpdateDetails = {
      firstName:
        this.state.firstName === ""
          ? this.props.coachData.firstName
          : this.state.firstName,
      lastName:
        this.state.lastName === ""
          ? this.props.coachData.lastName
          : this.state.lastName,
      email:
        this.state.email === ""
          ? this.props.coachData.email
          : this.state.email,
      team:
        this.state.team === "" ? this.props.coachData.team : this.state.team,
      club:
        this.state.club === "" ? this.props.coachData.club : this.state.club,
      sport:
        this.state.sport === ""
          ? this.props.coachData.sport
          : this.state.sport,
      id: this.props.coachData.id,
      isActivated: false,
      clubSportTeamId: "1"
    };
    const { updateCoachPersonalData: CoachPersonalDataUpdate } = this.props;
    CoachPersonalDataUpdate(coachObj);
  };

  finalClubList = (clubNames: Array<string>): string => clubNames.reduce((finalList: Array<string>, currentClubName: string) => {
    let indexOfClubName = finalList.findIndex(clubName => clubName === currentClubName);
    if (indexOfClubName === -1) {
      finalList = [...finalList, currentClubName]
    }
    return finalList;
  }, []).join(', ');

  render() {
    const {
      coachData: CoachData,
      t // TODO: some translations are fetched using i18next.t() and this behaves differently from t() passed from props
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    let $imagePreview = (
      <div className="previewText image-container">
        <img
          src="http://www.ccchs.org/wp-content/uploads/coach-avatar-flat.jpg"
          alt="icon"
        />
      </div>
    );
    if (this.state.imagePreviewUrl) {
      $imagePreview = (
        <div className="image-container">
          <img src={this.state.imagePreviewUrl} alt="icon" />{" "}
        </div>
      );
    }

    this.props.i18n.on('languageChanged', (lng: any) => {
      this.props.requestCoachPersonalData()
    })

    return (
      <Layout>
        <Content className="main-content profile-settings">
          <Title className="h1">{t('Edit profile')}</Title>
          <Card title="" bordered={false} className="profile-card">
            <Form style={{ backgroundColor: "white" }}>
              <Row>
                <Col span={24}>
                  <br />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                  <Form.Item label={i18next.t('Firstname')}>
                    {getFieldDecorator("firstName", {
                      initialValue: CoachData.firstName,
                      rules: [
                        {
                          required: false,
                          message: "Förnamn"
                        }
                      ]
                    })(
                      <Input
                        size="large"
                        placeholder="First Name"
                        onChange={this.changeCoachDetails}
                        disabled={this.state.editCoachInfo}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label={i18next.t('Lastname')}>
                    {getFieldDecorator("lastName", {
                      initialValue: CoachData.lastName,
                      rules: [
                        {
                          required: false,
                          message: "Please input your last name"
                        }
                      ]
                    })(
                      <Input
                        size="large"
                        placeholder="Last Name"
                        onChange={this.changeCoachDetails}
                        disabled={this.state.editCoachInfo}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="Email">
                    {getFieldDecorator("email", {
                      initialValue: CoachData.email,
                      rules: [
                        {
                          required: false,
                          message: "Please input your email"
                        }
                      ]
                    })(
                      <Input
                        size="large"
                        placeholder="Email"
                        onChange={this.changeCoachDetails}
                        disabled={this.state.editCoachInfo}
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                  <Form.Item label={i18next.t('Team')}>
                    {getFieldDecorator("team", {
                      initialValue: CoachData.team,
                      rules: [
                        {
                          required: false,
                          message: "Please input your Team"
                        }
                      ]
                    })(
                      <Input
                        size="large"
                        placeholder="Team"
                        onChange={this.changeCoachDetails}
                        disabled={this.state.editCoachInfo}
                      />
                    )}
                  </Form.Item>

                  <Form.Item label={i18next.t('Club')}>
                    {getFieldDecorator("club", {
                      initialValue: this.finalClubList(CoachData.club),
                      rules: [
                        {
                          required: false,
                          message: "Please input your first name"
                        }
                      ]
                    })(
                      <Input
                        size="large"
                        placeholder="First Name"
                        onChange={this.changeCoachDetails}
                        disabled={this.state.editCoachInfo}
                      />
                    )}
                  </Form.Item>
                  <Form.Item label={i18next.t('Sport')}>
                    {getFieldDecorator("sport", {
                      initialValue: CoachData.sport,
                      rules: [
                        {
                          required: false,
                          message: "Please input your last name"
                        }
                      ]
                    })(
                      <Input
                        size="large"
                        placeholder="Last Name"
                        onChange={this.changeCoachDetails}
                        disabled={this.state.editCoachInfo}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = ({
  coachPersonalData,
  coachUpdateMessageData
}: ApplicationState) => ({
  coachData: coachPersonalData.coachData,
  updateMessage: coachUpdateMessageData.updateMessage
});
const mapDispatchToProps = {
  requestCoachPersonalData: requestCoachPersonalData,
  updateCoachPersonalData: updateCoachPersonalData
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create<FormComponentProps>()(withTranslation('coach-profile')(CoachProfile)));
