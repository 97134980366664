import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { BRAINSAFE_AUTH_POLICIES } from "../../config/auth-config";

const tenant = process.env.REACT_APP_TENANT;
const signInPolicy = BRAINSAFE_AUTH_POLICIES.signin;
const passwordResetPolicy = BRAINSAFE_AUTH_POLICIES.passwordReset;
const scopes = process.env.REACT_APP_SCOPES
const tenantSubdomain = tenant.split(".")[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;
const signInAuthority = `${instance}${tenant}/${signInPolicy}`;
const passwordResetAuthority = `${instance}${tenant}/${passwordResetPolicy}`;

// Msal Configurations
const signInConfig = {
  auth: {
    authority: signInAuthority,
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL,
    validateAuthority: false
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CACHE_LOCATION,
    storeAuthStateInCookie: true
  }
};

const passwordResetConfig = {
  auth: {
    authority: passwordResetAuthority,
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL,
    validateAuthority: false
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CACHE_LOCATION,
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [
    "https://graph.microsoft.com/Directory.Read.All",
    scopes
  ]
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/auth.html"
};

export const signInAuthProvider = new MsalAuthProvider(
  signInConfig,
  authenticationParameters,
  options
);

export const passwordResetAuthProvider = new MsalAuthProvider(
  passwordResetConfig,
  authenticationParameters,
  options
);
