import { action } from "typesafe-actions";
import { NotificationTypes, RegisterCoachIDObject, RequestAthleteConcussionTestPermission, ResponseAthleteConcussionTestPermission } from "../types/notification";

///coach registration

export const registerNotificationCoachID = (coachDetails: RegisterCoachIDObject) =>
    action(NotificationTypes.REGISTERCOACHNOTIFICATION_REQUEST, coachDetails);

export const registerNotificationCoachIDResponse = (responseMessage: string) =>
    action(NotificationTypes.REGISTERCOACHNOTIFICATION_RESPONSEMESSSAGE, responseMessage);

///force notification pull

export const requestOldNotification = (payload: { coachId: number, teamSelection: number }) =>
    action(NotificationTypes.REQUESTCONCUSSIONTESTPERMISSIONLIST_REQUEST, payload);

export const requestOldNotificationSuccess = (athleteListConcussionTestRequestPermission: Array<RequestAthleteConcussionTestPermission>) =>
    action(NotificationTypes.REQUESTCONCUSSIONTESTPERMISSIONLIST_RESPONSE, athleteListConcussionTestRequestPermission)


export const requestOldNotificationResponse = (message: string) =>
    action(NotificationTypes.REQUESTCONCUSSIONTESTPERMISSIONLIST_RESPONSE, message);

///response permission

export const responsePermission = (responseNotification: ResponseAthleteConcussionTestPermission) =>
    action(NotificationTypes.RESPONSECONCUSSIONTESTPERMISSIONLIST_SUBMIT, responseNotification);

export const dismissNotification = (athleteID: string, notificationID: number) =>
    action(NotificationTypes.RESPONSECONCUSSIONTESTPERMISSIONLIST_DISMISS, { athleteID, notificationID });

export const responsePermissionReplyMessage = (responseNotificationMessage: string) =>
    action(NotificationTypes.RESPONSECONCUSSIONTESTPERMISSIONLIST_REPLYMESSAGE, responseNotificationMessage);

///save/delete new/old notifications

export const insertNotification = (requestNotification: RequestAthleteConcussionTestPermission) =>
    action(NotificationTypes.REQUESTCONCUSSIONTESTPERMISSIONLIST_INSERT, requestNotification);

export const appendOldNotifications = (athleteID: string) =>
    action(NotificationTypes.RESPONSECONCUSSIONTESTPERMISSIONLIST_APPEND, athleteID);

