import { AthleteDetails } from "./athlete-list";

export interface AthleteProfileState {
  athleteProfileData: AthleteDetails;
}

export enum AthleteProfileTypes {
  ATHLETE_PROFILE_REQUEST = "@@athlete/ATHLETE_PROFILE_REQUEST",
  ATHLETE_PROFILE_SUCCESS = "@@athlete/ATHLETE_PROFILE_SUCCESS",
  ATHLETE_PROFILE_ERROR = "@@athlete/ATHLETE_PROFILE_ERROR"
}