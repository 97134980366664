import React, { FC } from "react";
import { Button, Icon, Typography } from 'antd';
import "../../styles/coach-nonexistent/coach-nonexistent.less";
import AzureAD, { AuthenticationState } from "react-aad-msal";
import { signInAuthProvider } from "../../utils/services/auth/auth-provider";
import { useTranslation } from "react-i18next";

type CoachNonexistentProps = {};

const CoachNonexistent: FC<CoachNonexistentProps> = () => {
    const { t } = useTranslation('coach-profile');

    return (
        <div className="admin-login-page">
            <div className="login-left-col">
                <div className="logo-wrapper">
                    <img className="logo" src="https://brainsafestorage.blob.core.windows.net/bransafeblobcontainer/images/final-logo-white.svg" alt=""/>
                </div>
                <img className="background-image" src="https://brainsafestorage.blob.core.windows.net/bransafeblobcontainer/images/login-admin-bg.png" alt=""/>
            </div>
            <div className="login-right-col">
                <div className="outlined-container">
                    <Icon type="exclamation-circle" style={{ fontSize: 45, marginBottom: 50 }} />
                    <Typography.Title level={3} id="title">{t("coach_nonexistent.warning_title")}</Typography.Title>
                    <Typography.Text type="secondary" id="description">{t("coach_nonexistent.warning_body")}</Typography.Text>

                    <AzureAD provider={signInAuthProvider}>
                        {({ logout, authenticationState }: any) => {
                            switch (authenticationState) {
                                case AuthenticationState.Authenticated:
                                return (
                                    <Button id="back-link" type="link" size="small" onClick={() => logout()}>{t("coach_nonexistent.back")}</Button>
                                );
                            }
                        }}
                    </AzureAD>
                </div>
            </div>
        </div>
    );
}

export default CoachNonexistent;