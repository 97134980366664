import React, { FC } from "react";
import { Row, Col, Button } from "antd";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import "./confirmationNotification.less";

interface Athlete {
  id: number;
  data: {
    athleteId: string;
    athleteFirstName: string;
    athleteLastName: string;
    position: string;
    scenario: string;
    requestedTime: Date;
  };
}

interface ConfirmationNotificationProps {
  athlete: Athlete;
  sendPermission: (
    permission: boolean,
    athleteId: string,
    type: string,
    notificationId: number
  ) => void;
}

const ConfirmationNotificationComponent: FC<ConfirmationNotificationProps> = ({
  athlete,
  sendPermission,
}) => {
  const { t } = useTranslation();

  const {
    athleteId,
    athleteFirstName,
    athleteLastName,
    position,
    scenario,
    requestedTime,
  } = athlete.data;
  return (
    <div className="notifications blue">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <span className="content-left">
            <span>
              {athleteFirstName} {athleteLastName} - {position}{" "}
              {scenario === "APPROVAL"
                ? " begär godkännande för att starta rehabilitering p.g.a. av en befarad hjärnskakning"
                : scenario === "UNBLOCK"
                ? " Active requests approval to get unblocked from a contact specialist"
                : scenario === "TRANSFERCLUBREQUEST"
                ? " vill byta till ert lag?"
                : ""}
              -{" "}
              {
                <Moment fromNow utc>
                  {requestedTime}
                </Moment>
              }
            </span>
          </span>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="content-right">
          <Button
            className="btn"
            type="primary"
            onClick={() => {
              sendPermission(true, athleteId, scenario, athlete.id);
            }}
          >
            {t("Approve")}
          </Button>
          <Button
            className="active"
            onClick={() => {
              sendPermission(false, athleteId, scenario, athlete.id);
            }}
          >
            {t("Decline")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmationNotificationComponent;
