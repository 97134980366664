import { IAthleteDetail } from "../../pages/athletes/athletes"

export interface NewAthlete{
    firstName:string,
    lastName:string,
    email:string
}
export interface EmailList{
    email:string
}
export interface EmailListValidityResponse{
    email:string
    validity:boolean
}
export interface InviteAthleteState {
    athleteData: Array<NewAthlete>
    successMessage:number
    email:string
    emailValidMessage:string
    isAvailable: boolean
    getAthleteByEmailError: boolean
    getAthleteByEmailLoading: boolean
    existingAthleteDetails: IAthleteDetail | null
}
export interface BulkEmailCheckAthleteState {
    emailList: Array<EmailList>
    emailListValidityResponse:Array<EmailListValidityResponse>  
}

export enum InviteAthleteTypes{
    GET_ATHLETE_BY_EMAIL='@@inviteathlete/GET_ATHLETE_BY_EMAIL',
    GET_ATHLETE_BY_EMAIL_SUCCESS='@@inviteathlete/GET_ATHLETE_BY_EMAIL_SUCCESS',
    GET_ATHLETE_BY_EMAIL_ERROR='@@inviteathlete/GET_ATHLETE_BY_EMAIL_ERROR',
    INVITEATHLETE_REQUEST='@@inviteathlete/INVITEATHLETE_REQUEST',
    INVITEATHLETE_SUCCESS='@@inviteathlete/INVITEATHLETE_SUCCESS',
    INVITEATHLETE_ERROR='@@inviteathlete/INVITEATHLETE_ERROR',
    CHECKEMAIL_REQUEST='@@inviteathlete/CHECKEMAIL_REQUEST',
    CHECKEMAIL_MESSAGE='@@inviteathlete/CHECKEMAIL_MESSAGE',
    CHECKEMAIL_BULKREQUEST='@@inviteathlete/CHECKEMAIL_BULKREQUEST',
    CHECKEMAIL_BULKMESSAGE='@@inviteathlete/CHECKEMAIL_BULKMESSAGE',
    CHECKEMAIL_BULKERROR='@@inviteathlete/CHECKEMAIL_BULKMESSAGE',
} 