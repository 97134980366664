export interface SportPositionsState {
  sportPositionsData: {
    [key: string]: string[];
  };
}

export enum SportPositionsTypes {
  SPORT_POSITIONS_REQUEST = "@@sportPositions/SPORT_POSITIONS_REQUEST",
  SPORT_POSITIONS_SUCCESS = "@@sportPositions/SPORT_POSITIONS_SUCCESS",
  SPORT_POSITIONS_ERROR = "@@sportPositions/SPORT_POSITIONS_ERROR",
}
