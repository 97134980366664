import { Reducer } from "redux";
import { SidelineTestState, SidelineTestTypes, } from "../types/sideline-test";

export const initialState: SidelineTestState = {
    sidelineTestInformation: {
        athleteList: [],
        overviewEn: "",
        overviewSv: "",
        overviewDa: "",
        overviewNb: "",
        stepOneDescriptionEn: "",
        stepOneDescriptionSv: "",
        stepOneDescriptionDa: "",
        stepOneDescriptionNb: "",
        stepTwoDescriptionEn: "",
        stepTwoDescriptionSv: "",
        stepTwoDescriptionDa: "",
        stepTwoDescriptionNb: "",
        symptomList: [],
        questionList: []
    },
    sidelineTestLoading: false,
    sidelineTest: {
        symptomList: [],
        questionList: []
    },
    responseMessage: 0,
    result: 0
};

const reducer: Reducer<SidelineTestState> = (state = initialState, action) => {
    switch (action.type) {
        case SidelineTestTypes.SIDELINETESTINFORMATION_REQUEST:
            return { ...state, sidelineTestLoading:true };
        case SidelineTestTypes.SIDELINETESTINFORMATION_SUCCESS:
            return { ...state, sidelineTestInformation: action.payload, sidelineTestLoading:false };
        case SidelineTestTypes.SIDELINETESTINFORMATION_ERROR:
            return { ...state, responseMessage: action.payload, sidelineTestLoading:false};
        case SidelineTestTypes.SUBMITSIDELINETEST_REQUEST:
            return { ...state, sidelineTest: action.payload.sidelineTest};
        case SidelineTestTypes.SUBMITSIDELINETEST_SUCCESS:
            return { ...state, result: action.payload };
        case SidelineTestTypes.SUBMITSIDELINETEST_ERROR:
            return { ...state, responseMessage: action.payload };
        default:
            return state;
    }
};

export { reducer as sidelineTestReducer };
