import { Reducer } from "redux";
import { AthleteCardState, AthleteCardTypes } from "../types/athlete-card";

export const initialState: AthleteCardState = {
  athleteRemovalStatus: 0,
  athleteHardRemovalStatus: 0,
};

const reducer: Reducer<AthleteCardState> = (state = initialState, action) => {
  switch (action.type) {
    case AthleteCardTypes.ATHLETE_CARD_REQUEST:
      return { ...state };
    case AthleteCardTypes.ATHLETE_CARD_SUCCESS:
      return { ...state, athleteRemovalStatus: action.payload };
    case AthleteCardTypes.ATHLETE_CARD_ERROR:
      return { ...state, athleteRemovalStatus: action.payload };
    case AthleteCardTypes.ATHLETE_REMOVE_REQUEST:
      return { ...state};
    case AthleteCardTypes.ATHLETE_REMOVE_SUCCESS:
      return {...state, athleteHardRemovalStatus: action.payload}
    case AthleteCardTypes.ATHLETE_REMOVE_ERROR:
      return {...state, athleteHardRemovalStatus: action.payload}
    default:
      return state;
  }
};

export { reducer as athleteCardReducer };
