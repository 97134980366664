import React, { Component } from "react";
import {
  Layout,
  Avatar,
  Icon,
  Col,
  Row,
  Menu,
  Popover,
  Radio,
  Typography,
  Badge,
} from "antd";

import jwt from "jwt-decode";
import "antd/dist/antd.less";
import AzureAD, { AuthenticationState } from "react-aad-msal";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

// import "../../styles/layout/theme-variables.less";
// import "../../styles/layout/common-styles.less";
import { signInAuthProvider } from "../../utils/services/auth/auth-provider";
import { UserRole } from "../../utils/enums/enums";
import TeamSelection from "../../pages/team-selection/team-selection";
import ProfilePic from "../../assets/images/newAvatar.svg";
import { requestTableData } from "../../store/actions/concussion-table";
import { ConcussionTable } from "../../store/types/concussion-table";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { CoachDetails } from "../../store/types/coach-profile";
import { requestCoachPersonalData } from "../../store/actions/coach-profile";
import Notification from "../dashboard-notification";
import Languages from "../../utils/common/languages.json";
import { CircleFlag } from "react-circle-flags";

import { apiCaller } from "../../utils/apicaller";

const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;

const { Header } = Layout;

interface PropsFromDispatch {
  requestTableData: typeof requestTableData;
}
interface PropsFromState {
  concussionTableData: Array<ConcussionTable>;
  coachData: CoachDetails;
}
type AllProps = PropsFromState & PropsFromDispatch;
class HeaderComponent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    var language = localStorage.getItem("language");

    if (!language) {
      language = "sv-SE";
      localStorage.setItem("language", language);
    }

    let countryCode = language.split("-");

    this.state = {
      language: language,
      flag: countryCode[1],
      userRole: UserRole.admin,
      visible: false,
      club: "",
      team: "",
      notificationVisible: false,
      languageLoaded: false,
    };
    this.props.i18n.changeLanguage(language);
  }

  componentWillMount() {
    const token = sessionStorage.getItem("msal.idtoken");
    if (token) {
      const user: any = jwt(token);
      if (user.extension_Role === UserRole.super_admin) {
        this.setState({ userRole: UserRole.super_admin });
      }
    }
  }

  componentDidUpdate() {
    const coachData: CoachDetails = this.props.coachData;

    if (coachData.id !== "" && coachData.locale && !this.state.languageLoaded) {
      const newLocale = coachData.locale;

      this.onLanguageChanged(newLocale);
      this.setState({ languageLoaded: true });
    }
  }

  LoadConcussionTableData = (id: number) => {
    const { requestTableData } = this.props;
    requestTableData(id);
  };

  onLanguageChanged = (language: string) => {
    // let language = event.target.value;
    let countryCode = language.split("-");
    localStorage.setItem("language", language);
    this.setState(() => ({ language: language }));
    this.setState({ flag: countryCode[1] });
    const { coachData: CoachData } = this.props;
    let coachId = parseInt(CoachData.id);
    this.props.i18n.changeLanguage(language);
    this.LoadConcussionTableData(CoachData.teamSelection);

    apiCaller(
      "put",
      API_ENDPOINT,
      `Coach/${coachId}/${language}/updateCoachLocale`
    );
  };

  showModal = () => {
    let newState = { ...this.state };
    newState.visible = true;
    newState.closeModal = this.closeModal;
    this.setState(newState);
  };

  closeModal = () => {
    let newState = { ...this.state };
    newState.visible = false;
    this.setState(newState);
  };

  hideNotification = () => {
    this.setState({
      notificationVisible: false,
    });
  };

  handleVisibleChange = (notificationVisible: any) => {
    this.setState({ notificationVisible });
  };

  render() {
    const { t } = this.props;
    const menuItemStyle = {
      backgroundColor: "white",
      display: "block",
      width: "100%",
      textAlign: "left" as const,
    };
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
      textAlign: "left" as const,
    };

    const clubName =
      this.props.coachData.club[
        this.props.coachData.clubSportTeamIds.indexOf(
          this.props.coachData.teamSelection
        )
      ];

    const teamName =
      this.props.coachData.team[
        this.props.coachData.clubSportTeamIds.indexOf(
          this.props.coachData.teamSelection
        )
      ];

    const menu = (
      <Menu>
        <Menu.Item key="0" style={menuItemStyle}>
          <Link to="/coach">
            <Icon type="user" />
            {t("Edit profile")}
          </Link>
        </Menu.Item>
        <Menu.Item
          // disabled={this.state.userRole === UserRole.super_admin ? false : true} // commented this so this is shown to all coaches
          key="1"
          style={menuItemStyle}
        >
          <a onClick={this.showModal}>
            <Icon type="thunderbolt" />
            {t("Change team")}
          </a>
          <TeamSelection {...this.state}></TeamSelection>
        </Menu.Item>
        <Menu.Item key="3" style={menuItemStyle}>
          <AzureAD provider={signInAuthProvider}>
            {({ logout, authenticationState }: any) => {
              switch (authenticationState) {
                case AuthenticationState.Authenticated:
                  return (
                    <a
                      onClick={() => {
                        localStorage.clear();
                        logout();
                      }}
                    >
                      <Icon type="logout" />
                      {t("Log out")}
                    </a>
                  );
              }
            }}
          </AzureAD>
        </Menu.Item>
        <Menu.Divider />
        <Radio.Group
          style={{ display: "block", width: "100%" }}
          onChange={(e) => this.onLanguageChanged(e.target.value)}
          value={this.state.language}
        >
          <Typography.Text type="secondary">
            {t("CHANGE LANGUAGE")}
          </Typography.Text>

          {Languages.map((language) => (
            <Radio
              key={language.languageCode}
              style={radioStyle}
              value={language.languageCode}
            >
              <CircleFlag countryCode={language.countryCode} height="20" />
              <span style={{ paddingLeft: "10px" }}>
                {language.languageName}
              </span>
            </Radio>
          ))}
        </Radio.Group>
      </Menu>
    );
    return (
      <Header className="main-header">
        <Row>
          <Col xs={24} sm={24} md={15} lg={15} xl={15}>
            <div className="header-left">
              <div className="welcome-msg">{clubName},</div>
              <div className="team-name">{teamName}</div>
            </div>
          </Col>

          <Col
            sm={24}
            md={{ span: 8, offset: 1 }}
            lg={{ span: 8, offset: 1 }}
            xl={{ span: 8, offset: 1 }}
            xs={24}
          >
            <div className="header-right">
              <Popover
                title={
                  <div className="notification-header">
                    <div>{t("Notifications")}</div>
                    <div className="popover-close">
                      <a onClick={this.hideNotification}>
                        <Icon type="close" />
                      </a>
                    </div>
                  </div>
                }
                trigger="click"
                placement="bottomRight"
                overlayClassName="notification-pop"
                arrowPointAtCenter
                visible={this.state.notificationVisible}
                onVisibleChange={this.handleVisibleChange}
                content={
                  <div className="notification-wrap">
                    {" "}
                    <Notification />
                  </div>
                }
              >
                <Badge
                  count={
                    this.props.athleteListConcussionTestRequestPermission.length
                  }
                >
                  <Icon type="bell" />
                </Badge>
              </Popover>
              <Avatar className="avatar" src={ProfilePic} />
              <Popover
                placement="bottom"
                title={""}
                content={menu}
                trigger="click"
                className="hd-name"
                overlayClassName="language-pop"
                overlayStyle={{
                  width: "200px",
                  textAlign: "center",
                  zIndex: 100,
                }}
              >
                <a className="ant-dropdown-link">
                  <span>
                    <AzureAD provider={signInAuthProvider}>
                      {({ accountInfo, authenticationState }: any) => {
                        switch (authenticationState) {
                          case AuthenticationState.Authenticated:
                            return <span>{accountInfo.account.name}</span>;
                        }
                      }}
                    </AzureAD>
                  </span>
                  <Icon type="down" />
                </a>
              </Popover>
            </div>
          </Col>
        </Row>
      </Header>
    );
  }
}

const mapDispatchToProps = {
  requestTableData: requestTableData,
  requestCoachPersonalData: requestCoachPersonalData,
};
const mapStateToProps = ({
  concussionTableData: ConcussionTableData,
  coachPersonalData,
  athleteRequestConcussionTestListDetails:
    AthleteRequestConcussionTestListDetails,
}: ApplicationState) => ({
  concussionTableData: ConcussionTableData.concussionTableResultData,
  athleteListConcussionTestRequestPermission:
    AthleteRequestConcussionTestListDetails.athleteListConcussionTestRequestPermission,
  requestTableData: requestTableData,
  coachData: coachPersonalData.coachData,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("layout")(HeaderComponent));

//export default withTranslation()(HeaderComponent);
