//package imports
import React, { FC, useEffect, useRef, useState } from "react";
import {
  Layout,
  Button,
  Drawer,
  Form,
  Row,
  Input,
  Col,
  Select,
  Tabs,
  Tag,
  List,
  Alert,
  Popconfirm,
  Spin,
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//CSS
import "../../styles/athletes/athlete.less";

//local imports
import CSVUploader from "../../components/athlete/csv-uploader";
import AthleteCard from "../../components/athlete-card/athlete-card";
import Spinner from "../../components/spinner/spinner";
import { ApplicationState } from "../../store";
import { requestAthleteData } from "../../store/actions/athlete-list";
import { AthleteDetails } from "../../store/types/athlete-list";
import {
  requestInviteAthlete,
  getAthleteByEmail,
} from "../../store/actions/invite-athlete";
import { requestSportPositionsData } from "../../store/actions/sport-positions";
import { requestCoachPersonalData } from "../../store/actions/coach-profile";

import {
  FilterMenu,
  HealthStatusMenu,
  BaselineTestMenu,
  RequestStatus,
} from "../../utils/enums/enums";
import { SortAthleteList } from "../../utils/common/functions";
import CustomTag from "../../components/custom-tag/custom-tag";

export interface IAthleteDetail {
  firstName: string;
  lastName: string;
  email: string;
  isExisting?: boolean;
  isSameSport?: boolean;
  athleteId?: number;
}

const Athletes: FC<FormComponentProps> = (props) => {
  const { t, i18n } = useTranslation("athletes");

  const [visible, setVisible] = useState<boolean>(false);
  const [athleteArray, setAhleteArray] = useState<IAthleteDetail[]>([]);
  const [athletes, setAthletes] = useState<AthleteDetails[]>([]);
  const [filteredAthlets, setFilteredAthlets] = useState<AthleteDetails[]>([]);
  const [filterMenuValue, setFilterMenuValue] = useState<string>(
    t("Filter by")
  );
  const [isAddAthleteEmailValidating, setIsAddAthleteEmailValidating] =
    useState<boolean>(false);
  const [healthStatusFilter, setHealthStatusFilter] = useState<string>(
    t("Select")
  );
  const [positionFilter, setPositionFilter] = useState<string>(t("Select"));
  const [baselineTestFilter, setBaselineTestFilter] = useState<string>(
    t("Select")
  );
  const [submitStatus, setSubmitStatus] = useState<string>(RequestStatus.done);
  // const [isAvailableState, setIsAvailableState] = useState<boolean>(false);
  const {
    getFieldDecorator,
    getFieldsValue,
    setFieldsValue,
    setFields,
    getFieldsError,
    isFieldTouched,
    getFieldError,
    validateFields,
    resetFields,
  } = props.form;

  const dispatch = useDispatch();

  const firstNameError =
    isFieldTouched("firstName") && getFieldError("firstName");
  const lastNameError = isFieldTouched("lastName") && getFieldError("lastName");

  /**
   * show emailError only if error is the email-required error when the field has been touched or
   * if the email error is not the required-field error when the field is not touched i.e. don't
   * show the email-required error if the field has not been touched
   */
  const emailError =
    typeof getFieldError("email") !== "undefined" &&
      getFieldError("email")!.length > 0 &&
      (isFieldTouched("email") ||
        (!isFieldTouched("email") &&
          (getFieldError("email") as string[])[0] != t("Email")))
      ? (getFieldError("email") as string[])[0]
      : undefined;

  //consts
  const TabPane = Tabs.TabPane;
  const Content = Layout.Content;
  const Option = Select.Option;
  const Item = Form.Item;

  const padding = {
    paddingTop: "10px",
  };

  const { athleteListData: athleteList, athleteListLoading } = useSelector(
    (state: ApplicationState) => state.athleteList
  );

  const {
    successMessage: inviteAthleteMessage,
    isAvailable,
    getAthleteByEmailLoading,
    existingAthleteDetails: athleteFromEmail,
  } = useSelector((state: ApplicationState) => state.inviteAthleteData);

  const { coachData } = useSelector(
    (state: ApplicationState) => state.coachPersonalData
  );
  const { sportPositionsData: sportPositions } = useSelector(
    (state: ApplicationState) => state.sportPositions
  );

  // to store the previous athleteFromEmail
  const prevAthleteFromEmailRef = useRef<typeof athleteFromEmail>();
  useEffect(() => {
    let errors: string[] = [];
    if (athleteFromEmail?.isExisting) {
      if (athleteFromEmail.isSameSport) {
        setFieldsValue({
          firstName: athleteFromEmail.firstName,
          lastName: athleteFromEmail.lastName,
        });
      } else {
        // different sport therefore set errors
        errors.push(t("invite_athlete.sportMismatch"));
      }

      if (athleteList.find((a) => a.athleteId === athleteFromEmail.athleteId)) {
        errors.push(t("invite_athlete.alreadyInTeam"));
      }

      if (errors.length > 0) {
        validateFields((error, values) => {
          setFields({
            email: {
              value: values.email,
              errors,
            },
          });
        });
      }
    } else {
      if (prevAthleteFromEmailRef.current?.isExisting) {
        setFieldsValue({
          firstName: "",
          lastName: "",
        });
      }
    }
    prevAthleteFromEmailRef.current = athleteFromEmail;
  }, [athleteFromEmail]);

  useEffect(() => {
    if (coachData.id === "") {
      dispatch(requestCoachPersonalData());
    } else {
      LoadAthletes();
      const team = coachData.teamSelection ? coachData.teamSelection : 1;
      dispatch(requestSportPositionsData(team));
    }

    i18n.on("languageChanged", (lng: any) => {
      translateFilter();
      LoadAthletes();
    });
  }, []);

  useEffect(() => {
    setIsAddAthleteEmailValidating(false);
    if (isAvailable) {
      // setIsAvailableState(true);
    }
  }, [isAvailable]);

  useEffect(() => {
    if (coachData.teamSelection) {
      LoadAthletes();
      dispatch(requestSportPositionsData(coachData.teamSelection));
    }
  }, [coachData]);

  useEffect(() => {
    const sortedAthletes = SortAthleteList(athleteList, "asc");
    setAthletes(sortedAthletes);
    setFilteredAthlets(sortedAthletes);
    // setIsAvailableState(isAvailable);
  }, [athleteList]);

  useEffect(() => {
    if (inviteAthleteMessage > 0) {
      switch (inviteAthleteMessage) {
        case 200:
          {
            LoadAthletes();
            updateRequestDone();
          }
          break;
        case 400:
          updateRequestDone();
          break;
        case 401:
          updateRequestDone();
          break;

        default:
          break;
      }
    }
  }, [inviteAthleteMessage]);

  const LoadAthletes = () => {
    const team = coachData.teamSelection;
    if (team) {
      dispatch(requestAthleteData(team));
    }
  };

  const translateFilter = () => {
    if (filterMenuValue === "Filter by" || filterMenuValue === "Filtrera") {
      setFilterMenuValue(t("Filter by"));
    }
  };

  const clearFilters = () => {
    setFilteredAthlets(athletes);
    setFilterMenuValue(t("Filter by"));
    setHealthStatusFilter(t("Select"));
    setPositionFilter(t("Select"));
    setBaselineTestFilter(t("Select"));
  };

  const validateEmail = (email: string) => {
    var re =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const findAthlete = (text: string): void => {
    if (!text) {
      setFilteredAthlets(athletes);
    } else {
      const foundAthletes = athletes.filter(athlete => `${athlete.firstName} ${athlete.lastName}`.toLowerCase().includes(text.toLocaleLowerCase()));
      setFilteredAthlets(foundAthletes);
    }
  }

  const handleChangeFilterMenu = (value: any) => {
    const sortedAthletes = SortAthleteList(athletes, "asc");
    setFilterMenuValue(value);
    setFilteredAthlets(sortedAthletes);
    setHealthStatusFilter(t("Select"));
    setPositionFilter(t("Select"));
    setBaselineTestFilter(t("Select"));
  };

  const handleHealthStatusMenu = (value: any) => {
    setHealthStatusFilter(value);
    var tempAthletes;

    switch (value) {
      case HealthStatusMenu.healthy:
        tempAthletes = athletes.filter(
          ({ healthStatus }: AthleteDetails) => healthStatus === "100"
        );
        break;

      case HealthStatusMenu.concussion:
        tempAthletes = athletes.filter(
          ({ healthStatus }: AthleteDetails) =>
            healthStatus !== "100" &&
            healthStatus !== null &&
            healthStatus !== undefined
        );
        break;
      case HealthStatusMenu.pending:
        tempAthletes = athletes.filter(
          ({ healthStatus }: AthleteDetails) => healthStatus === null
        );
        break;

      default:
        break;
    }
    if (tempAthletes) {
      const sortedAthletes = SortAthleteList(tempAthletes, "asc");
      setFilteredAthlets(sortedAthletes);
    }
  };

  const hasErrors = (fieldsError: Record<string, string[] | undefined>) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  const handlePositionMenu = (value: any) => {
    setPositionFilter(value);
    const tempAthletes = athletes.filter(
      ({ position }: AthleteDetails) => position === value
    );
    const sortedAthletes = SortAthleteList(tempAthletes, "asc");
    setFilteredAthlets(sortedAthletes);
  };

  const handleBaselineTestMenu = (value: any) => {
    setBaselineTestFilter(value);
    var tempAthletes;
    if (value === BaselineTestMenu.complete) {
      tempAthletes = athletes.filter(
        ({ healthStatus, baselineCognitiveDate }: AthleteDetails) =>
          baselineCognitiveDate !== null || healthStatus === "100"
      );
    } else {
      tempAthletes = athletes.filter(
        ({ healthStatus, baselineCognitiveDate }: AthleteDetails) =>
          baselineCognitiveDate == null && healthStatus !== "100"
      );
    }
    const sortedAthletes = SortAthleteList(tempAthletes, "asc");
    setFilteredAthlets(sortedAthletes);
  };

  const validateIndividualAthlete = () => {
    const emailVal = getFieldsValue(["email"]);
    const emailStringVal = emailVal.email.toString();
    var checkSingleEmailValid = true;
    checkSingleEmailValid = checkIfRecordAvailableInArray(emailStringVal);
    validateFields(["firstName", "lastName", "email"]);
  };
  const removeAthlete = (removedAthlete: IAthleteDetail) => {
    const AthleteArray = athleteArray.filter(
      (athlete) => athlete !== removedAthlete
    );

    setAhleteArray(AthleteArray);
  };

  const checkAthleteArrayLength = (): boolean => {
    if (athleteArray.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const checkIfRecordAvailableInArray = (email: string): boolean => {
    setIsAddAthleteEmailValidating(true);

    for (var i = 0; i < athleteArray.length; i++) {
      var tempEmail = athleteArray[i].email;
      if (tempEmail === email) {
        return false;
      }
    }
    setTimeout(() => setIsAddAthleteEmailValidating(false), 5000);
    dispatch(getAthleteByEmail(email));
    return true;
  };

  const sendAPIPostRequestInviteAthlete = () => {
    const TempArray = [...athleteArray];
    setAhleteArray([]);
    setSubmitStatus(RequestStatus.pending);
    dispatch(requestInviteAthlete(TempArray));
  };

  const addAthlete = (values: { [field: string]: any }) => {
    setAhleteArray([...athleteArray, values as IAthleteDetail]);
    resetFields(["firstName", "lastName", "email"]);
  };

  const addAthleteFromCSV = (values: any) => {
    // filter values error such that only non-existing emails will be in tempAthleteArray
    const tempAthleteArray: IAthleteDetail[] = values.filter(
      (v: IAthleteDetail) =>
        athleteArray.findIndex((a: IAthleteDetail) => a.email === v.email) ===
        -1
    );

    setAhleteArray((current) => [...current, ...tempAthleteArray]);
  };

  const showDrawer = () => {
    setVisible(true);
    validateFields(["firstName", "lastName", "email"]);
  };
  const onClose = () => {
    setVisible(false);
    resetFields();
  };

  const secondValidator = async (rule: any, value: any, callback: any) => {
    for (var i = 0; i < athleteArray.length; i++) {
      var tempEmail = athleteArray[i].email;
      if (tempEmail === value) {
        return Promise.reject("This email already added to the list");
      }
    }
  };

  const callback = (key: any) => { };

  const CheckSpaceKey = (e: any) => {
    var key = e.charCode;
    if (key === 32) {
      e.preventDefault();
    }
  };

  const updateRequestDone = () => {
    if (submitStatus === RequestStatus.pending) {
      setSubmitStatus(RequestStatus.done);
      setVisible(false);
    }
  };
  const showSuccessAlert = () => {
    if (inviteAthleteMessage === 200) {
      return (
        <Alert
          message={t("Athletes have been added")}
          type="success"
          closable
          showIcon
        />
      );
    } else if (inviteAthleteMessage === 400 || inviteAthleteMessage === 401) {
      return (
        <Alert message={t("Error occurred")} type="error" closable showIcon />
      );
    }

    if (submitStatus === RequestStatus.pending) {
      return (
        <Alert
          type="info"
          message={
            <div>
              <Spin /> {t("Adding athlete")}
            </div>
          }
        />
      );
    }
  };

  if (!athleteListLoading) {
    return (
      <Layout>
        <Content className="main-content athlete-list">
          <div className="row">
            <Row
              type="flex"
              className="filter-row"
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Col span={20}>
                <Input
                  placeholder={t("Find_Athlete")}
                  style={{ width: '35%', marginRight: '5%' }}
                  onChange={e => findAthlete(e.target.value)}
                />
                <Select
                  placeholder={t("Filter by")}
                  className="dropdown1"
                  value={
                    Object.keys(FilterMenu).indexOf(filterMenuValue) === -1
                      ? t("Filter by")
                      : filterMenuValue
                  }
                  onChange={handleChangeFilterMenu}
                >
                  <Option value={FilterMenu.health_status}>
                    {t("Status")}
                  </Option>
                  <Option value={FilterMenu.position}>
                    {coachData.positionLabel}
                  </Option>
                  <Option value={FilterMenu.baseline_test}>
                    {t("Baseline test")}
                  </Option>
                </Select>
                {filterMenuValue === FilterMenu.health_status ? (
                  <Select
                    placeholder={t("Select")}
                    className="dropdown2"
                    value={healthStatusFilter}
                    onChange={handleHealthStatusMenu}
                  >
                    <Option value="healthy">{t("Healthy")}</Option>
                    <Option value="concussion">{t("Concussion")}</Option>
                    <Option value="pending">{t("Pending")}</Option>
                  </Select>
                ) : filterMenuValue === FilterMenu.position ? (
                  <Select
                    placeholder={t("Select")}
                    className="dropdown2"
                    value={positionFilter}
                    onChange={handlePositionMenu}
                  >
                    {sportPositions[i18n.language].map(
                      (item: any, index: number) => {
                        return (
                          <Option value={sportPositions["en-US"][index]}>
                            {item}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                ) : filterMenuValue === FilterMenu.baseline_test ? (
                  <Select
                    placeholder={t("Select")}
                    value={baselineTestFilter}
                    className="dropdown2"
                    onChange={handleBaselineTestMenu}
                  >
                    <Option value="complete">{t("Completed")}</Option>
                    <Option value="incomplete">{t("Not completed")}</Option>
                  </Select>
                ) : (
                  <Select
                    disabled
                    value={t("Select")}
                    className="dropdown2"
                  ></Select>
                )}

                <Button
                  style={{
                    margin: 10,
                    marginLeft: 30,
                    display: "inline-block",
                    fontSize: 30,
                  }}
                  onClick={clearFilters}
                >
                  {t("Clear Filter")}
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  onClick={showDrawer}
                  id={"invite-athletes"}
                >
                  {t("Invite athletes")}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="list-wrapper">
            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 1,
                xl: 3,
                xxl: 3,
              }}
              dataSource={filteredAthlets}
              renderItem={(item) => (
                <List.Item>
                  <AthleteCard {...item} />
                </List.Item>
              )}
            />
          </div>
        </Content>
        <Drawer
          title={t("Invite athletes")}
          closable={true}
          onClose={onClose}
          visible={visible}
          className="invite-athletes-drawer"
        >
          <Tabs defaultActiveKey="Add Athlete" onChange={callback}>
            <TabPane tab={<span>{t("Add athlete")}</span>} key="Add Athlete">
              <Form layout="vertical" hideRequiredMark>
                <Row>
                  <Col span={24} className="add-athlete-form">
                    <Item
                      validateStatus={emailError ? "error" : ""}
                      help={emailError || ""}
                      label={t("Email") + " *"}
                      style={{ width: "100%" }}
                    >
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            type: "email",
                            message:
                              t("Email") +
                              " " +
                              t("eg") +
                              "- example@example.com",
                          },
                          {
                            required: true,
                            message: t("Email"),
                          },
                          { validator: secondValidator },
                        ],
                      })(
                        <Input
                          placeholder=""
                          name="email"
                          onChange={() => {
                            setTimeout(() => {
                              if (
                                validateEmail(getFieldsValue(["email"]).email)
                              ) {
                                validateIndividualAthlete();
                                validateFields(["email"]);
                              }
                            }, 100);
                          }}
                          onKeyPress={CheckSpaceKey}
                        />
                      )}
                    </Item>

                    <Item
                      validateStatus={firstNameError ? "error" : ""}
                      help={firstNameError || ""}
                      label={t("First Name") + " *"}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 6px",
                      }}
                    >
                      {getFieldDecorator("firstName", {
                        rules: [
                          {
                            required: true,
                            message: t("Firstname"),
                            whitespace: true,
                          },
                        ],
                      })(
                        <Input
                          placeholder=""
                          disabled={
                            athleteFromEmail?.isExisting ||
                            getAthleteByEmailLoading
                          }
                        />
                      )}
                    </Item>
                    <Item
                      validateStatus={lastNameError ? "error" : ""}
                      help={lastNameError || ""}
                      label={t("Last Name") + " *"}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 6px",
                        marginLeft: "12px",
                      }}
                    >
                      {getFieldDecorator("lastName", {
                        rules: [
                          {
                            required: true,
                            message: t("Lastname"),
                            whitespace: true,
                          },
                        ],
                      })(
                        <Input
                          placeholder=""
                          disabled={
                            athleteFromEmail?.isExisting ||
                            getAthleteByEmailLoading
                          }
                        />
                      )}
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} className="add-athlete-btn-wrapper">
                    <Item>
                      <Spin spinning={getAthleteByEmailLoading} />
                      <Button
                        disabled={
                          hasErrors(
                            getFieldsError(["firstName", "lastName", "email"])
                          ) ||
                          isAddAthleteEmailValidating ||
                          getAthleteByEmailLoading ||
                          (athleteFromEmail?.isExisting &&
                            !athleteFromEmail.isSameSport)
                        }
                        onClick={() => {
                          if (
                            validateEmail(getFieldsValue(["email"]).email) &&
                            getFieldsValue(["firstName"]).firstName !== "" &&
                            getFieldsValue(["lastName"]).lastName !== ""
                          ) {
                            addAthlete({
                              ...getFieldsValue([
                                "firstName",
                                "lastName",
                                "email",
                              ]),
                              isExisting: athleteFromEmail?.isExisting,
                              id: athleteFromEmail?.athleteId,
                            });
                          }
                          validateFields(["firstName", "lastName", "email"]);
                        }}
                        id={"add-single-athlete"}
                      >
                        {t("Add athlete")}
                      </Button>
                    </Item>
                  </Col>
                </Row>
              </Form>
              <Row style={padding}>
                <Col>
                  <div className="selected-athletes-wrapper">
                    {athleteArray.map((Athlete, index) => (
                      // <Tag
                      //   className="span-tag"
                      //   closable
                      //   visible={true}
                      //   onClose={() => removeAthlete(Athlete)}
                      //   placeholder="New Athletes list"
                      //   key={index}
                      // >
                      //   {Athlete.firstName + " " + Athlete.lastName}
                      // </Tag>
                      <CustomTag
                        onClose={() => removeAthlete(Athlete)}
                        prependText={Athlete.isExisting ? undefined : "New"}
                        key={index}
                      >
                        {Athlete.firstName + " " + Athlete.lastName}
                      </CustomTag>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row className="save-athlete-btn-wrapper">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="action-col"
                >
                  {athleteArray.length === 0 ? (
                    <Button
                      className="btn-cancel ant-btn-background-ghost ant-btn-primary"
                      onClick={() => {
                        setAhleteArray([]);
                        onClose();
                      }}
                      id="btn-cancel"
                    >
                      {t("Cancel")}
                    </Button>
                  ) : (
                    <Popconfirm
                      title={t(
                        "Are you sure you want to delete existing records?"
                      )}
                      onConfirm={() => {
                        setAhleteArray([]);
                        onClose();
                      }}
                      onCancel={() => { }}
                      okText={t("Yes")}
                      cancelText={t("No")}
                    >
                      <Button className="btn-cancel ant-btn-background-ghost ant-btn-primary">
                        {t("Cancel")}
                      </Button>{" "}
                    </Popconfirm>
                  )}

                  <Button
                    className="btn-save"
                    type="primary"
                    disabled={checkAthleteArrayLength()}
                    onClick={() => {
                      sendAPIPostRequestInviteAthlete();
                    }}
                    id={"invite-all"}
                  >
                    {t("Invite")}
                  </Button>
                </Col>
              </Row>
              <div className="success-alert">{showSuccessAlert()}</div>
            </TabPane>
            <TabPane tab={<span> {t("Upload CSV")}</span>} key="Upload CSV">
              <CSVUploader addAthleteFromCSV={addAthleteFromCSV} />

              <Row gutter={16} style={padding}>
                <Col span={24}>
                  <div className="selected-athletes-wrapper">
                    {athleteArray.map((Athlete) => (
                      <Tag
                        key={Athlete.email}
                        className="span-tag"
                        closable
                        visible={true}
                        onClose={() => removeAthlete(Athlete)}
                      >
                        {Athlete.firstName + " " + Athlete.lastName}
                      </Tag>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row className="save-athlete-btn-wrapper">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className="action-col"
                >
                  {athleteArray.length === 0 ? (
                    <Button
                      id="btn-csv-cancel"
                      className="btn-cancel ant-btn-background-ghost ant-btn-primary"
                      onClick={() => {
                        setAhleteArray([]);
                        onClose();
                      }}
                    >
                      {t("Cancel")}
                    </Button>
                  ) : (
                    <Popconfirm
                      title={t(
                        "Are you sure you want to delete existing records?"
                      )}
                      onConfirm={() => {
                        setAhleteArray([]);
                        onClose();
                      }}
                      onCancel={() => { }}
                      okText={t("Yes")}
                      cancelText={t("No")}
                    >
                      <Button
                        id="btn-delete-cancel"
                        className="btn-cancel ant-btn-background-ghost ant-btn-primary"
                      >
                        {t("Cancel")}
                      </Button>{" "}
                    </Popconfirm>
                  )}
                  <Button
                    className="btn-save"
                    id="btn-csv-invite"
                    type="primary"
                    disabled={checkAthleteArrayLength()}
                    onClick={() => {
                      sendAPIPostRequestInviteAthlete();
                    }}
                  >
                    {t("Invite")}
                  </Button>
                </Col>
              </Row>
              <div className="success-alert">{showSuccessAlert()}</div>
            </TabPane>
          </Tabs>
        </Drawer>
      </Layout>
    );
  }
  return (
    <Layout>
      <Content className="main-content athlete-list">
        <Spinner />
      </Content>
    </Layout>
  );
};

export default Form.create<FormComponentProps>()(Athletes);
