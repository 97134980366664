//package imports
import React, { useEffect } from "react";
import { Store } from "redux";
import { History } from "history";
import { createBrowserHistory } from "history";
import firebase from "firebase";
import { BroadcastChannel as AlternateBroadcastChannel } from 'broadcast-channel';

//local imports
import { ApplicationState } from "./store";

//css
import "./App.less";

// FontAwesome icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faComments, faBell } from "@fortawesome/free-solid-svg-icons";
import ContentComponent from "./components/layout/content";
import { BRAINSAFE_AUTH_POLICIES } from "./utils/config/auth-config";
import AzureAD from "react-aad-msal";
import {
  signInAuthProvider,
  passwordResetAuthProvider,
} from "./utils/services/auth/auth-provider";
import { requestCoachPersonalData } from "./store/actions/coach-profile";

library.add(fab, faComments, faBell);

interface AppProps {
  store: Store<ApplicationState>;
  history: History;
}

const history = createBrowserHistory();

const getCoachDetails = () => {
  requestCoachPersonalData();
};
let tempChannel;
try {
  tempChannel = new BroadcastChannel('sw-messages');
} catch {
  tempChannel = new AlternateBroadcastChannel('sw-messages');
}
const channel = tempChannel;
channel.addEventListener("message", () => {
  history.push("/athletes");
  history.push("/dashboard");
});
const requestPermission = () => {
  var config = {
    apiKey: "AIzaSyDzGlknUn6XGEw8qBXxzxSADM64d-htchE",
    authDomain: "brainsafe-reactnative-431de.firebaseapp.com",
    databaseURL: "https://brainsafe-reactnative-431de.firebaseio.com",
    projectId: "brainsafe-reactnative-431de",
    storageBucket: "brainsafe-reactnative-431de.appspot.com",
    messagingSenderId: "254201310544",
    appId: "1:254201310544:web:b58f05ec6970919b135055",
    measurementId: "G-Q2CFRLMMFN",
  };
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();

    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then((token) => {
        sessionStorage.setItem("coachNotificationToken", token);
      })
      .catch((err: any) => {
        console.log("error occured", err);
      });
  }
};

function reload() {
  sessionStorage.clear();
  localStorage.clear();
  window.location.reload();
}

const App: React.FC<AppProps> = () => {
  useEffect(() => {
    history.push("/dashboard");
  }, []);
  requestPermission();

  // clear localStorage on page reload if not PROD environment
  useEffect(() => {
    const bodyElem: HTMLBodyElement = document.getElementsByTagName("body")[0];
    bodyElem.onunload = () => {
      sessionStorage.clear();
      if (false && process.env.NODE_ENV !== 'production') { // TODO: `false &&` is only for dev purposes!
        localStorage.clear();
      }
    };
  }, []);

  let msalErrorDescription =
    window.sessionStorage.getItem("msal.error.description") || "";

  if (
    msalErrorDescription.indexOf(BRAINSAFE_AUTH_POLICIES.passwordResetUrlCode) >
    -1
  ) {
    return (
      <AzureAD provider={passwordResetAuthProvider} forceLogin={true}>
        <ContentComponent />
      </AzureAD>
    );
  } 
  else if (msalErrorDescription.indexOf(BRAINSAFE_AUTH_POLICIES.cancelPasswordResetCode) > -1)
  {
    reload();
    return (
      <AzureAD provider={signInAuthProvider} forceLogin={true}>        
        <ContentComponent />
      </AzureAD>
    );
  }
  else {
    return (
      <AzureAD provider={signInAuthProvider} forceLogin={true}>
        {getCoachDetails()}
        <ContentComponent />
      </AzureAD>
    );
  }
};

export default App;