import React, { Component } from "react";
import AzureAD from "react-aad-msal";
import { passwordResetAuthProvider } from "../../utils/services/auth/auth-provider";

export default class PasswordReset extends Component {
  constructor(props: any) {
    super(props);
    sessionStorage.clear();
  }
  render() {
    return (
      <AzureAD provider={passwordResetAuthProvider} forceLogin={true}>
        <div>{null}</div>
      </AzureAD>
    );
  }
}
