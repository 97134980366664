import { Reducer } from "redux";
import {
  InviteAthleteState,
  InviteAthleteTypes,
  BulkEmailCheckAthleteState,
} from "../types/invite-athlete";

export const initialInviteAthleteState: InviteAthleteState = {
  athleteData: [
    {
      firstName: "",
      lastName: "",
      email: "",
    },
  ],
  successMessage: 0,
  email: "",
  emailValidMessage: "",
  isAvailable: false,
  getAthleteByEmailError: false,
  getAthleteByEmailLoading: false,
  existingAthleteDetails: null,
};

export const initialBulkEmailAthleteState: BulkEmailCheckAthleteState = {
  emailList: [
    {
      email: "",
    },
  ],
  emailListValidityResponse: [],
};

const inviteAthleteReducer: Reducer<InviteAthleteState> = (
  state = initialInviteAthleteState,
  action
) => {
  switch (action.type) {
    case InviteAthleteTypes.INVITEATHLETE_REQUEST:
      return { ...state, athleteData: action.payload };
    case InviteAthleteTypes.INVITEATHLETE_SUCCESS:
      return { ...state, successMessage: action.payload };
    case InviteAthleteTypes.INVITEATHLETE_ERROR:
      return { ...state, successMessage: action.payload };
    case InviteAthleteTypes.CHECKEMAIL_REQUEST:
      return { ...state, email: action.payload };
    case InviteAthleteTypes.CHECKEMAIL_MESSAGE:
      return { ...state, emailValidMessage: action.payload };
    case InviteAthleteTypes.GET_ATHLETE_BY_EMAIL:
      return {
        ...state,
        getAthleteByEmailLoading: true,
        existingAthleteDetails: null,
        isAvailable: false,
      };
    case InviteAthleteTypes.GET_ATHLETE_BY_EMAIL_SUCCESS:
      return {
        ...state,
        isAvailable: action.payload.isExisting,
        existingAthleteDetails: action.payload,
        getAthleteByEmailLoading: false,
      };
    case InviteAthleteTypes.GET_ATHLETE_BY_EMAIL_ERROR:
      return {
        ...state,
        getAthleteByEmailLoading: false,
        existingAthleteDetails: null,
        getAthleteByEmailError: true,
      };
    default:
      return { ...state };
  }
};

const bulkEmailCheckReducer: Reducer<BulkEmailCheckAthleteState> = (
  state = initialBulkEmailAthleteState,
  action
) => {
  switch (action.type) {
    case InviteAthleteTypes.CHECKEMAIL_BULKREQUEST:
      return { ...state, emailList: action.payload };
    case InviteAthleteTypes.CHECKEMAIL_BULKMESSAGE:
      return { ...state, emailListValidityResponse: action.payload };

    default:
      return { ...state };
  }
};

export { inviteAthleteReducer };
export { bulkEmailCheckReducer };
