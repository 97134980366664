export interface CoachDetails {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  sport: string;
  team: string;
  club: string[];
  locale: string;
  clubSportTeamIds?: number[];
  teamSelection?: number;
  positionLabel?: string;
}
export interface CoachUpdateDetails {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  sport: string;
  team: string;
  club: string[];
  isActivated: boolean;
  clubSportTeamId: string;
}
export interface CoachState {
  coachData: CoachDetails;
}
export interface CoachUpdateState {
  coachUpdateData: CoachUpdateDetails;
  updateMessage: string;
}
export enum CoachTypes {
  COACH_REQUEST = "@@coach/COACH_REQUEST",
  COACH_SUCCESS = "@@coach/COACH_SUCCESS",
  COACH_ERROR = "@@coach/COACH_ERROR",
  COACH_NONEXISTENT = "@@coach/COACH_NONEXISTENT",

  COACH_UPDATE = "@@coach/COACH_UPDATE",
  COACH_UPDATE_SUCCESS = "@@coach/COACH_UPDATE_SUCCESS",
  COACH_UPDATE_ERROR = "@@coach/COACH_UPDATE_ERROR",

  COACH_SET_TEAM = "@@coach/COACH_SET_TEAM",
}
