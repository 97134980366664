export interface AthleteDetails {
  athleteId: number;
  firstName: string;
  lastName: string;
  email: string;
  position: string;
  nextStep: string;
  healthStatus: string;
  daysInjured: number;
  baselineTreadmillDate: Date;
  baselineCognitiveDate: Date;
  returnToPlay: string;
  picture: string;
  currentStatusId: number;
  positionLabel: string;
}

export interface AthleteListState {
  athleteListData: Array<AthleteDetails>;
  athleteListLoading: boolean;
}

export enum AthleteListTypes {
  ATHLETELIST_REQUEST = "@@athleteList/ATHLETE_LIST_REQUEST",
  ATHLETELIST_SUCCESS = "@@athleteList/ATHLETE_LIST_SUCCESS",
  ATHLETELIST_ERROR = "@@athleteList/ATHLETE_LIST_ERROR",
}
