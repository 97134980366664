import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import TableSorter from "../dashboard-table";
import CoachProfile from "../../pages/coach-profile/coach-profile";
import Athletes from "../../pages/athletes/athletes";
import Dashboard from "../../pages/dashboard/dashboard";
import Sideline from "../../pages/sideline/sideline";
import AthleteProfile from "../../pages/athlete-profile/athlete-profile";
import CoachNonexistent from "../../pages/coach-nonexistent/coach-nonexistent";
import SideBarComponent from "./sidebar";
import HeaderComponent from "./header";
import { Layout } from "antd";
import { signInAuthProvider } from "../../utils/services/auth/auth-provider";
import IdleTimer from "react-idle-timer";
import { IdleTimeOutModal } from "./idleTimeOutModal";
import { UserRole } from "../../utils/enums/enums";
import jwt_decode from "jwt-decode";
import ScrollToTop from "../../utils/ScrollToTop";

import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { CoachDetails } from "../../store/types/coach-profile";
import {
  requestCoachPersonalData,
  setSelectedTeam,
} from "../../store/actions/coach-profile";

const TIMEOUT_ON = process.env.REACT_APP_SESSION_TIMEOUT_MINUTES!;
class ContentComponent extends Component<any, any> {
  private idleTimer: any = null;
  constructor(props: any) {
    super(props);

    var inactiveMinutesAllowed = parseInt(TIMEOUT_ON);
    if (!inactiveMinutesAllowed) {
      inactiveMinutesAllowed = 20;
    }
    var IdelAlertShowBeforeSeconds = 30;

    this.state = {
      OriginIdleTimeIterations:
        (60 * inactiveMinutesAllowed) / IdelAlertShowBeforeSeconds,
      idleTimeIterations:
        (60 * inactiveMinutesAllowed) / IdelAlertShowBeforeSeconds,
      timeout: 1000 * IdelAlertShowBeforeSeconds,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      hasRoleSet: false,
      idleTimer: null,
      userRole: "",
    };
  }

  componentDidMount() {
    const { requestCoachPersonalData } = this.props;

    var token = sessionStorage.getItem("msal.idtoken") || "";

    if (token !== null || token !== undefined || token !== "") {
      var userDetails: any = jwt_decode(token);

      requestCoachPersonalData();

      if (userDetails.extension_Role == UserRole.super_admin) {
        this.setState({ userRole: UserRole.super_admin });
      } else {
        this.setState({ userRole: UserRole.admin });
      }
      this.setState({ hasRoleSet: true });
    }
  }

  componentWillReceiveProps(nextProps: any) {
    const { setSelectedTeam, coachData } = nextProps;
    const localTeamSelection = localStorage.getItem("teamSelection");

    if (coachData && coachData.firstName !== this.props.coachData.firstName) {
      const { clubSportTeamIds }: CoachDetails = coachData;

      if (clubSportTeamIds) {
        // cst list has been retrieved
        if (localTeamSelection === null) {
          // teamSelection has not been stored ealier
          // therefore, store teamSelection at the 0th index of csst list
          localStorage.setItem("teamSelection", clubSportTeamIds[0].toString());
          setSelectedTeam(clubSportTeamIds[0]);
        } else {
          // a team selection present already
          // check if team selection is in cst list of coach i.e. if coach can access that team
          if (
            clubSportTeamIds.indexOf(parseInt(localTeamSelection as string)) >
            -1
          ) {
            // coach has access
            setSelectedTeam(parseInt(localTeamSelection as string));
          } else {
            // coach doesn't have access. Set default teamSelection (0th index)
            localStorage.setItem(
              "teamSelection",
              clubSportTeamIds[0].toString()
            );
            setSelectedTeam(clubSportTeamIds[0]);
          }
        }
      } else {
        // cst team not yet received
      }
    }
  }

  _onAction = () => {
    this.setState({
      isTimedOut: false,
      idleTimeIterations: this.state.OriginIdleTimeIterations,
    });
  };

  _onActive = () => {
    this.setState({
      isTimedOut: false,
      idleTimeIterations: this.state.OriginIdleTimeIterations,
    });
  };
  _onIdle = () => {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      signInAuthProvider.logout();
    } else {
      let reducedIterations = this.state.idleTimeIterations - 1;
      this.setState({ idleTimeIterations: reducedIterations });
      this.idleTimer.reset();
      if (reducedIterations <= 1) {
        this.setState({ showModal: true });
        this.setState({ isTimedOut: true });
      }
    }
  };
  handleClose = () => {
    this.setState({ showModal: false });
  };
  signOut = () => {
    signInAuthProvider.logout();
  };
  render() {
    const auth = signInAuthProvider.authenticationState;
    const { coachData: CoachData } = this.props;

    if (typeof CoachData === "undefined") {
      return <CoachNonexistent />;
    }
    // wait until CoachData have been loaded, otherwise flickering might happen
    if (CoachData.id === "" || typeof CoachData.teamSelection === "undefined") {
      // don't load application till teamSelection is available on the data store
      return null;
    }

    return (
      <Layout>
        {auth == "Authenticated" && (
          <div>
            <IdleTimer
              ref={(ref) => {
                this.idleTimer = ref;
              }}
              element={document}
              onActive={this._onActive}
              onIdle={this._onIdle}
              onAction={this._onAction}
              debounce={250}
              timeout={this.state.timeout}
            />

            <IdleTimeOutModal
              showModal={this.state.showModal}
              handleClose={this.handleClose}
              handleLogout={this.signOut}
            />
          </div>
        )}
        <Layout>
          <SideBarComponent />
          <Layout>
            <HeaderComponent />
            <ScrollToTop />
            <Switch>
              <Route path="/TableSorter" component={TableSorter} />
              <Route exact path="/coach" component={CoachProfile} />
              <Route path="/athletes/:id" component={AthleteProfile} />
              <Route exact path="/athletes" component={Athletes} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/sideline" component={Sideline} />
              <Route exact path="/" component={Dashboard} />
            </Switch>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}
const mapDispatchToProps = {
  requestCoachPersonalData: requestCoachPersonalData,
  setSelectedTeam,
};
const mapStateToProps = ({ coachPersonalData }: ApplicationState) => ({
  coachData: coachPersonalData.coachData,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContentComponent));
