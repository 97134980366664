import React, { Component } from "react";
import { Row } from "antd";
import { DashboardWidgets } from "../../utils/enums/enums";

//css
import "./dashboardWidget.less";

import CurrentConcussionsComponent from "../current-concussion";
import AthletesRiskComponent from "../athletes-risk";
import BaseLineTestComponent from "../baseline-test";

class Widget extends Component<any, any> {
  filterElement() {
    const { widgetData, isViewClicked, type } = this.props;
    const { numberOfConcussions, athletesInRisk, baselineTest } = widgetData;

    switch (type) {
      case DashboardWidgets.noc:
        return (
          <CurrentConcussionsComponent
            numberOfConcussions={numberOfConcussions}
          />
        );

      case DashboardWidgets.air:
        return (
          <AthletesRiskComponent
            viewClicked={isViewClicked}
            athletesInRisk={athletesInRisk}
          />
        );
      case DashboardWidgets.bt:
        return <BaseLineTestComponent baselineTest={baselineTest} />;
    }
  }

  render() {
    return <Row gutter={0}>{this.filterElement()}</Row>;
  }
}
export default Widget;
