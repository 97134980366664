import { Reducer } from "redux";
import {
  ConcussionTestDetailsState,
  ConcussionTestDetailsTypes
} from "../types/concussion-test-details";

export const initialState: ConcussionTestDetailsState = {
  concussionTestDetailsData: [
    {
      stepNumber: "",
      stepNames: {},
      stepDescriptions: {},
    }
  ]
};

const reducer: Reducer<ConcussionTestDetailsState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ConcussionTestDetailsTypes.CONCUSSION_TEST_DETAILS_REQUEST:
      return { ...state };
    case ConcussionTestDetailsTypes.CONCUSSION_TEST_DETAILS_SUCCESS:
      return { ...state, concussionTestDetailsData: action.payload };
    case ConcussionTestDetailsTypes.CONCUSSION_TEST_DETAILS_ERROR:
      return { ...state, concussionTestDetailsData: [] };
    default:
      return state;
  }
};

export { reducer as concussionTestDetailsReducer };
