import { call, all, put, fork, takeLatest } from "redux-saga/effects";
import { createBrowserHistory } from "history";

import {
  requestSportPositionsSuccess,
  requestSportPositionsData,
  requestSportPositionsError
} from "../actions/sport-positions";
import { apiCaller } from "../../utils/apicaller";
import { SportPositionsTypes } from "../types/sport-positions";
import { AxiosResponse } from "axios";

export const history = createBrowserHistory();

const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;

function* fetchSportPositionsAsync(
  action: ReturnType<typeof requestSportPositionsData>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const response: AxiosResponse = yield call(
      apiCaller,
      "get",
      API_ENDPOINT,
      `Coach/${action.payload}/sportPositions`
    );

    if (response.data.error || response.status === 500) {
      yield put(requestSportPositionsError());
    } else {
      yield put(requestSportPositionsSuccess(response.data));
    }
  } catch (err) {
    yield put(requestSportPositionsError());
  }
}

//this is watcherfunction we use takeLatest to watch redux actions which should go through saga

function* watchFetchSportPositions() {
  yield takeLatest(
    SportPositionsTypes.SPORT_POSITIONS_REQUEST,
    fetchSportPositionsAsync
  );
}
// We can also use `fork()` here to split our saga into multiple watchers.

function* sportPositionsSaga() {
  yield all([fork(watchFetchSportPositions)]);
}

export default sportPositionsSaga;
