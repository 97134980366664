
export enum DashboardWidgets {
  noc = "1",
  air = "2",
  bt = "3",
}

export enum HealthStatus {
  healthy = "Healthy",
  pending = "Pending",
}

export enum RequestStatus {
  pending = "Pending",
  done = "Done",
}

export enum UserRole {
  admin = "admin",
  super_admin = "super_admin",
}

export enum FilterMenu {
  availability = "availability",
  health_status = "health_status",
  position = "position",
  baseline_test = "baseline_test",
}

export enum AvailabilityMenu {
  active = "active",
  inactive = "inactive",
}

export enum HealthStatusMenu {
  healthy = "healthy",
  concussion = "concussion",
  pending = "pending",
}

export enum BaselineTestMenu {
  complete = "complete",
  incomplete = "incomplete",
}
