import { Reducer } from 'redux'
import { ConcussionTableState, CurrentConcussionTableTypes } from '../types/concussion-table';

//typesafe initialstate
export const initialState: ConcussionTableState = {
    concussionTableResultData: []
}

const reducer: Reducer<ConcussionTableState> = (state = initialState, action) => {

    switch (action.type) {
        case (CurrentConcussionTableTypes.CONCUSSIONTABLE_REQUEST):
            return {
                ...state
            };
        case (CurrentConcussionTableTypes.CONCUSSIONTABLE_SUCCESS):
            return {
                ...state,
                concussionTableResultData: action.payload
            };
        case (CurrentConcussionTableTypes.CONCUSSIONTABLE_ERROR):
            return {
                ...state,
                concussionTableResultData: []
            };
        default:
            return state;
    }
}

export { reducer as tableReducer }

