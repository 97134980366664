import React, { Component } from "react";
import { Layout, Row, Typography, Select, Button, Result } from "antd";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import SidelineForm from "../../components/sideline/sideline-form"
import {
  requestSidelineTestInformation,
  requestSubmitSidelineTest,
  requestSubmitSidelineTestSuccess,
} from "../../store/actions/sideline-test";
import { requestCoachPersonalData } from "../../store/actions/coach-profile";
import {
  responsePermission,
} from "../../store/actions/notification";
import { CoachDetails } from "../../store/types/coach-profile";
import { SidelineTest, SidelineTestInformation } from "../../store/types/sideline-test";
import Spinner from "../../components/spinner/spinner";
const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

interface PropsFromDispatch {
  requestSidelineTestInformation: typeof requestSidelineTestInformation;
  requestSubmitSidelineTest: typeof requestSubmitSidelineTest;
  responsePermission: typeof responsePermission;
  requestCoachPersonalData: typeof requestCoachPersonalData;
  requestSubmitSidelineTestSuccess: typeof requestSubmitSidelineTestSuccess;
}

interface PropsFromState {
  coachData: CoachDetails;
  sidelineTest: SidelineTest;
  sidelineTestInformation: SidelineTestInformation;
  result: number,
  responseNotificationMessage: string;
  sidelineTestLoading: boolean;
}

type AllProps = PropsFromState & PropsFromDispatch;
class Sideline extends Component<any, any> {
  stepOneRef: React.RefObject<InstanceType<typeof SidelineForm>>;
  stepTwoRef: React.RefObject<InstanceType<typeof SidelineForm>>;
  constructor(props: any) {
    super(props);
    this.stepOneRef = React.createRef<InstanceType<typeof SidelineForm>>();
    this.stepTwoRef = React.createRef<InstanceType<typeof SidelineForm>>();

    this.state = {
      currentAthlete: undefined,
      currentStep: 1,
      maxSteps: 2,
      overview: "",
      stepOneDescription: "",
      stepTwoDescription: "",
      questionList: [],
      symptomList: [],
      result: 0,
      isRequestApproved: false,
      buttonOneDisabled: true,
      buttonTwoDisabled: true,
      showPositiveResult: false,
      showNegativeResult: false,
      responseSent: false,
    };
  }

  componentDidMount() {

    const { coachData, requestCoachPersonalData, requestSidelineTestInformation, requestSubmitSidelineTestSuccess } = this.props;
    if (coachData.id === "") {
      requestCoachPersonalData();
    } else {
      let coachId = parseInt(coachData.id);
      requestSidelineTestInformation(coachData.teamSelection)
    }
    if (this.props.result !== 0) {
      requestSubmitSidelineTestSuccess(0)
    }
  }

  componentDidUpdate(prevProps: any) {
    const { t, coachData } = this.props;
    if (this.props.sidelineTestInformation !== prevProps.sidelineTestInformation) {
      const { i18n } = this.props;
      this.handleLanguageChange(i18n.language)
    }
    if (this.props.result !== prevProps.result) {
      this.setState({
        result: this.props.result,
      })
    }
    if (this.props.responseNotificationMessage !== prevProps.responseNotificationMessage) {
      if (this.props.responseNotificationMessage === 200) {
        if (this.state.isRequestApproved) {
          this.setState({
            showPositiveResult: true,
          });
        } else {
          this.setState({
            showNegativeResult: true,
          });
        }
        this.setState({
          isRequestApproved: false,
        });
      }
    }
    if (coachData !== prevProps.coachData) {
      if (coachData) {
        this.props.requestSidelineTestInformation(coachData.teamSelection)
        this.setState({
          currentStep: 1,
          buttonOneDisabled: true,
          buttonTwoDisabled: true
        })
      }
    }
  }

  setAthlete(value: any) {
    const { sidelineTestInformation } = this.props;
    var currentAthlete = {};
    sidelineTestInformation.athleteList.forEach((athlete: any) => {
      if (athlete.id === value) {
        currentAthlete = athlete;
      }
    });
    this.setState(
      { currentAthlete },
      () => this.isButtonOneDisabled(this.stepOneRef.current?.state.questionList)
    );
  }

  submitTest() {
    this.setState({
      questionList: this.stepTwoRef.current?.state.questionList
    })
    var sidelineTest: SidelineTest = {
      symptomList: this.state.symptomList,
      questionList: this.state.questionList
    };
    this.props.requestSubmitSidelineTest(this.state.currentAthlete.id, sidelineTest);
  }

  goToStepTwo() {
    this.setState({
      currentStep: 2,
      symptomList: this.stepOneRef.current?.state.questionList
    })
  }

  checkUnanswered(questionList: any) {
    if (questionList) {
      for (var question of questionList) {
        if (!question.hasOwnProperty('SidelineQuestionId') && !question.hasOwnProperty('SidelineSymptomId')) {
          return true;
        }
      }
      return false;
    }
  }

  isButtonOneDisabled(questionList: any) {
    if (questionList) {
      var hasAthlete = this.state.currentAthlete !== undefined
      this.setState({
        buttonOneDisabled: !hasAthlete || this.checkUnanswered(questionList)
      })
    }
  }

  isButtonTwoDisabled(questionList: any) {
    this.setState({
      buttonTwoDisabled: this.checkUnanswered(questionList)
    })
  }

  LoadSidelineTestData = () => {
    const { requestSidelineTestInformation, coachData } = this.props;
    requestSidelineTestInformation(coachData.teamSelection);
    if (this.stepOneRef.current) {
      this.setState(
        () => {
          this.isButtonOneDisabled(this.state.questionList)
        })
    }
    else {
      this.setState({
        currentStep: 1
      },
        () => {
          this.isButtonOneDisabled(this.state.questionList)
          this.isButtonTwoDisabled(this.state.questionList)
        })
    }


  };

  handleLanguageChange(language: any) {
    const { sidelineTestInformation } = this.props;
    var questionList: { id: any; question: any; }[] = [];
    var symptomList: { id: any; question: any; }[] = [];
    switch (language) {
      case 'en-US':
        sidelineTestInformation.questionList.map((item: any, index: any) => (
          questionList.push({ id: item.id, question: item.localizedDescription })
        ))
        sidelineTestInformation.symptomList.map((item: any, index: any) => (
          symptomList.push({ id: item.id, question: item.localizedDescription })
        ))
        this.setState({
          overview: sidelineTestInformation.overviewEn,
          stepOneDescription: sidelineTestInformation.stepOneDescriptionEn,
          stepTwoDescription: sidelineTestInformation.stepTwoDescriptionEn,
          questionList: questionList,
          symptomList: symptomList
        })
        break;
      case 'sv-SE':
        sidelineTestInformation.questionList.map((item: any, index: any) => (
          questionList.push({ id: item.id, question: item.localizedDescription })
        ))
        sidelineTestInformation.symptomList.map((item: any, index: any) => (
          symptomList.push({ id: item.id, question: item.localizedDescription })
        ))
        this.setState({
          overview: sidelineTestInformation.overviewSv,
          stepOneDescription: sidelineTestInformation.stepOneDescriptionSv,
          stepTwoDescription: sidelineTestInformation.stepTwoDescriptionSv,
          questionList: questionList,
          symptomList: symptomList
        })
        break;
      case 'da-DK':
        sidelineTestInformation.questionList.map((item: any, index: any) => (
          questionList.push({ id: item.id, question: item.localizedDescription })
        ))
        sidelineTestInformation.symptomList.map((item: any, index: any) => (
          symptomList.push({ id: item.id, question: item.localizedDescription })
        ))
        this.setState({
          overview: sidelineTestInformation.overviewDa,
          stepOneDescription: sidelineTestInformation.stepOneDescriptionDa,
          stepTwoDescription: sidelineTestInformation.stepTwoDescriptionDa,
          questionList: questionList,
          symptomList: symptomList
        })
        break;
      case 'nb-NO':
        sidelineTestInformation.questionList.map((item: any, index: any) => (
          questionList.push({ id: item.id, question: item.localizedDescription })
        ))
        sidelineTestInformation.symptomList.map((item: any, index: any) => (
          symptomList.push({ id: item.id, question: item.localizedDescription })
        ))
        this.setState({
          overview: sidelineTestInformation.overviewNb,
          stepOneDescription: sidelineTestInformation.stepOneDescriptionNb,
          stepTwoDescription: sidelineTestInformation.stepTwoDescriptionNb,
          questionList: questionList,
          symptomList: symptomList
        })
        break;
    }
  }

  sendPermission = (permission: boolean, athleteID: string) => {
    this.setState({
      isRequestApproved: permission,
      responseSent: true
    });
    const { responsePermission } = this.props;
    var responseObject = {
      athleteId: athleteID,
      permission: permission,
    };
    responsePermission(responseObject);
  };

  goToDashboard(path: any) {
    this.props.history.push('/dashboard');
  }

  renderContent() {
    const { currentStep, maxSteps, currentAthlete, overview, stepOneDescription, stepTwoDescription, questionList, symptomList, result, buttonOneDisabled, buttonTwoDisabled, showPositiveResult, showNegativeResult } = this.state;
    const { t, sidelineTestInformation } = this.props;
    if (result === 0) {
      if (currentStep === 1) {
        return <div>
          <Row>
            <Select
              placeholder={t("Select player")}
              value={currentAthlete?.id || undefined}
              onChange={this.setAthlete.bind(this)}
              style={{ width: 200 }}
            >
              {sidelineTestInformation.athleteList.map((athlete: any) => {
                return (
                  <Option key={athlete.id} value={athlete.id}>
                    {athlete.firstName}{" "}{athlete.lastName}
                  </Option>
                );
              })}
            </Select>
            {/* <br />
            <br />
            <Text>{overview}</Text> */}
          </Row>
          <br />
          <br />
          <Row>
            <Text className="bold-text">{t("Step")} {currentStep} {t("of")} {maxSteps}</Text>
            <SidelineForm ref={this.stepOneRef} title={t("Symptom")} isSymptoms={true} currentAthlete={currentAthlete} description={stepOneDescription} buttonOne={t("Yes")} buttonTwo={t("No")} questionList={symptomList} disabledChecker={this.isButtonOneDisabled.bind(this)} />
          </Row>
          <Row className="buttons-row">
            <Button type="primary" onClick={this.goToStepTwo.bind(this)} disabled={buttonOneDisabled}>{t("Next")}</Button>
          </Row>
        </div>
      }
      else {
        return <div>
          <Row>
            <Text className="bold-text">{currentAthlete.firstName}{" "}{currentAthlete.lastName}</Text>
          </Row>
          <Row>
            <Text>{t("Step")} {currentStep} {t("of")} {maxSteps}</Text>
            <SidelineForm ref={this.stepTwoRef} title={t("Memory Test")} isSymptoms={false} currentAthlete={currentAthlete} buttonOne={t("Correct")} buttonTwo={t("Incorrect")} questionList={questionList} disabledChecker={this.isButtonTwoDisabled.bind(this)} />
          </Row>
          <Row className="buttons-row">
            <Button onClick={() => this.setState({ currentStep: 1 })}>{t("Back")}</Button>
            <Button type="primary" onClick={this.submitTest.bind(this)} disabled={buttonTwoDisabled}>{t("Submit")}</Button>
          </Row>
        </div>
      }
    } else {
      if (result === 2) {
        return <Row>
          <Text>
            {t("Sideline description one part one")} {" "} {currentAthlete.firstName}{" "}{currentAthlete.lastName} {" "} {t("Sideline description one part two")}
          </Text>
        </Row>
      } else if (result === 3) {
        return <Row>
          <Text>
            {t("Sideline description two part one")} {" "} {currentAthlete.firstName}{" "}{currentAthlete.lastName} {" "} {t("Sideline description two part two")}
          </Text>
        </Row>
      }
    }
  }

  render() {
    const { t, sidelineTestInformation, i18n, sidelineTestLoading } = this.props;
    i18n.on('languageChanged', (lng: any) => {
      this.LoadSidelineTestData()
      this.handleLanguageChange(lng)
    })
    if (sidelineTestLoading) {
      return (
        <Layout>
          <Content className="main-content">
            <Spinner />
          </Content>
        </Layout>
      );
    } else {
      if (sidelineTestInformation.athleteList !== undefined) {
        return (
          <Layout>
            <Content className="main-content">
              <Row>
                <Title level={1}>{t("layout:Sidelinetest")}</Title>
              </Row>
              {this.renderContent()}
            </Content>
          </Layout>
        );
      } else {
        return (
          <Layout>
            <Content className="main-content">
              <Spinner />
            </Content>
          </Layout>
        );
      }
    }
  }
}
const mapDispatchToProps = {
  requestSidelineTestInformation: requestSidelineTestInformation,
  requestSubmitSidelineTest: requestSubmitSidelineTest,
  responsePermission: responsePermission,
  requestCoachPersonalData: requestCoachPersonalData,
  requestSubmitSidelineTestSuccess: requestSubmitSidelineTestSuccess
};

const mapStateToProps = ({
  coachPersonalData,
  sidelineTestData,
  athleteResponseConcussionTestListDetails: AthleteResponseConcussionTestListDetails,
}: ApplicationState) => ({
  coachData: coachPersonalData.coachData,
  sidelineTest: sidelineTestData.sidelineTest,
  sidelineTestInformation: sidelineTestData.sidelineTestInformation,
  sidelineTestLoading: sidelineTestData.sidelineTestLoading,
  result: sidelineTestData.result,
  responseNotificationMessage:
    AthleteResponseConcussionTestListDetails.permissionReplyMessage,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(['sideline-test', "layout"])(Sideline));
