import { ApplicationState,createRootReducer,rootSaga } from "./store";
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'

import {composeWithDevTools } from "redux-devtools-extension"
import { Store, applyMiddleware,createStore } from "redux";
import { History } from 'history'


export default function configureStore(history:History,initialState:ApplicationState):Store<ApplicationState>{
    //creating composing function function for our middlewares
    const composeEnhancers=composeWithDevTools({})

    //creating redux saga middleware
    const sagaMiddleware =createSagaMiddleware()

    const store=createStore(
        createRootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(routerMiddleware(history),sagaMiddleware)))

    sagaMiddleware.run(rootSaga)
    return store
}