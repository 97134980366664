import React, { Component } from "react";
import { Layout, Menu, Typography } from "antd";
import { Link, withRouter } from "react-router-dom";
import collapsedIconSVG from "../../assets/images/final-logo-icon1.svg";
import expandedIconSVG from "../../assets/images/final-logo-blue.svg";
import { withTranslation } from "react-i18next";
import Icons from "./icons";

const { Sider } = Layout;
const { Title } = Typography;
const collapsedIcon = (
  <div>
    <img src={collapsedIconSVG} width={"45px"} />
  </div>
);
const expandedIcon = (
  <div>
    <img src={expandedIconSVG} width={""} />
  </div>
);
class SideBarComponent extends Component<any> {
  state = {
    collapsed: false,
  };
  onCollapse = (collapsed: any) => {
    this.setState({ collapsed });
  };

  // onclick for mobile menu
  handleClick = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };


  render() {
    const { location, t } = this.props;
    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        className="main-sider"
        width={240}
        breakpoint="lg"
        collapsedWidth="0"
      >
        <Title className="logo-name" level={3}>
          {this.state.collapsed ? collapsedIcon : expandedIcon}
        </Title>
        {/*// desktop menu*/}
        <Menu
          defaultSelectedKeys={["1"]}
          mode="inline"
          theme="dark"
          selectedKeys={[location.pathname]}
          className="desktop-menu"
        >
          <Menu.Item key="/dashboard">
            <Icons.IconDashboard />
            <span>{t("Dashboard")}</span>
            <Link id="sidebar-desktop-dashboard" to="/dashboard" />
          </Menu.Item>

          <Menu.Item key="/athletes">
            <Icons.IconAthletes />
            <span>{t("Athletes")}</span>
            <Link id="sidebar-desktop-athletes" to="/athletes" />
          </Menu.Item>

{/*           <Menu.Item key="/inbox">
            <Icons.IconInbox />
            <span>{t("Inbox")}</span>
            <Link to="/inbox" />
          </Menu.Item> */}

          <Menu.Item key="/sideline">
             <Icons.IconAward />
            <span>{t("Sidelinetest")}</span>
            <Link id="sidebar-desktop-sideline" to="/sideline" />
          </Menu.Item>
          
        </Menu>


          {/*mobile menu bellow*/}
        <Menu
            defaultSelectedKeys={["1"]}
            mode="inline"
            theme="dark"
            selectedKeys={[location.pathname]}
            onClick={this.handleClick}
            className="mobile-menu"
        >
          <Menu.Item key="/dashboard">
            <Icons.IconDashboard />
            <span>{t("Dashboard")}</span>
            <Link id="sidebar-mobile-dashboard" to="/dashboard" />
          </Menu.Item>

          <Menu.Item key="/athletes">
            <Icons.IconAthletes />
            <span>{t("Athletes")}</span>
            <Link id="sidebar-mobile-athletes" to="/athletes"  />
          </Menu.Item>
          <Menu.Item key="/sideline">
            <Icons.IconAward />
            <span>{t("Sidelinetest")}</span>
            <Link id="sidebar-mobile-sideline" to="/sideline" />
          </Menu.Item>
        </Menu>
        {/*end mobile menu*/}

      </Sider>
    );
  }
}

export default withTranslation('layout')(withRouter(SideBarComponent));
