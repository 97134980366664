import { AxiosResponse } from "axios";
import { apiCaller } from "../apicaller";
const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;

export const getAthleteMultipleTeams = async (
  athleteId: number
): Promise<AxiosResponse<any>> => {
  return await apiCaller(
    "get",
    API_ENDPOINT,
    `Coach/GetAthleteMultipleTeams?athleteId=${athleteId}`
  );
};

export const deleteAthlete = async (
  athleteId: number,
  clubSportTeamId: number
): Promise<AxiosResponse<any>> => {
  return await apiCaller(
    "get",
    API_ENDPOINT,
    `Coach/RemoveAthleteInTeam?athleteId=${athleteId}&clubSportTeamId=${clubSportTeamId}`
  );
};
