import _ from "lodash";
import { AthleteDetails } from "../../store/types/athlete-list";

export const SortAthleteList = (
  athleteList: Array<AthleteDetails>,
  sortOrder: string
) => {
  var tempAthleteArray = athleteList;
  if (sortOrder === "asc") {
    tempAthleteArray = _.orderBy(
      athleteList,
      [athlete => athlete.firstName.toLowerCase()],
      ["asc"]
    );
  } else if (sortOrder === "desc") {
    tempAthleteArray = _.orderBy(
      athleteList,
      [athlete => athlete.firstName.toLowerCase()],
      ["desc"]
    );
  }

  return tempAthleteArray;
};