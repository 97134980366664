//package imports
import React, { FC, useEffect, useState } from "react";
import { Card, Row, Col, Layout, Tag } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DOMPurify from "dompurify";

//CSS
import "./athlete-profile-card.less";

//local imports
import Icons from "../layout/icons";
import Spinner from "../spinner/spinner";
import { getAthleteMultipleTeams } from "../../utils/services/athleteTeams";
import Healthy from "../../assets/images/healthy.svg";

import { ApplicationState } from "../../store";
import { StepDetail } from "../../store/types/concussion-test-details";

interface AthleteTeam {
  teamName: string;
  countryName: null | string;
  countryCode: null | string;
  sportId: number;
  sportName: null | string;
  clubId: number;
  clubName: string;
  coachId: number;
  coachFirstName: null | string;
  coachLastName: null | string;
  coachEmail: null | string;
  exisitingCoachIds: null | number;
  mobileNumber: null | string;
  clubSportTeamId: number;
  id: number;
  createdOn: Date;
  isDeleted: boolean;
}

interface AthleteProfileCardProps {
  id: number;
  firstName: string;
  lastName: string;
  position: string;
  nextStep: string;
  healthStatus: string;
  daysInjured: number;
  //   baselineTreadmillDate: Date;
  baselineCognitiveDate: Date;
  //   returnToPlay: string;
  dateOfConcussion: Date;
  picture: string;
  positionLabel: string;
}
const AthleteProfileCard: FC<AthleteProfileCardProps> = ({
  id,
  dateOfConcussion,
  firstName,
  lastName,
  healthStatus,
  picture,
  positionLabel,
  baselineCognitiveDate,
  position,
  daysInjured,
  nextStep,
}) => {
  const { t, i18n } = useTranslation(["athlete-profile", "athletes"]);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [steps, setSteps] = useState<StepDetail[]>([]);
  const [athleteTeams, setAthleteTeams] = useState<AthleteTeam[]>([]);

  //consts
  const Content = Layout.Content;

  const concussionTestData = useSelector(
    ({ concussionTestDetails }: ApplicationState) =>
      concussionTestDetails.concussionTestDetailsData
  );

  useEffect(() => {
    i18n.on("languageChanged", (lng: string) => {
      changeStepsLanguage(lng as keyof object);
    });
  }, []);

  useEffect(() => {
    changeStepsLanguage(i18n.language as keyof object);
  }, [concussionTestData]);

  useEffect(() => {
    if (id > 0) {
      getAthleteMultipleTeams(id)
        .then(({ status, data }) => {
          if (status == 200 && data) {
            setAthleteTeams(data);
          }
        })
        .catch((e) => {
          setAthleteTeams([]);
          console.log(e, "error");
        })
        .finally(() => setIsLoaded(true));
    } else {
      setIsLoaded(false);
    }
  }, [id]);

  const changeStepsLanguage = (lng: keyof object) => {
    if (concussionTestData) {
      var steps = [];
      for (let step of concussionTestData) {
        var currentStep: StepDetail = {
          stepNumber: step.stepNumber,
          stepName: step.stepNames[lng],
          stepDescription: step.stepDescriptions[lng],
        };
        steps.push(currentStep);
      }
      setSteps(steps);
    }
  };

  const getClubTeamNames = (teams: Array<AthleteTeam>): Array<string> => {
    const sortedList = teams.reduce((allTeams: Array<Array<string>>, athleteTeam: AthleteTeam) => {
      let indexOfClub = allTeams.findIndex(team => team[0] === athleteTeam.clubName)
      if (!indexOfClub) {
        allTeams[indexOfClub].push(athleteTeam.teamName);
      } else {
        allTeams.push([athleteTeam.clubName, athleteTeam.teamName]);
      }
      return allTeams;
    }, [[teams[0].clubName]])

    return sortedList.map(clubTeams => `${clubTeams[0]} - ${clubTeams.slice(1).join(", ")}`);
  };

  if (isLoaded) {
    const injuredSubtitle = (
      <div>
        <p>
          <strong>{t("Date of concussion")}</strong>
          <br /> {moment(dateOfConcussion).format("L")}
        </p>
      </div>
    );

    return (
      <Content>
        <div className="main-div">
          <Row>
            <Col xs={24} sm={24} md={242} lg={24} xl={24}>
              <div className="athlete-profile-wrap">
                <div className="athlete-profile-avatar">
                  <img src={picture} alt="" className="avatar-large" />
                </div>
                <div className="athlete-profile-info">
                  <div className="athlete-name">
                    {firstName.charAt(0).toUpperCase() + firstName.slice(1)}{" "}
                    {lastName.charAt(0).toUpperCase() + lastName.slice(1)}
                  </div>
                  <div className="athlete-health">
                    {healthStatus === "100" ? (
                      <span className="healthy-tag-text">{t("Healthy")}</span>
                    ) : healthStatus !== null && healthStatus !== undefined ? (
                      <span className="return-to-play-text">
                        {t("athletes:Concussion")}
                      </span>
                    ) : (
                      <span className="pending-tag-text">{t("Pending")}</span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={15} lg={15} xl={15}>
              <div className="overview-title">{t("Overview")}</div>
              <Card
                title=""
                bordered={false}
                className="widget-card narrow-card"
              >
                <div className="details-section">
                  <div className="overview-box">
                    <div className="icon-box">
                      <div className="icon-col icon-gradient">
                        <Icons.GradientBg
                          style={{
                            width: "0",
                            height: "0",
                            position: "absolute",
                          }}
                        />
                        <Icons.IconPosition />
                      </div>
                      <div className="icon-info">
                        <div className="icon-info-label">{positionLabel}</div>
                        <div className="icon-info-label-l">
                          {position !== null && position !== undefined
                            ? position
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="icon-box">
                      <div className="icon-col icon-gradient">
                        <Icons.GradientBg
                          style={{
                            width: "0",
                            height: "0",
                            position: "absolute",
                          }}
                        />
                        <Icons.IconDamage />
                      </div>
                      <div className="icon-info">
                        <div className="icon-info-label">{t("Baseline")}</div>
                        <div className="icon-info-label-l">
                          {" "}
                          {baselineCognitiveDate !== null ? (
                            moment(baselineCognitiveDate).format("L")
                          ) : (
                            <span>{t("athletes:Not completed")}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="overview-box">
                    <div className="icon-box">
                      <div className="icon-col icon-gradient">
                        <Icons.GradientBg
                          style={{
                            width: "0",
                            height: "0",
                            position: "absolute",
                          }}
                        />
                        <Icons.IconFiles />
                      </div>
                      <div className="icon-info">
                        <div className="icon-info-label">
                          {t("Days injured")}
                        </div>
                        <div className="icon-info-label-l">
                          {" "}
                          {healthStatus === null
                            ? "N/A"
                            : [healthStatus === "100" ? "-" : daysInjured]}
                        </div>
                      </div>
                    </div>
                    <div className="icon-box">
                      <div className="icon-col icon-gradient">
                        <Icons.GradientBg
                          style={{
                            width: "0",
                            height: "0",
                            position: "absolute",
                          }}
                        />
                        <Icons.IconReset />
                      </div>
                      <div className="icon-info">
                        <div className="icon-info-label">
                          {t("Health status")} (%)
                        </div>
                        <div className="icon-info-label-l">
                          {healthStatus !== null && healthStatus !== undefined
                            ? healthStatus
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {healthStatus !== "100" ? (
                  <div className="step-section">
                    <div className="number">
                      <span>{nextStep ?? "-"}</span>
                    </div>
                    <div className="label">
                      <span>{t("athlete-profile:rehabilitation")}</span>
                    </div>
                  </div>
                ) : (
                  <div className="step-section" style={{ minWidth: "134px" }}>
                    <div className="number">
                      <img src={Healthy} />
                    </div>
                    <div className="label">
                      <span>{t("Healthy")}</span>
                    </div>
                  </div>
                )}
              </Card>
              <div className="overview-title">{t("Teams")}</div>
              <Card title="" bordered={false}>
                <div className="overview-box tag-container">
                  {getClubTeamNames(athleteTeams).map(clubTeams =>
                    <Tag color="#CBCFD9">{clubTeams}</Tag>)
                  }
                </div>
              </Card>
              <Card
                title=""
                bordered={false}
                className="widget-card"
                style={{
                  marginTop: 20,
                }}
                bodyStyle={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div className="overview-title">{t("Concussion History")}</div>

                <div
                  className="no-data"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div className="icon-gradient icon-large2">
                    <Icons.IconNodata />
                  </div>
                  {t("No History to be displayed")}
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <div className="recovery-title">{t("Recovery Process")}</div>

              <div className="recovery-box">
                {healthStatus !== "100" &&
                  healthStatus !== null &&
                  healthStatus !== undefined ? (
                  <div>
                    <div className="step-div step0">
                      <div className="step-header-wrap">
                        <div className="step-count">0</div>
                        <div className="step-title">{t("Damaged")}</div>
                      </div>
                      <div className="step-description">{injuredSubtitle}</div>
                    </div>
                    {steps.map((step) => (
                      <div
                        className={
                          nextStep === step.stepNumber
                            ? "step-div active"
                            : "step-div"
                        }
                      >
                        <div className="step-header-wrap">
                          <div className="step-count">{step.stepNumber}</div>
                          <div className="step-title">
                            {t("Step") + " "}
                            {step.stepNumber}
                            {" - "}
                            {step.stepName}
                          </div>
                        </div>
                        <div
                          className="step-description"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(step.stepDescription),
                          }}
                        ></div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <Card title="" className="no-data">
                      <div className="icon-gradient icon-large2">
                        <Icons.IconNodata />
                      </div>
                      {t("Athlete is not in recovery process")}
                    </Card>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Content>
    );
  } else {
    return <Spinner />;
  }
};

export default AthleteProfileCard;
