//package imports
import React, { FC, useState, useEffect } from "react";
import { Card, Row, Typography, Icon, Popover, message, Button } from "antd";
import jwt from "jwt-decode";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  removePendingAthlete,
  deleteAthleteRequest,
} from "../../store/actions/athlete-card";
import { requestAthleteData } from "../../store/actions/athlete-list";
import { ApplicationState } from "../../store";
import { UserRole } from "../../utils/enums/enums";

import ConfirmationModal from "../confirmation-modal/confirmation-modal";
import { deleteAthlete } from "../../utils/services/athleteTeams";

//css
import "./athlete-card.less";

type AthleteData = {
  athleteId: number;
  firstName: string;
  lastName: string;
  email: string;
  position: string;
  healthStatus: string;
  baselineCognitiveDate: Date;
  picture: string;
  positionLabel: string;
};

const AthleteCard: FC<AthleteData> = ({
  athleteId,
  firstName,
  lastName,
  email,
  position,
  healthStatus,
  baselineCognitiveDate,
  picture,
  positionLabel,
}) => {
  const [isPendingRemoved, setIsPendingRemoved] = useState(false);
  const [isHardRemove, setIsHardRemove] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userRole, setUserRole] = useState(UserRole.admin);
  const [type, setType] = useState("none");
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  const { t } = useTranslation(["athletes", "common"]);
  const dispatch = useDispatch();
  const { athleteRemovalStatus, athleteHardRemovalStatus } = useSelector(
    (state: ApplicationState) => state.athleteRemovalStatus
  );
  const {
    teamSelection,
    id: coachDataId,
    clubSportTeamIds,
  } = useSelector(
    (state: ApplicationState) => state.coachPersonalData.coachData
  );

  const success = message.success;
  const warning = message.warning;
  const error = message.error;
  const loading = message.loading;
  const Text = Typography.Text;

  useEffect(() => {
    const token = sessionStorage.getItem("msal.idtoken");
    if (token) {
      const user: any = jwt(token);
      if (user.extension_Role === UserRole.super_admin) {
        setUserRole(UserRole.super_admin);
      }
    }
  }, []);

  useEffect(() => {
    if (isPendingRemoved && teamSelection) {
      switch (athleteRemovalStatus) {
        case 200: {
          dispatch(requestAthleteData(teamSelection));
          success(
            t(
              healthStatus === null || healthStatus === undefined
                ? "Invitation removed"
                : "Athlete inactivated"
            )
          );

          break;
        }
        case 400: {
          warning(t("athleteNotFound"));
          dispatch(requestAthleteData(teamSelection));
          break;
        }
        default: {
          error(t("noInternet"));
          break;
        }
      }
      setIsPendingRemoved(false);
    }
  }, [athleteRemovalStatus]);

  useEffect(() => {
    if (athleteHardRemovalStatus === 200 && teamSelection) {
      if (isHardRemove) {
        dispatch(requestAthleteData(teamSelection));
        success(t("Athlete_Deleted"));
      }
      setIsHardRemove(false);
    }
  }, [athleteHardRemovalStatus]);

  const handleConfirmModalCancel = () => {
    setVisibleConfirmModal(false);
  };

  const handleRemovePendingAthlete = () => {
    if (teamSelection) {
      handleConfirmModalCancel();
      setVisible(false);
      const coachId = parseInt(coachDataId);
      setIsPendingRemoved(true);
      dispatch(removePendingAthlete(coachId, athleteId, email, teamSelection));
      loading(
        t(
          healthStatus === null || healthStatus === undefined
            ? "Removing invitation"
            : "Inactivating athlete"
        )
      );
    }
  };

  const deleteAthletes = () => {
    handleConfirmModalCancel();
    setVisible(false);
    setIsHardRemove(true);
    dispatch(deleteAthleteRequest(email));
    loading(t("Deleting_Athlete"));
  };

  const handleRemoveAthlete = () => {
    handleConfirmModalCancel();
    setVisible(false);

    const clubSportTeamId = clubSportTeamIds?.find(
      (clubSpId) => clubSpId == teamSelection
    );
    if (clubSportTeamId) {
      loading(t("Removing Athlete"));
      deleteAthlete(athleteId, clubSportTeamId)
        .then(({ status, data }) => {
          if (status === 200 && teamSelection) {
            dispatch(requestAthleteData(teamSelection));
            success(t("Athlete Removed"));
          }
        })
        .catch(() => {
          error(t("noInternet"));
        });
    }
  };

  const menu =
    healthStatus === null || healthStatus === undefined ? (
      <div
        className="popover-common"
        onClick={() => {
          setVisible(false);
          setType("cancel");
          setVisibleConfirmModal(true);
        }}>
        {t("Cancel")}
      </div>
    ) : (
      <>
        <div
          className="popover-common"
          onClick={() => {
            setVisible(false);
            setType("remove");
            setVisibleConfirmModal(true);
          }}>
          {t("Remove athlete")}
        </div>


        {userRole === UserRole.super_admin && (
          <div
            className="popover-delete"
            onClick={() => {
              setVisible(false);
              setType("delete");
              setVisibleConfirmModal(true);
            }}
          >
            {t("Delete_athlete")}
          </div>
        )}
      </>
    );

  const PlayerHealthStatus =
    healthStatus === null || healthStatus === undefined ? (
      <span className="pending-text">{t("Pending")}</span>
    ) : healthStatus === "100" ? (
      <span className="healthy-text">{t("Healthy")}</span>
    ) : (
      <span className="return-to-play-text">{t("Concussion")}</span>
    );

  return (
    <>
      <Card className="athlete-list-item">
        <Row className="athlete-list-item-wrap">
          <div className="athlete-active-menu">
            <Popover
              placement="bottomRight"
              title={""}
              content={menu}
              trigger="click"
              visible={visible}
              onVisibleChange={setVisible}
              className="hd-name"
            >
              <a className="ant-dropdown-link">
                <Icon type="more" />
              </a>
            </Popover>
          </div>

          <Link to={"/athletes/" + athleteId} className="athlete-list-link">
            <Row className="athlete-list-header">
              <div className="avatar-col">
                <img src={picture} alt="" className="avatar-large" />
              </div>
              <div className="name-col">
                <Text className="athlete-name" ellipsis={true}>
                  {firstName.charAt(0).toUpperCase() + firstName.slice(1)}
                </Text>
                <Text className="athlete-name" ellipsis={true}>
                  {lastName.charAt(0).toUpperCase() + lastName.slice(1)}
                </Text>
                <div className="athlete-health-status">
                  <span>{PlayerHealthStatus}</span>
                </div>
              </div>
            </Row>
            <Row className="athlete-list-body">
              <div>
                <div className="athlete-position-lb">{positionLabel}</div>

                <div className="athlete-position">
                  {position ? position : "N/A"}
                </div>

                <div className="athlete-test-lb">Baseline</div>

                <div className="athlete-test">
                  {baselineCognitiveDate !== null
                    ? moment(baselineCognitiveDate).format("L")
                    : t("Not completed")}
                </div>
              </div>
            </Row>
          </Link>
        </Row>
      </Card>
      <ConfirmationModal
        type={type}
        visibleConfirmModal={visibleConfirmModal}
        handleConfirmModalCancel={handleConfirmModalCancel}
        removeAthletes={handleRemoveAthlete}
        deleteAthlete={deleteAthletes}
        removePendingAthlete={handleRemovePendingAthlete}
      />
    </>
  );
};

export default AthleteCard;
