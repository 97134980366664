export interface ConcussionTable {
    name: string
    position: string
    nextStep: string
    daysInjured: string
    picture: string
}

export interface ConcussionTableState {
    concussionTableResultData: Array<ConcussionTable>
}

export enum CurrentConcussionTableTypes{
    CONCUSSIONTABLE_REQUEST='@@concussionTable/CONCUSSIONTABLE_REQUEST',
    CONCUSSIONTABLE_SUCCESS='@@concussionTable/CONCUSSIONTABLE_SUCCESS',
    CONCUSSIONTABLE_ERROR='@@concussionTable/CONCUSSIONTABLE_ERROR',
} 