import {
  call,
  all,
  put,
  fork,
  takeLatest
} from "redux-saga/effects";
import { createBrowserHistory } from "history";

import {
  requestAthleteProfileDataSuccess,
  requestAthleteProfileData,
  requestAthleteProfileDataError
} from "../actions/athlete-profile";
import { apiCaller } from "../../utils/apicaller";
import { AthleteProfileTypes } from "../types/athlete_profile";
import { AxiosResponse } from "axios";

export const history = createBrowserHistory();

const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;

function* fetchAthleteProfileDataAsync(
  action: ReturnType<typeof requestAthleteProfileData>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const teamSelection: string | null = localStorage.getItem("teamSelection");
    const response: AxiosResponse = yield call(
      apiCaller,
      "get",
      API_ENDPOINT,
      `coach/${action.payload}/getAthleteDetails?clubSportTeamId=${teamSelection}`
    );

    if (response.data.error || response.status === 500) {
      yield put(requestAthleteProfileDataError());
    } else {
      yield put(requestAthleteProfileDataSuccess(response.data));
    }
  } catch (err) {
    yield put(requestAthleteProfileDataError());
  }
}

//this is watcherfunction we use takeLatest to watch redux actions which should go through saga

function* watchFetchAthleteProfileData() {
  yield takeLatest(
    AthleteProfileTypes.ATHLETE_PROFILE_REQUEST,
    fetchAthleteProfileDataAsync
  );
}
// We can also use `fork()` here to split our saga into multiple watchers.

function* athleteProfileSaga() {
  yield all([fork(watchFetchAthleteProfileData)]);
}

export default athleteProfileSaga;