//package imports
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import { Provider } from "react-redux";
//local imports
import * as serviceWorker from "./serviceWorker";
import "./i18n";

//css
import "./index.less";
import Routes from "./routes";
import configureStore from "./configure-store";
import AnimatedSpinner from "./components/spinner/AnimatedSpinner"


const history = createBrowserHistory();
const initiateState = window.INITIAL_REDUX_STATE;
const store = configureStore(history, initiateState);

ReactDOM.render(
  <Provider store={store}>
    <React.Suspense fallback={<AnimatedSpinner />}>
    <Router history={history}>
        <Routes />
    </Router>
    </React.Suspense>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
