import { call, all, put, fork, takeLatest } from "redux-saga/effects";
import { createBrowserHistory } from "history";

import {
  requestTeamsListSuccess,
  requestTeamsList,
  requestTeamsListError,
} from "../actions/team-selection";
import { apiCaller } from "../../utils/apicaller";
import { TeamListTypes } from "../types/team-selection";
import { AxiosResponse } from "axios";

export const history = createBrowserHistory();

const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;

function* fetchTeamListAsync(action: ReturnType<typeof requestTeamsList>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const response: AxiosResponse = yield call(
      apiCaller,
      "get",
      API_ENDPOINT,
      `Coach/clubSportTeams`
    );

    if (response.data.error || response.status === 500) {
      yield put(requestTeamsListError());
    } else {
      yield put(requestTeamsListSuccess(response.data));
    }
  } catch (err) {
    yield put(requestTeamsListError());
  }
}

//this is watcherfunction we use takeLatest to watch redux actions which should go through saga

function* watchFetchTeamList() {
  yield takeLatest(TeamListTypes.TEAMLIST_REQUEST, fetchTeamListAsync);
}
// We can also use `fork()` here to split our saga into multiple watchers.

function* teamListSaga() {
  yield all([fork(watchFetchTeamList)]);
}

export default teamListSaga;
