import { Reducer } from "redux";
import { CoachState, CoachTypes, CoachUpdateState } from "../types/coach-profile";

export const initialStateCoach: CoachState = {
    coachData: { firstName: "", lastName: "", email: "", id: "", sport: "", team: "", club: [], locale: "sv-SE" }
}
export const initialStateCoachUpdate: CoachUpdateState = {
    coachUpdateData: { firstName: "", lastName: "", email: "", id: "", sport: "", team: "", club: [], isActivated: false, clubSportTeamId: "1" },
    updateMessage: ""
}
const reducer: Reducer<CoachState> = (state = initialStateCoach, action) => {
    switch (action.type) {
        case (CoachTypes.COACH_REQUEST):
            return { ...state }
        case (CoachTypes.COACH_SUCCESS):
            return { ...state, coachData: { ...state.coachData, ...action.payload } } // the teamSelection might already be set when this is called, so append instead of replace
        case (CoachTypes.COACH_NONEXISTENT):
            return { ...state, coachData: undefined }
        case (CoachTypes.COACH_SET_TEAM):
            return { ...state, coachData: { ...state.coachData, teamSelection: action.payload } }
        default:
            return { ...state }
    }
}
const reducerUpdate: Reducer<CoachUpdateState> = (state = initialStateCoachUpdate, action) => {
    switch (action.type) {
        case (CoachTypes.COACH_UPDATE):
            return { ...state, CoachData: action.payload }
        case (CoachTypes.COACH_UPDATE_SUCCESS):
            return { ...state, updateMessage: action.payload }
        default:
            return { ...state }
    }
}
export { reducer as coachReducer }
export { reducerUpdate as coachUpdateReducer }



