import React, { FC } from "react";

interface NoOfConcussionProps {
  numberOfConcussions: { value: string; year: string }[];
}

const CurrentConcussionsComponent: FC<NoOfConcussionProps> = ({
  numberOfConcussions,
}) => {
  return (
    <div className="num-cns">
      <div className="year-one">
        <div className="">
          <div className="year-value">
            <span>{numberOfConcussions && numberOfConcussions[0].value}</span>
          </div>
          <div className="year-label">
            {numberOfConcussions && numberOfConcussions[0].year}
          </div>
        </div>
      </div>
      <div className="year-two">
        <div>
          <div className="year-value">
            <span>{numberOfConcussions && numberOfConcussions[1].value}</span>
          </div>
          <div className="year-label">
            {numberOfConcussions && numberOfConcussions[1].year}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentConcussionsComponent;
