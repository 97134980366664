import { action } from "typesafe-actions";
import { AthleteCardTypes } from "../types/athlete-card";

export const removePendingAthlete = (
  coachId: number,
  athleteId: number,
  athleteEmail: string,
  clubSportTeamId: number
) =>
  action(AthleteCardTypes.ATHLETE_CARD_REQUEST, {
    coachId,
    athleteId,
    athleteEmail,
    clubSportTeamId,
  });

export const removePendingAthleteSuccess = (status: number) =>
  action(AthleteCardTypes.ATHLETE_CARD_SUCCESS, status);

export const removePendingAthleteError = (status: number) =>
  action(AthleteCardTypes.ATHLETE_CARD_ERROR, status);

export const deleteAthleteRequest = (athleteEmail: string) =>
  action(AthleteCardTypes.ATHLETE_REMOVE_REQUEST, { athleteEmail });

export const deleteAthleteSuccess = (status: number) =>
  action(AthleteCardTypes.ATHLETE_REMOVE_SUCCESS, status);

export const deleteAthleteError = (status: number) =>
  action(AthleteCardTypes.ATHLETE_CARD_ERROR, status);
