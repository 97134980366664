import { action } from "typesafe-actions";
import { TeamListState, TeamListTypes } from "../types/team-selection";

export const requestTeamsList = () => action(TeamListTypes.TEAMLIST_REQUEST);

export const requestTeamsListSuccess = (temaList: TeamListState) =>
  action(TeamListTypes.TEAMLIST_SUCCESS, temaList);

export const requestTeamsListError = () => action(TeamListTypes.TEAMLIST_ERROR);

export const fetchTeamsList = () => action("FETCHED_ATHLETE_DATA");
