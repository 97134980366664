import React from "react";
import { Icon } from "antd";

// CSS
import "./icons.less";

const IconDashboardSvg = () => (
  <svg fill="currentColor" viewBox="0 0 17 17" id="icon_dashboard">
      <path d="M9 0h8v6H9V0zm0 17V7h8v10H9zm-9 0v-6h8v6H0zm0-7V0h8v10H0zm1-9v8h6V1H1zm9 0v4h6V1h-6zm0 7v8h6V8h-6zm-9 4v4h6v-4H1z"></path>
  </svg>
);

const IconAthletesSvg = () => (
    <svg fill="currentColor" viewBox="0 0 17 17" id="icon_athletes">
        <path d="M14 0l-3 3H3a3 3 0 00-3 3v3a3 3 0 003 3v3a2 2 0 002 2h1a2 2 0 002-2v-3h3l3 3h2V0h-2zm.414 1H15v13h-.586l-3-3H3a2 2 0 01-2-2V6a2 2 0 012-2h8.414l3-3zM18 3v1.008a3.5 3.5 0 010 6.93v1.007A4.5 4.5 0 0018 3zm0 3.059v2.828a1.5 1.5 0 000-2.828zM4 12h3v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-3z" ></path>
    </svg>
);


const IconInboxSvg = () => (
    <svg fill="currentColor" viewBox="0 0 17 17" id="icon_inbox">
        <path d="M3 0h11a3 3 0 013 3v11a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3zm0 1a2 2 0 00-2 2v8h5v.5a2.5 2.5 0 005 0V11h5V3a2 2 0 00-2-2H3zM1 14a2 2 0 002 2h11a2 2 0 002-2v-2h-4.035a3.5 3.5 0 01-6.93 0H1v2z"></path>
    </svg>
);

const IconPositionSvg = () => (
    <svg fill="currentColor" viewBox="0 0 20 20" id="icon_position">
        <path d="M3 7.25a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm0 4a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm7-4a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm0 4a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm7-5.5a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5zm0-4a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zm0 5.5a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm0 4a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM3 .25a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm0 4a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm7 10a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm0 4a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm0-18a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm0 4a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z"></path>
    </svg>
);

const IconDamageSvg = () => (
    <svg fill="currentColor" viewBox="0 0 20 20" id="icon_damage">
        <path d="M5.468 7.395l-.002.001-.003.002.005-.003zm9.954-.187a1.24 1.24 0 00-.23-.175 1 1 0 00-1.4.411 5.782 5.782 0 01-1.398 1.778A8.714 8.714 0 008.128.13a1 1 0 00-1.492.806 7.017 7.017 0 01-2.471 4.942l-.23.187a8.513 8.513 0 00-1.988 1.863 8.983 8.983 0 003.656 13.908 1 1 0 001.327-1.238 6.977 6.977 0 01-.19-2.581 9.004 9.004 0 004.313 4.016c.225.1.48.114.715.038a8.996 8.996 0 003.654-14.863zm-3.905 12.831a6.964 6.964 0 01-3.577-4.402 8.911 8.911 0 01-.18-.964 1 1 0 00-1.857-.363 8.96 8.96 0 00-1.205 4.718A6.985 6.985 0 013.522 9.16a6.555 6.555 0 011.562-1.458.746.746 0 00.075-.055s.296-.245.306-.25a8.968 8.968 0 002.9-4.633 6.736 6.736 0 011.385 8.088 1 1 0 001.184 1.418 7.857 7.857 0 003.862-2.688 6.999 6.999 0 01-3.28 10.457z"></path>
    </svg>
);

const IconFilesSvg = () => (
    <svg fill="currentColor" viewBox="0 0 20 20" id="icon_files">
        <path d="M13 18H5a3 3 0 01-3-3V5a1 1 0 00-2 0v10a5 5 0 005 5h8a1 1 0 000-2zm5-11.06a1.307 1.307 0 00-.06-.27v-.09a1.07 1.07 0 00-.19-.28l-6-6a1.07 1.07 0 00-.28-.19h-.09L11.06 0H7a3 3 0 00-3 3v10a3 3 0 003 3h8a3 3 0 003-3V6.94zm-6-3.53L14.59 6H13a1 1 0 01-1-1V3.41zM16 13a1 1 0 01-1 1H7a1 1 0 01-1-1V3a1 1 0 011-1h3v3a3 3 0 003 3h3v5z"></path>
    </svg>
);
const IconResetSvg = () => (
    <svg fill="currentColor" viewBox="0 0 20 20" id="icon_files">
        <path d="M3.5 15.5H2v-11h7.8l-.8.79a1 1 0 001.41 1.42l2.5-2.5a1 1 0 000-1.42l-2.5-2.5A1 1 0 109 1.71l.79.79H1a1 1 0 00-1 1v13a1 1 0 001 1h2.5a1 1 0 000-2zM19 2.5h-2.5a1 1 0 100 2H18v11H9.63l.79-.79A1.002 1.002 0 009.715 13a1 1 0 00-.705.29l-2.5 2.5a1 1 0 000 1.42l2.5 2.5a1 1 0 101.41-1.42l-.79-.79H19a1 1 0 001-1v-13a1 1 0 00-1-1z"></path>
    </svg>
);

const IconNoDataSvg = () => (
    <svg fill="currentColor" viewBox="0 0 66 68" id="icon_nodata">
        <path d="M2.125 23.795l29.25 16.9a3.25 3.25 0 003.25 0l29.25-16.9A3.25 3.25 0 0065.5 21a3.25 3.25 0 00-1.625-2.828L34.625 1.305a3.25 3.25 0 00-3.25 0L2.125 18.172A3.25 3.25 0 00.5 21a3.25 3.25 0 001.625 2.795zM33 8l22.75 13L33 34 10.25 21 33 8zm27.625 23.302L33 47 5.375 31.172a3.25 3.25 0 00-4.453 1.203 3.25 3.25 0 001.203 4.42l29.25 16.9a3.25 3.25 0 003.25 0l29.25-16.9a3.25 3.25 0 00.367-5.381 3.25 3.25 0 00-3.617-.242v.13zm0 13L33 60 5.375 44.172a3.249 3.249 0 00-4.762 3.66 3.25 3.25 0 001.512 1.963l29.25 16.9a3.25 3.25 0 003.25 0l29.25-16.9a3.25 3.25 0 00.367-5.381 3.25 3.25 0 00-3.617-.242v.13z"></path>
    </svg>
);
const IconAwardSvg = () => (
    <svg fill="currentColor" viewBox="0 0 16 16" id="icon_award">
        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
        <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
    </svg>
);

const GradientBgSvg = () => (
    <svg  aria-hidden="true" focusable="false">
        <linearGradient id="blue-gradient" x1="68%" y1="0%" x2="32%" y2="100%">
            <stop offset="0%" />
            <stop offset="100%" />
        </linearGradient>
    </svg>
);


const IconDashboard = (props: any) => {
  return <Icon component={IconDashboardSvg} {...props} />;
};
const IconAthletes = (props: any) => {
    return <Icon component={IconAthletesSvg} {...props} />;
};
const IconInbox = (props: any) => {
    return <Icon component={IconInboxSvg} {...props} />;
};

const IconPosition = (props: any) => {
    return <Icon component={IconPositionSvg} {...props} />;
};
const IconDamage = (props: any) => {
    return <Icon component={IconDamageSvg} {...props} />;
};

const IconFiles = (props: any) => {
    return <Icon component={IconFilesSvg} {...props} />;
};

const IconReset = (props: any) => {
    return <Icon component={IconResetSvg} {...props} />;
};

const IconNodata = (props: any) => {
    return <Icon component={IconNoDataSvg} {...props} />;
};

const GradientBg = (props: any) => {
    return <Icon component={GradientBgSvg} {...props} />;
};

const IconAward = (props: any) => {
    return <Icon component={IconAwardSvg} {...props} />;
};

export default { IconDashboard,IconAthletes,IconInbox,GradientBg,IconPosition,IconDamage,IconFiles,IconReset,IconNodata,IconAward};
