import { all, call, put, takeLatest, fork, delay } from "redux-saga/effects";
import { apiCaller } from "../../utils/apicaller";
import {
  requestCoachPersonalData,
  requestCoachPersonalDataError,
  requestCoachPersonalDataSuccess,
  requestCoachNonExistentError,
  updateCoachPersonalData,
  updateCoachPersonalDataError,
  updateCoachPersonalDataSuccess,
} from "../actions/coach-profile";
import { CoachTypes } from "../types/coach-profile";
import { requestTableData } from "../actions/concussion-table";
import { requestWidgetData } from "../actions/dashboard-widgets";
import { RegisterCoachIDObject } from "../types/notification";
import {
  registerNotificationCoachID,
  requestOldNotification,
} from "../actions/notification";
import { AxiosResponse } from "axios";

const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;

function* fetchCoachPersonalData(
  action: ReturnType<typeof requestCoachPersonalData>
) {
  try {
    const coachResponse: AxiosResponse = yield call(
      apiCaller,
      "get",
      API_ENDPOINT,
      "Coach/identify"
    );

    if (typeof coachResponse === "undefined" || coachResponse.data === "Coach does not exist" || coachResponse.status !== 200) {
      yield put(requestCoachNonExistentError());
    } else if (coachResponse.data.error) {
      yield put(requestCoachPersonalDataError());
    } else {
      const localeMapResponse: AxiosResponse = yield call(
        apiCaller,
        "get",
        API_ENDPOINT,
        "Coach/getLocales"
      );
      if (localeMapResponse.status !== 200) {
        console.log("Unable to get locale map")
        yield put(requestCoachPersonalDataError());
      } else {
        // map localeId of coachResponse.data onto coachReponse.data.locale
        const foundLocale = localeMapResponse.data.find((l: any) => l.id === coachResponse.data.localeId);
        coachResponse.data['locale'] = foundLocale?.locale ?? "en-US";
      }

      yield put(requestCoachPersonalDataSuccess(coachResponse.data));
      sessionStorage.setItem("coachID", coachResponse.data.id);
      // yield put(requestTableData(coachResponse.data.id));
      // yield put(requestWidgetData(coachResponse.data.id));
      var registerCoachID: RegisterCoachIDObject = {
        Id: sessionStorage.getItem("coachID") || "",
        PushToken: sessionStorage.getItem("coachNotificationToken") || "",
      };
      yield put(registerNotificationCoachID(registerCoachID));
      yield delay(500);
      yield put(requestOldNotification({
        coachId: coachResponse.data.id,
        teamSelection: parseInt(localStorage.getItem("teamSelection") as string) 
      }));  // NOTE: change to use parameter from action not localStorage directly!
    }
  } catch (error) {
    yield put(requestCoachPersonalDataError());
  }
}

function* fetchCoachPersonalDataUpdate(
  action: ReturnType<typeof updateCoachPersonalData>
) {
  try {
    const coachUpdateResponse: AxiosResponse = yield call(
      apiCaller,
      "put",
      API_ENDPOINT,
      `Coach/${action.payload.id}`, // id here is coachId
      action.payload
    );

    if (coachUpdateResponse.data.error) {
      yield put(updateCoachPersonalDataError(coachUpdateResponse.data));
    } else {
      yield put(updateCoachPersonalDataSuccess(coachUpdateResponse.status as any)); // TODO: properly fix the type here
    }
    window.location.reload();
  } catch (error) {
    yield put(updateCoachPersonalDataError(error as any));
  }
}
function* watchFetchCoachPersonal() {
  yield takeLatest(CoachTypes.COACH_REQUEST, fetchCoachPersonalData);
}
function* watchFetchCoachPersonalUpdate() {
  yield takeLatest(CoachTypes.COACH_UPDATE, fetchCoachPersonalDataUpdate);
}
function* CoachPersonalSaga() {
  yield all([
    fork(watchFetchCoachPersonal),
    fork(watchFetchCoachPersonalUpdate),
  ]);
}

export default CoachPersonalSaga;