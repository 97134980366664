import axios, { AxiosRequestConfig } from "axios";
import { TokenStorageService } from "./tokenStorage";

type RequestMethod = AxiosRequestConfig["method"];
const performAuthenticatedRequest = async <T>(
  method: RequestMethod,
  url: string,
  data?: object
) => {
  const response = await axios({
    url,
    data,
    method,
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      accept: "application/octet-stream",
      idToken: `${sessionStorage.getItem("msal.idtoken")}`,
      locale: `${localStorage.getItem("language")}`,
    },
  });
  return response;
};
const post = <T>(endpoint: string, data?: object) => {
  return performAuthenticatedRequest("post", endpoint, data);
};

export const get = <T>(endpoint: string) => {
  return performAuthenticatedRequest("get", endpoint);
};

export const put = <T>(endpoint: string, data?: object) => {
  return performAuthenticatedRequest("put", endpoint, data);
};
export const del = <T>(endpoint: string, data?: object) => {
  return performAuthenticatedRequest("delete", endpoint, data);
};
//######
axios.interceptors.request.use(
  (config) => {
    if (sessionStorage.getItem("msal.idtoken")) {
      config.headers["Authorization"] =
        "Bearer " + sessionStorage.getItem("msal.idtoken");
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
//############3
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (
      error.response.status === 400 &&
      !error.config.url.includes("/login") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return axios
        .post("/auth/token", {
          // "refresh_token": localStorageService.getRefreshToken()
        })
        .then((res) => {
          if (res.status === 201) {
            // 1) put token to LocalStorage
            const tokenstorage = new TokenStorageService();
            tokenstorage.putTokens(res.data, res.data, res.data, false);

            // 2) Change Authorization header
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + tokenstorage.getToken().userToken;

            // 3) return originalRequest object with Axios.
            return axios(originalRequest);
          }
        });
    }
    return error.response;
  }
);
export default post;
