import {
  call,
  all,
  put,
  fork,
  takeLatest,
  take
} from "redux-saga/effects";
import { createBrowserHistory } from "history";

import {
  requestTableDataSuccess,
  requestTableData,
  requestTableDataError
} from "../actions/concussion-table";
import { apiCaller } from "../../utils/apicaller";
import { CurrentConcussionTableTypes } from "../types/concussion-table";
import { CoachTypes } from "../types/coach-profile";
import { AxiosResponse } from "axios";

export const history = createBrowserHistory();
const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;

function* fetchConcussionDataAsync(
  action: ReturnType<typeof requestTableData>
) {
  try {
    // To call async functions, use redux-saga's `call()`.

    const response: AxiosResponse = yield call(
      apiCaller,
      "get",
      API_ENDPOINT,
      `Coach/${action.payload}/athletes` // TODO: modify action to require clubSportTeamId (and not coachID) and change to use cstId from that instead of localStorage directly
    );

    if (response.data.error || response.status === 500) {
      yield put(requestTableDataError());
    } else {
      yield put(requestTableDataSuccess(response.data));
    }
  } catch (err) {
    yield put(requestTableDataError());
  }
}

//this is watcherfunction we use takeLatest to watch redux actions which should go through saga

function* watchFetchWidgetData() {
  yield takeLatest(
    CurrentConcussionTableTypes.CONCUSSIONTABLE_REQUEST,
    fetchConcussionDataAsync
  );
}
// We can also use `fork()` here to split our saga into multiple watchers.

function* tableSaga() {
  yield take(CoachTypes.COACH_REQUEST);

  yield all([fork(watchFetchWidgetData)]);
}

export default tableSaga;