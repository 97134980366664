import { action } from "typesafe-actions";
import { IAthleteDetail } from "../../pages/athletes/athletes";
import { NewAthlete, InviteAthleteTypes, EmailList, EmailListValidityResponse } from "../types/invite-athlete";

export const getAthleteByEmail = (athleteEmail: string) => action(InviteAthleteTypes.GET_ATHLETE_BY_EMAIL, athleteEmail)

export const getAthleteByEmailSuccess = (athleteDetail: IAthleteDetail) => action(InviteAthleteTypes.GET_ATHLETE_BY_EMAIL_SUCCESS, athleteDetail)

export const getAthleteByEmailError = (status: string) => action(InviteAthleteTypes.GET_ATHLETE_BY_EMAIL_ERROR, status)

export const requestInviteAthlete = (AthleteData: Array<NewAthlete>) => action(InviteAthleteTypes.INVITEATHLETE_REQUEST, AthleteData)

export const requestInviteAthleteSuccess = (successMessage: number) => action(InviteAthleteTypes.INVITEATHLETE_SUCCESS, successMessage)

export const requestInviteAthleteError = (successMessage: number) => action(InviteAthleteTypes.INVITEATHLETE_ERROR, successMessage)

export const requestCheckEmailValidate = (Email: string) => action(InviteAthleteTypes.CHECKEMAIL_REQUEST, Email)

export const requestCheckEmailValidateSuccess = (EmailValidMessage: string) => action(InviteAthleteTypes.CHECKEMAIL_MESSAGE, EmailValidMessage)

export const requestBulkCheckEmailValidity = (EmailList: Array<EmailList>) => action(InviteAthleteTypes.CHECKEMAIL_BULKREQUEST, EmailList)

export const requestBulkCheckEmailValiditySuccess = (EmailListValidityResponse: Array<EmailListValidityResponse>) => action(InviteAthleteTypes.CHECKEMAIL_BULKMESSAGE, EmailListValidityResponse)

export const requestBulkCheckEmailValidityError = (errorMessage: String) => action(InviteAthleteTypes.CHECKEMAIL_BULKERROR, errorMessage)
