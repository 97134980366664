import { Icon } from "antd";
import React, { MouseEventHandler, useState } from "react";

import "./custom-tag.less";

const CustomTag = (
    { children, onClose, prependText }: 
    {
        children: JSX.Element | string, 
        onClose: MouseEventHandler<HTMLElement>,
        prependText?: string
    }
) => {

    return (
        <div className="ant-tag span-tag">
            {prependText && <span className="prepend-text">{prependText}</span>}
            {children}
            <Icon type="close" onClick={onClose} />
        </div>
    );
}

export default CustomTag;