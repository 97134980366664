import React, { FC, useState } from "react";
import { Row, List } from "antd";
import { useTranslation } from "react-i18next";
import AthletesInRisk from "../athlete-in-risk";

interface AthleteRisk {
  athleteName: string;
  frequency: number;
  picture: string;
}

interface AthletesRiskProps {
  viewClicked: boolean;
  athletesInRisk: AthleteRisk[];
}

const AthletesRiskComponent: FC<AthletesRiskProps> = ({
  viewClicked,
  athletesInRisk,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { t } = useTranslation("dashboard");

  const count = athletesInRisk.length;
  return viewClicked === false ? (
    <Row>
      <div style={{ textAlign: "center" }}>
        <div className="widget-athlete-risk">
          <div className="widget-large-count" data-testid="no-of-athletes">
            {count}
          </div>

          <div className="widget-large-label">{t("Athletes at risk")}</div>
        </div>
      </div>
    </Row>
  ) : (
    <Row>
      <div className="widget-athlete-risk">
        <List
          grid={{
            gutter: 16,
            xs: 2,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          pagination={{
            onChange: (page) => {
              setCurrentPage(page);
            },
            pageSize: 1,
            position: "bottom",
          }}
          dataSource={athletesInRisk}
          renderItem={(item: AthleteRisk) => <AthletesInRisk {...item} />}
        />

        {count ? (
          <span className="athlete-in-rsk-counter">
            {`${currentPage}/${count}`}
          </span>
        ) : null}
      </div>
    </Row>
  );
};

export default AthletesRiskComponent;
