export interface AthleteCardState {
  athleteRemovalStatus: number;
  athleteHardRemovalStatus: number;
}

export enum AthleteCardTypes {
  ATHLETE_CARD_REQUEST = "@@athlete/ATHLETE_CARD_REQUEST",
  ATHLETE_CARD_SUCCESS = "@@athlete/ATHLETE_CARD_SUCCESS",
  ATHLETE_CARD_ERROR = "@@athlete/ATHLETE_CARD_ERROR",
  ATHLETE_REMOVE_REQUEST = "@@athlete/ATHLETE_REMOVE_REQUEST",
  ATHLETE_REMOVE_SUCCESS = "@@athlete/ATHLETE_REMOVE_SUCCESS",
  ATHLETE_REMOVE_ERROR = "@@athlete/ATHLETE_REMOVE_ERROR"
}