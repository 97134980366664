//package imports
import React, { FC, Fragment } from "react";
import { Row, Col, Statistic, Avatar, Tooltip } from "antd";

import { useTranslation } from "react-i18next";
import ProfilePic from "../../assets/images/avatar2.svg";

type AthleteRisk = {
  athleteName: string;
  frequency: number;
  picture: string | null;
};

const AthletesInRisk: FC<AthleteRisk> = ({
  athleteName,
  frequency,
  picture,
}) => {
  const { t } = useTranslation("dashboard");

  return (
    <Fragment>
      <Row className="athlete-rsk-header">
        <Col span={12}>
          <span className="athlete-rsk-title">{t("Name")}</span>
        </Col>
        <Col span={12} style={{ textAlign: "center" }}>
          <span className="athlete-rsk-title">{t("Frequency")}</span>
        </Col>
      </Row>

      <Row className="athlete-rsk-body">
        <div className="col-img">
          <div className="athlete-rsk">
            <Avatar
              className="avatarGrid"
              src={picture ? picture : ProfilePic}
            />
          </div>
        </div>
        <div className="col-name">
          <div className="athlete-rsk">
            <Statistic
              value={athleteName}
              formatter={(athleteName) => `${athleteName}`}
            />
          </div>
        </div>
        <div className="col-count">
          <div className="athlete-rsk counter">
            <Tooltip title={t("Number_of_concussions")}>
              <span>
                <Statistic
                  valueStyle={{
                    alignItems: "center",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                  value={frequency}
                  formatter={(frequency) => `${frequency}`}
                />
              </span>
            </Tooltip>
          </div>
        </div>
      </Row>
    </Fragment>
  );
};

export default AthletesInRisk;
