import React, { FC, Fragment } from "react";
import { Col, Spin, Progress } from "antd";

interface NoOfConcussionProps {
  baselineTest: {
    noOfAthletesDoneBaseline: number;
    noOfAthletesUnderCoach: number;
  } | null;
}

const BaseLineTestComponent: FC<NoOfConcussionProps> = ({ baselineTest }) => {
  const particle = baselineTest ? baselineTest.noOfAthletesDoneBaseline : 0;
  const core = baselineTest ? baselineTest.noOfAthletesUnderCoach : 0;

  return (
    <Fragment>
      <Col span={12} className="widget-p-circle">
        <Spin spinning={!baselineTest}>
          <div className="bs-test">
            <Progress
              type="circle"
              percent={(particle / core) * 100}
              format={() => `${particle}/${core}`}
            />
          </div>
        </Spin>
      </Col>
    </Fragment>
  );
};

export default BaseLineTestComponent;
