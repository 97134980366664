export interface ConcussionTestDetails {
  stepNumber: string;
  stepNames: object;
  stepDescriptions: object;
}

export interface StepDetail{
  stepNumber: string;
  stepName: string;
  stepDescription: string;
}

export interface ConcussionTestDetailsState {
  concussionTestDetailsData: Array<ConcussionTestDetails>;
}

export enum ConcussionTestDetailsTypes {
  CONCUSSION_TEST_DETAILS_REQUEST = "@@concussionTestDetails/CONCUSSION_TEST_DETAILS_REQUEST",
  CONCUSSION_TEST_DETAILS_SUCCESS = "@@concussionTestDetails/CONCUSSION_TEST_DETAILS_SUCCESS",
  CONCUSSION_TEST_DETAILS_ERROR = "@@concussionTestDetails/CONCUSSION_TEST_DETAILS_ERROR"
}