import React, { FC, useEffect, useState } from "react";
import { Table } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { requestCoachPersonalData } from "../../store/actions/coach-profile";

//css
import "./dashboardTable.less";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";

const TableSorter: FC<any> = (props) => {
  const { requestCoachPersonalData, concussionDataProp, coachData } = props;
  // ToDo:Update prop types
  const [sortedConcussionData, setSortedConcussionData] = useState<any>(null);
  const { t } = useTranslation("dashboard");
  let history = useHistory();

  useEffect(() => {
    requestCoachPersonalData();
  }, []);

  useEffect(() => {
    if (concussionDataProp) {
      const sortedData = concussionDataProp.sort(compare);
      setSortedConcussionData(sortedData);
    }
  }, [concussionDataProp]);

  const compare = (a: any, b: any) => {
    const firstRecord = a.fullName.toUpperCase();
    const secondRecord = b.fullName.toUpperCase();

    let comparison = 0;
    if (firstRecord > secondRecord) {
      comparison = 1;
    } else if (firstRecord < secondRecord) {
      comparison = -1;
    }
    return comparison;
  };

  const columns = [
    {
      title: "",
      dataIndex: "picture",
      className: "col-img table-profile-pic",
      render: (theImageURL: string | undefined) => (
        <img alt={theImageURL} src={theImageURL} />
      ),
    },
    {
      title: t("Name"),
      dataIndex: "fullName",
      className: "col-first-name",
    },
    {
      title: t(coachData.positionLabel),
      dataIndex: "position",
      className: "col-position",
    },
    {
      title: t("Next step"),
      dataIndex: "nextStep",
      className: "col-current-step",
    },
    {
      title: t("Days injured"),
      dataIndex: "daysInjured",
      className: "col-days-injured txt-center",
    },
    {
      title: t("Health status2"),
      dataIndex: "healthStatus",
      className: "col-current-step txt-center",
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={Math.floor(Math.random() * 10).toString()}
      dataSource={sortedConcussionData}
      pagination={false}
      loading={sortedConcussionData ? false : true}
      onRow={(record: any) => {
        return {
          onClick: () => {
            history.push({
              pathname: `/athletes/${record.athleteId}`,
            });
          },
        };
      }}
    />
    //
  );
};
const mapStateToProps = ({ coachPersonalData }: ApplicationState) => ({
  coachData: coachPersonalData.coachData,
});
const mapDispatchToProps = {
  requestCoachPersonalData: requestCoachPersonalData,
};
// export default withRouter(withTranslation()(TableSorter) as any);
export default connect(mapStateToProps, mapDispatchToProps)(TableSorter);
