import { Reducer } from "redux";
import { AthleteListState, AthleteListTypes } from "../types/athlete-list";

export const initialState: AthleteListState = {
  athleteListData: [],
  athleteListLoading: true,
};

const reducer: Reducer<AthleteListState> = (state = initialState, action) => {
  switch (action.type) {
    case AthleteListTypes.ATHLETELIST_REQUEST:
      return { ...initialState };
    case AthleteListTypes.ATHLETELIST_SUCCESS:
      return {
        athleteListData: action.payload,
        athleteListLoading: false,
      };
    case AthleteListTypes.ATHLETELIST_ERROR:
      return { athleteListData: [], athleteListLoading: false };
    default:
      return state;
  }
};

export { reducer as athleteListReducer };
