import { Reducer } from "redux";
import { AthleteRequestConcussionTestListState, AthleteResponseConcussionTestListState, NotificationTypes, RegisterCoachIDState } from "../types/notification";


export const initialCoachRegistrationDetailState: RegisterCoachIDState = {
    registerCoachID: { Id: "", PushToken: "" },
    registerCoachResponseMessage: ""
}
export const initialRequestState: AthleteRequestConcussionTestListState = {
    athleteListConcussionTestRequestPermission: [{
        id: 0,
        data: {
            athleteId: "",
            athleteFirstName: "",
            athleteLastName: "",
            position: "",
            requestedTime: "",
        }
    }],
    forceRequestMessage: ""
}
export const initialResponseState: AthleteResponseConcussionTestListState = {
    athleteConcussionTestResponsePermission: { athleteId: "", permission: false, type: "", teamSelection: -1 }
    , permissionReplyMessage: ""
}
const registerCoachReducer: Reducer<RegisterCoachIDState> = (state = initialCoachRegistrationDetailState, action) => {
    switch (action.type) {
        case (NotificationTypes.REGISTERCOACHNOTIFICATION_REQUEST):
            return { ...state, registerCoachID: action.payload }
        case (NotificationTypes.REGISTERCOACHNOTIFICATION_RESPONSEMESSSAGE):
            return { ...state, registerCoachResponseMessage: action.payload }
        default:
            return { ...state }
    }
}

const requestPendingPermissionReducer: Reducer<AthleteRequestConcussionTestListState> = (state = initialRequestState, action) => {
    switch (action.type) {
        case (NotificationTypes.REQUESTCONCUSSIONTESTPERMISSIONLIST_REQUEST):
            return { ...state, coachID: action.payload }
        case (NotificationTypes.REQUESTCONCUSSIONTESTPERMISSIONLIST_RESPONSE):
            return { ...state, athleteListConcussionTestRequestPermission: action.payload }
        case (NotificationTypes.RESPONSECONCUSSIONTESTPERMISSIONLIST_APPEND):
            return { ...state, athleteListConcussionTestRequestPermission: state.athleteListConcussionTestRequestPermission.filter((item) => { return item.data.athleteId !== action.payload }) }
        case (NotificationTypes.REQUESTCONCUSSIONTESTPERMISSIONLIST_INSERT):
            return { ...state, athleteListConcussionTestRequestPermission: [action.payload, ...state.athleteListConcussionTestRequestPermission.filter((item) => { return item.data.athleteId !== "" })] }

        default:
            return { ...state }
    }
}
const responseCoachPermissionReducer: Reducer<AthleteResponseConcussionTestListState> = (state = initialResponseState, action) => {
    switch (action.type) {
        case (NotificationTypes.RESPONSECONCUSSIONTESTPERMISSIONLIST_SUBMIT):
            return { ...state, athleteConcussionTestResponsePermission: action.payload }
        case (NotificationTypes.RESPONSECONCUSSIONTESTPERMISSIONLIST_DISMISS):
            return { ...state, athleteConcussionTestResponsePermission: action.payload }
        case (NotificationTypes.RESPONSECONCUSSIONTESTPERMISSIONLIST_REPLYMESSAGE):
            return { ...state, permissionReplyMessage: action.payload }
        default:
            return { ...state }
    }
}


export { registerCoachReducer }
export { requestPendingPermissionReducer }
export { responseCoachPermissionReducer }




