import React, { Component } from 'react';
import Lottie from 'react-lottie'
import './animated-spinner.less'

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: require('../../assets/images/animation_loader.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

class AnimatedSpinner extends Component {
    render() {
  return (
    <div className='spinner-container'>
      <Lottie options={defaultOptions} width={100} height={100} style={{position: 'fixed', top: '50%', left: '50%', margin: '-25px 0 0 -25px'}}/>
    </div>
  )
}
}
export default AnimatedSpinner

