import { call, all, put, fork, takeLatest } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import { AxiosResponse } from "axios";

import {
  requestAthleteDataSuccess,
  requestAthleteData,
  requestAthleteDataError,
} from "../actions/athlete-list";
import { AthleteListTypes } from "../types/athlete-list";
import AthleteServices from "../../utils/services/athlete-service/athlete";

export const history = createBrowserHistory();

export function* fetchAthleteDataAsync(
  action: ReturnType<typeof requestAthleteData>
) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const response: AxiosResponse = yield AthleteServices.GetAllAthletes(
      action.payload
    );

    if (response.data.error || response.status === 500) {
      yield put(requestAthleteDataError());
    } else {
      yield put(requestAthleteDataSuccess(response.data));
    }
  } catch (err) {
    yield put(requestAthleteDataError());
  }
}

//this is watcherfunction we use takeLatest to watch redux actions which should go through saga

function* watchFetchAthleteData() {
  yield takeLatest(AthleteListTypes.ATHLETELIST_REQUEST, fetchAthleteDataAsync);
}
// We can also use `fork()` here to split our saga into multiple watchers.

function* athleteListSaga() {
  yield all([fork(watchFetchAthleteData)]);
}

export default athleteListSaga;
