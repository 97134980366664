export class TokenStorageService {

    putTokens(jwtToken: any, refreshToken: any, userName: any, identityRemembered: Boolean) {
        if (identityRemembered === false) {
            sessionStorage.setItem('userToken', jwtToken.token);
            sessionStorage.setItem('refreshToken', refreshToken.token);
            sessionStorage.setItem('userName', userName.token);
        } else if (identityRemembered === true) {
            localStorage.setItem('userToken', jwtToken.token);
            localStorage.setItem('refreshToken', refreshToken.token);
            localStorage.setItem('userName', userName.token);
        }
    }

    refreshToken(jwtToken: any, refreshToken: any) {
        const lsUserToken = localStorage.getItem('userToken');
        const lsRefreshToken = localStorage.getItem('refreshToken');
        const ssuserToken = sessionStorage.getItem('userToken');
        const ssRefreshToken = sessionStorage.getItem('refreshToken');
        if ((lsUserToken !== null) && (lsRefreshToken !== null)) {
            localStorage.setItem('userToken', jwtToken);
            localStorage.setItem('refreshToken', refreshToken);
        }
        if ((ssuserToken !== null) && (ssRefreshToken !== null)) {
            sessionStorage.setItem('userToken', jwtToken);
            sessionStorage.setItem('refreshToken', refreshToken);
        }
    }

    getToken() {
        const lsUserToken = localStorage.getItem('userToken');
        const lsRefreshToken = localStorage.getItem('refreshToken');
        const ssuserToken = sessionStorage.getItem('userToken');
        const ssRefreshToken = sessionStorage.getItem('refreshToken');
        if ((lsUserToken !== null) && (lsRefreshToken !== null)) {
            return { userToken: lsUserToken, refreshToken: lsRefreshToken };
        }
        if ((ssuserToken !== null) && (ssRefreshToken !== null)) {
            return { userToken: ssuserToken, refreshToken: ssRefreshToken };
        }
        return { userToken: null, refreshToken: null };
    }

    removeTokens() {
        localStorage.removeItem('userToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userName');
        sessionStorage.removeItem('userToken');
        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('isRefresh');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('companyId');
    }
    isTokenAvailable() {
        const lsUserToken = localStorage.getItem('userToken');
        const ssuserToken = sessionStorage.getItem('userToken');

        if ((lsUserToken == null) && (ssuserToken == null)) {
            return false;
        } else {
            return true;
        }
    }
}