import { call, all, put, fork, takeLatest, delay } from "redux-saga/effects";
import { createBrowserHistory } from "history";

import { apiCaller } from "../../utils/apicaller";
import { registerNotificationCoachID, registerNotificationCoachIDResponse, requestOldNotification, requestOldNotificationSuccess, requestOldNotificationResponse, responsePermission, responsePermissionReplyMessage, appendOldNotifications, insertNotification, dismissNotification } from "../actions/notification";
import {
  NotificationTypes,
  NotificationScenarios,
} from "../types/notification";
import { AxiosResponse } from "axios";

export const history = createBrowserHistory();

const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;


function* registerCoach(action: ReturnType<typeof registerNotificationCoachID>) {
  try {
    // To call async functions, use redux-saga's `call()`.
    const coachId = sessionStorage.getItem("coachID")
    const response: AxiosResponse = yield call(
      apiCaller,
      "put",
      API_ENDPOINT,
      `coach/${coachId}/registerPushTokenForCoach`, action.payload
    );

    yield put(registerNotificationCoachIDResponse(response.data));

  } catch (err) {
    yield put(registerNotificationCoachIDResponse(err as any));
  }
}
function* requestForceNotification(action: ReturnType<typeof requestOldNotification>) {
  try {
    // To call async functions, use redux-saga's `call()`.

    const response: AxiosResponse = yield call(
      apiCaller,
      "get",
      API_ENDPOINT,
      `coach/${action.payload.coachId}/${action.payload.teamSelection}/getPendingBaselineNotifications`
    );
    if (response.status === 200) {
      yield put(requestOldNotificationSuccess(response.data));
    } else {
      yield put(requestOldNotificationResponse(response.data));
    }
  } catch (err) {
    yield put(requestOldNotificationResponse(err as any));
  }
}
//this is watcherfunction we use takeLatest to watch redux actions which should go through saga

function* responseCoachPermission(
  action: ReturnType<typeof responsePermission>
): any {
  try {

    const { type, athleteId, permission, teamSelection } = action.payload;
    const coachId = sessionStorage.getItem("coachID") ?? "";
    sessionStorage.setItem("approvedAthleteID", athleteId);
    let httpMethod = "get",
      url = `coach/${coachId}/${athleteId}/${permission}/getCoachResponseForTestApproval`;
    
    // Team Transfer Approval
    if (type === NotificationScenarios.TRANSFERCLUBREQUEST) {
      httpMethod = "put";
      url = `coach/${coachId}/${athleteId}/${teamSelection}/${permission}/getCoachApprovalForTransferAthleteClub`;
    } 
      
    const response = yield call(apiCaller, httpMethod, API_ENDPOINT, url);

    if (response.status === 200) {
      yield put(
        appendOldNotifications(
          sessionStorage.getItem("approvedAthleteID") || ""
        )
      );
      yield put(requestOldNotification({
        coachId: parseInt(coachId),
        teamSelection: parseInt(localStorage.getItem("teamSelection") as string)  // NOTE: change to use parameter from action mot localStorage directly!
      }));
      yield delay(1000);
      yield put(responsePermissionReplyMessage(response.status));
    } else {
      yield put(responsePermissionReplyMessage(response.status));
    }
    yield delay(5000);
    yield put(responsePermissionReplyMessage(""));
  } catch (err) {
    yield put(responsePermissionReplyMessage(err as any));
  } finally {
    sessionStorage.removeItem("approvedAthleteID");
  }
}

function* dismissAthleteNotification(action: ReturnType<typeof dismissNotification>) {
  try {
    //   To call async functions, use redux-saga's `call()`.
    const coachId = sessionStorage.getItem("coachID") ?? ''
    sessionStorage.setItem("approvedAthleteID", action.payload.athleteID)
    const response: AxiosResponse = yield call(
      apiCaller,
      "put",
      API_ENDPOINT,
      `coach/${action.payload.notificationID}/dismiss`
    );
    if (response.status === 200) {
      yield put(appendOldNotifications(sessionStorage.getItem("approvedAthleteID") || ""));
      yield put(requestOldNotification({
        coachId: parseInt(coachId),
        teamSelection: parseInt(localStorage.getItem("teamSelection") as string) // NOTE: change to use parameter from action, not from localStorage directly!
      }));
      yield delay(1000)
      yield put(responsePermissionReplyMessage(response.status as any)); // TODO: properly fix the type here
    } else {
      yield put(responsePermissionReplyMessage(response.status as any)); // TODO: properly fix the type here
    }
    yield delay(5000)
    yield put(responsePermissionReplyMessage(""))

  } catch (err) {
    yield put(responsePermissionReplyMessage(err as any));
  }
  finally {
    sessionStorage.removeItem("approvedAthleteID")

  }
}

function* appendPermissionList(action: ReturnType<typeof appendOldNotifications>) {

  // To call async functions, use redux-saga's `call()`.
  yield put(appendOldNotifications(action.payload))

}
function* insertPermissionList(action: ReturnType<typeof insertNotification>) {

  // To call async functions, use redux-saga's `call()`.
  yield put(insertNotification(action.payload))

}
function* watchCoachRegistration() {
  yield takeLatest(
    NotificationTypes.REGISTERCOACHNOTIFICATION_REQUEST,
    registerCoach
  );
}
function* watchForceNotificationRetrieval() {
  yield takeLatest(
    NotificationTypes.REQUESTCONCUSSIONTESTPERMISSIONLIST_REQUEST,
    requestForceNotification
  );
}

function* watchCoachResponsePermission() {
  yield takeLatest(
    NotificationTypes.RESPONSECONCUSSIONTESTPERMISSIONLIST_SUBMIT,
    responseCoachPermission
  );
}

function* watchDismissNotification() {
  yield takeLatest(
    NotificationTypes.RESPONSECONCUSSIONTESTPERMISSIONLIST_DISMISS,
    dismissAthleteNotification
  );
}

function* watchPermissionListAppend() {
  yield takeLatest(
    NotificationTypes.RESPONSECONCUSSIONTESTPERMISSIONLIST_APPEND,
    appendPermissionList
  );
}
function* watchPermissionListInsert() {
  yield takeLatest(
    NotificationTypes.REQUESTCONCUSSIONTESTPERMISSIONLIST_INSERT,
    insertPermissionList
  );
}
// We can also use `fork()` here to split our saga into multiple watchers.

function* notificationRequestResponseSaga() {
  yield all([fork(watchCoachRegistration), fork(watchForceNotificationRetrieval), fork(watchCoachResponsePermission), fork(watchDismissNotification)]);
}

export default notificationRequestResponseSaga;
