import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import jwt from "jwt-decode";
import { withTranslation } from "react-i18next";
import { Layout, Select, Button, Card, Typography, Modal } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import "../../styles/team-selection/team-selection.less";
//redux
import { requestTeamsList } from "../../store/actions/team-selection";
import {
  requestCoachPersonalData,
  updateCoachPersonalData,
} from "../../store/actions/coach-profile";
import { ApplicationState } from "../../store";

//context
import GlobleStateContext from "../../context/globle-state-context";
import Spinner from "../../components/spinner/spinner";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

class TeamSelection extends Component<any, any> {
  static contextType = GlobleStateContext;
  constructor(props: any) {
    super(props);

    this.state = {
      selectedCompany: null,
      companyList: null,
      name: "",
      teamName: "",
      hasNameSet: false,
      visible: this.props.visible,
    };
  }

  componentWillReceiveProps(nextProps: any) {
    const { visible, form } = this.props;
    if (nextProps.visible !== visible && nextProps.visible) {
      form.resetFields(); // Reset team form when it open.
    }
  }
  componentWillMount() {
    const { requestCoachPersonalData, requestTeamsList } = this.props;
    const token = sessionStorage.getItem("msal.idtoken");

    if (token) {
      var userDetails: any = jwt(token);
      this.setState({ name: userDetails.given_name });
      this.setState({ hasNameSet: true });
    }
    if (this.props.coachData.id === "") {
      requestCoachPersonalData();
    }
    requestTeamsList();
  }

  OnCompanySelect = (value: any) => {
    const { setSelectedCompany } = this.context;
    this.setState({
      selectedCompany: value,
    });
    setSelectedCompany(value);
  };

  confirmChangeCoachDetails = async (clubSportTeamId: string) => {
    const { updateCoachPersonalData: CoachPersonalDataUpdate, coachData } =
      this.props;

    var coachObj: any = {
      id: coachData.id,
      clubSportTeamId: clubSportTeamId,
    };

    CoachPersonalDataUpdate(coachObj);
    this.setState({ hasNameSet: false });
  };

  onFinish = (e: any) => {
    e.preventDefault();
    const { form, coachData } = this.props;
    form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err && coachData && values.team) {
        localStorage.setItem("teamSelection", values.team);

        if (coachData.isSuperCoach) {
          this.confirmChangeCoachDetails(values.team);
        } else {
          // TODO: coachData accessible from here to retrieve a flag...etc. set this condition
          window.location.reload();
        }
      }
    });
  };

  handleSelectChange = (value: any) => {
    var teamName = "";
    this.props.teamList.forEach((team: any) => {
      if (team.id === value) {
        teamName = team.teamName;
      }
    });
    this.setState({ teamName });
  };

  render() {
    const { t, form, visible, closeModal, teamList, coachData } = this.props;
    const { hasNameSet, name } = this.state;

    if (hasNameSet) {
      return (
        <Layout>
          <Modal visible={visible} footer={null} onCancel={closeModal} centered>
            <Content className="modal-content">
              <Card title="" bordered={false}>
                <Title className="h1">
                  {t("Hi")} {name}, {t("Select a team")}
                </Title>
                <Form layout="vertical" onSubmit={this.onFinish}>
                  <Form.Item label={t("Team")}>
                    {form.getFieldDecorator("team", {
                      rules: [
                        { required: true, message: t("Please select a team") },
                      ],
                    })(
                      <Select
                        placeholder={t("Select a team")}
                        onChange={this.handleSelectChange}
                        dropdownClassName="team-select"
                        dropdownMatchSelectWidth={false}
                      >
                        {coachData.isSuperCoach
                          ? teamList.map((team: any) => (
                              <Option key={team.id} value={team.id}>
                                {team.clubSport.club.clubName} -{" "}
                                {team.clubSport.sport.name} -{" "}
                                {team.team.teamName}
                              </Option>
                            ))
                          : coachData.clubSportTeamIds.map(
                              (cstId: number, i: number) => {
                                return (
                                  <Option key={cstId} value={cstId}>
                                    {coachData.club[i]} - {coachData.team[i]}
                                  </Option>
                                );
                              }
                            )}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {t("Continue")}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Content>
          </Modal>
        </Layout>
      );
    } else {
      return (
        <Content>
          <Spinner />
        </Content>
      );
    }
  }
}

const mapStateToProps = ({
  coachPersonalData,
  coachUpdateMessageData,
  teamListData,
}: ApplicationState) => ({
  coachData: coachPersonalData.coachData,
  updateMessage: coachUpdateMessageData.updateMessage,
  teamList: teamListData.teamListData,
});
const mapDispatchToProps = {
  requestCoachPersonalData: requestCoachPersonalData,
  updateCoachPersonalData: updateCoachPersonalData,
  requestTeamsList: requestTeamsList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create<FormComponentProps>()(
    withTranslation("team-selection")(withRouter(TeamSelection))
  )
);
