import { Reducer } from "redux";
import {
  AthleteProfileState,
  AthleteProfileTypes,
} from "../types/athlete_profile";

export const initialState: AthleteProfileState = {
  athleteProfileData: {
    athleteId: 0,
    firstName: "",
    lastName: "",
    email: "",
    position: "",
    nextStep: "",
    healthStatus: "",
    daysInjured: 0,
    baselineTreadmillDate: new Date(),
    baselineCognitiveDate: new Date(),
    returnToPlay: "0",
    picture: "",
    currentStatusId: 0,
    positionLabel: "",
  },
};

const reducer: Reducer<AthleteProfileState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AthleteProfileTypes.ATHLETE_PROFILE_REQUEST:
      return { ...state };
    case AthleteProfileTypes.ATHLETE_PROFILE_SUCCESS:
      return { ...state, athleteProfileData: action.payload };
    case AthleteProfileTypes.ATHLETE_PROFILE_ERROR:
      return { ...state, athleteProfileData: [] };
    default:
      return state;
  }
};

export { reducer as athleteProfileReducer };
