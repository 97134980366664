import { action } from "typesafe-actions";
 import { CoachTypes, CoachState, CoachUpdateState, CoachUpdateDetails } from "../types/coach-profile";

export const requestCoachPersonalData=()=>action(CoachTypes.COACH_REQUEST)

export const requestCoachPersonalDataSuccess=(CoachData:CoachState)=>action(CoachTypes.COACH_SUCCESS,CoachData)

export const requestCoachPersonalDataError=()=>action(CoachTypes.COACH_ERROR)

export const requestCoachNonExistentError=()=>action(CoachTypes.COACH_NONEXISTENT)

export const updateCoachPersonalData=(CoachData:CoachUpdateDetails)=>action(CoachTypes.COACH_UPDATE,CoachData)

export const updateCoachPersonalDataSuccess=(UpdateMessage:CoachUpdateState)=>action(CoachTypes.COACH_UPDATE_SUCCESS,UpdateMessage)

export const updateCoachPersonalDataError=(UpdateMessage:CoachUpdateState)=>action(CoachTypes.COACH_UPDATE_ERROR,UpdateMessage)

export const setSelectedTeam=(teamSelection: number)=>action(CoachTypes.COACH_SET_TEAM, teamSelection)