import { call, all, put, fork, takeLatest, delay } from "redux-saga/effects";

import {
    requestSidelineTestInformation,
    requestSidelineTestInformationSuccess,
    requestSidelineTestInformationError,
    requestSubmitSidelineTest,
    requestSubmitSidelineTestSuccess,
    requestSubmitSidelineTestError,
} from "../actions/sideline-test";
import { apiCaller } from "../../utils/apicaller";
import { SidelineTestTypes } from "../types/sideline-test";
import { AxiosResponse } from "axios";

const API_ENDPOINT = process.env.REACT_APP_COACH_API_ENDPOINT!;

function* requestSidelineTestInformationSaga(action: ReturnType<typeof requestSidelineTestInformation>) {
    try {
        console.log(action.payload) 
        const response: AxiosResponse = yield call(
            apiCaller,
            "get",
            API_ENDPOINT,
            `Coach/${action.payload}/sidelineInfo`
        );
        console.log(response)
        if (response.data.error || response.status === 500) {
            yield put(requestSidelineTestInformationError(response.status));
        } else {
            yield put(requestSidelineTestInformationSuccess(response.data));
        }
    } catch (err) {
        yield put(requestSidelineTestInformationError(400));
    }
}

function* requestSubmitSidelineTestSaga(action: ReturnType<typeof requestSubmitSidelineTest>) {
    try {
        const coachID = sessionStorage.getItem("coachID")

        const response: AxiosResponse = yield call(
            apiCaller,
            "post",
            API_ENDPOINT,
            `Coach/${coachID}/${action.payload.athleteId}/submitSidelineTest`,
            action.payload.sidelineTest
        );
        if (response.status === 200) {
            yield put(requestSubmitSidelineTestSuccess(response.data));
        } else {
            yield put(requestSubmitSidelineTestError(response.status));
        }
    } catch (error) {
        console.log(error)
        yield put(requestSubmitSidelineTestError(400));
        yield delay(4000);
        yield put(requestSubmitSidelineTestError(0));
    }
}

function* watchRequestSidelineTestInformation() {
    yield takeLatest(SidelineTestTypes.SIDELINETESTINFORMATION_REQUEST, requestSidelineTestInformationSaga);
}

function* watchRequestSubmitSidelineTest() {
    yield takeLatest(SidelineTestTypes.SUBMITSIDELINETEST_REQUEST, requestSubmitSidelineTestSaga);
}

function* sidelineTestSaga() {
    yield all([
      fork(watchRequestSidelineTestInformation),
      fork(watchRequestSubmitSidelineTest)
    ]);
  }
  
  export default sidelineTestSaga;