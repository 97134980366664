export interface SidelineTestState{
    sidelineTestInformation: SidelineTestInformation,
    sidelineTestLoading: boolean,
    sidelineTest: SidelineTest,
    responseMessage: number,
    result: number
}

export interface SidelineTestInformation {
    athleteList: SidelineAthlete[],
    overviewEn: string,
    overviewSv: string,
    overviewDa: string,
    overviewNb: string,
    stepOneDescriptionEn: string,
    stepOneDescriptionSv: string,
    stepOneDescriptionDa:string,
    stepOneDescriptionNb:string,
    stepTwoDescriptionEn: string,
    stepTwoDescriptionSv: string,
    stepTwoDescriptionDa: string,
    stepTwoDescriptionNb: string,
    symptomList: SidelineSymptom[],
    questionList: SidelineQuestion[]
}

export interface SidelineTest {
    symptomList: SidelineSymptom[],
    questionList: SidelineQuestion[]
}

export interface SidelineAthlete {
    id: number,
    firstName: string,
    lastName: string
}

export interface SidelineSymptom {
    id: number,
    en: string,
    sv: string,
    value: number
}

export interface SidelineQuestion {
    id: number,
    en: string,
    sv: string,
    value: number
}

export enum SidelineTestTypes {
    SIDELINETESTINFORMATION_REQUEST = "@@sidelineTest/SIDELINE_TEST_INFORMATION_REQUEST",
    SIDELINETESTINFORMATION_SUCCESS = "@@sidelineTest/SIDELINE_TEST_INFORMATION_SUCCESS",
    SIDELINETESTINFORMATION_ERROR = "@@sidelineTest/SIDELINE_TEST_INFORMATION_ERROR",
    SUBMITSIDELINETEST_REQUEST = "@@sidelineTest/SUBMIT_SIDELINE_TEST_REQUEST",
    SUBMITSIDELINETEST_SUCCESS = "@@sidelineTest/SUBMIT_SIDELINE_TEST_SUCCESS",
    SUBMITSIDELINETEST_ERROR = "@@sidelineTest/SUBMIT_SIDELINE_TEST_ERROR",
}